import { action, makeObservable, observable } from "mobx";
import i18n from "../i18n";
import { IImageInfo } from "react-native-image-zoom-viewer/built/image-viewer.type";

class Alert {
  modalType = "";
  title = "";
  message = "";
  button1Text = null;
  button2Text = null;
  button1Action = null;
  button2Action = null;
  isCameraPortrait = true;
  takePicture = undefined;
  imageIndex = 0;
  imageUrls = [{ url: "" }];

  constructor() {
    makeObservable(this, {
      modalType: observable,
      title: observable,
      message: observable,
      button1Text: observable,
      button2Text: observable,
      button1Action: observable,
      button2Action: observable,
      isCameraPortrait: observable,
      takePicture: observable,
      imageIndex: observable,
      imageUrls: observable,
      setError: action,
      closeError: action,
      setAlert: action,
      closeAlert: action,
      setSuccess: action,
      closeSuccess: action,
      setLoading: action,
      openCamera: action,
      closeCamera: action,
      setImageModal: action,
      closeImageModal: action
    });
  }

  setError = (title: string, message: string) => {
    this.modalType = "errorModal";
    this.title = title;
    this.message = message;
  };

  closeError = () => {
    this.modalType = "";
    this.title = "";
    this.message = "";
  };

  setAlert = (title: string, message: string, button1Text?: string, button1Action?: () => void, button2Text?: string, button2Action?: () => void) => {
    this.modalType = "genericModal";
    this.title = title;
    this.message = message;
    this.button1Text = button1Text || i18n.t("generic.close");
    this.button1Action = button1Action || this.closeAlert;
    if (button2Text) {
      this.button2Text = button2Text;
      this.button2Action = button2Action;
    }
  };

  closeAlert = () => {
    this.modalType = "";
    this.title = "";
    this.message = "";
    this.button1Text = "";
    this.button1Action = null;
    this.button2Text = "";
    this.button2Action = null;
  };

  setSuccess = (title: string, message: string, button1Action?: () => void) => {
    this.modalType = "successModal";
    this.title = title;
    this.message = message;
    this.button1Action = button1Action || this.closeSuccess;
  };

  closeSuccess = () => {
    this.modalType = "";
    this.title = "";
    this.message = "";
    this.button1Action = null;
  };

  setLoading = (value: boolean) => {
    // If value is true, set modalType to "loadingModal".
    // If value is false and the current modalType is "loadingModal", reset it to an empty string.
    // This ensures that the modalType is only set to "loadingModal" when actively loading,
    // and reset to an empty string otherwise, but only if it was previously a "loadingModal".
    if (this.modalType == "loadingModal" || value) {
      this.modalType = value ? "loadingModal" : "";
    }
  };

  openCamera = (takePicture: (picture: any) => any, isPortrait: boolean) => {
    this.modalType = "cameraModal";
    this.takePicture = takePicture;
    this.isCameraPortrait = isPortrait;
  };

  closeCamera = () => {
    this.modalType = "";
    this.takePicture = undefined;
    this.isCameraPortrait = true;
  };

  setImageModal = (index: number, imageUrls: IImageInfo[]) => {
    this.modalType = "imageModal";
    this.imageIndex = index;
    this.imageUrls = imageUrls;
  };

  closeImageModal = () => {
    this.modalType = "";
    this.imageIndex = 0;
    this.imageUrls = [{ url: "" }];
  };

}

export const mobAlert = new Alert();