import React from "react";
import { View, StyleSheet, Dimensions } from "react-native";
import InfoCard from "./InfosCard";
import i18n from "../i18n";

const Placeholder = () => {
  return (
    <View style={styles.container}>
      <InfoCard fieldMap={[{text:i18n.t("errors.emptyContent")}]} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height:Dimensions.get("screen").height/3,
    justifyContent: "flex-end",
    alignItems: "center",
    margin: 20,
  },
});

export default Placeholder;
