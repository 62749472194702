import { Platform, TouchableOpacity, View, StyleSheet } from "react-native";
import ImageViewer from "react-native-image-zoom-viewer";
import { CrossIcon } from "../Icons";
import { mobAlert } from "../../mobx/MobAlert";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { useTheme } from "react-native-paper";

const ImageViewerModal = observer(() => {
  const theme = useTheme();
  return <ImageViewer
    index={mobAlert.imageIndex}
    imageUrls={toJS(mobAlert.imageUrls)}
    enableSwipeDown={true}
    onSwipeDown={mobAlert.closeImageModal}
    renderHeader={() => (
      <View style={{
        position: "absolute",
        zIndex: 99,
        top: Platform.OS === "ios" ? "7.5%" : 20,
        left: 20,
      }}>
        <TouchableOpacity style={styles.button} onPress={mobAlert.closeImageModal}>
          <CrossIcon color={theme.colors.background} width={40} height={40} />
        </TouchableOpacity>
      </View>
    )}
  />;
});

const styles = StyleSheet.create({
  button: {
    backgroundColor: "rgba(0,0,0,0.7)",
    padding:20,
    overflow: "hidden",
    borderRadius: 20
  }
});

export default ImageViewerModal;