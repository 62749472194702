import React, { useEffect, useState } from "react";
import { ScrollView, StyleSheet } from "react-native";
import Queries from "../../../api/queries";
import { mobUser } from "../../../mobx/MobUser";
import getInfosFromArray from "./getInfosArray";
import { ApolloQueryResult } from "@apollo/client";
import InfoCard from "../../../components/InfosCard";
import { InfoCardTextProps } from "../../../types/types";
import BookingIDCard from "./BookingIDCard";

export default function BookingInfos() {
  const [infos, setInfos] = useState<InfoCardTextProps[]>([]);
  const queries = new Queries();

  useEffect(() => {
    let subscription;

    async function subscribeToBookingData() {
      try {
        const observable = await queries.getBookingById(mobUser.OiqiaBookingId);
        subscription = observable.subscribe({
          next(response: ApolloQueryResult<any>) {
            if (response.data?.NTA_getBookingById) {
              const transformedInfos = getInfosFromArray(response.data?.NTA_getBookingById);
              setInfos(transformedInfos);
            }
          },
          error(err) {
            console.error("Error fetching booking data:", err);
          }
        });
      } catch (error) {
        console.error("Error setting up subscription:", error);
      }
    }

    subscribeToBookingData();

    return () => {
      subscription?.unsubscribe();
    };
  }, []);

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <BookingIDCard field={infos[0]} />
      <InfoCard fieldMap={infos.slice(1, 3)} />
      <InfoCard fieldMap={infos.slice(3, 4)} />
      <InfoCard fieldMap={infos.slice(4, 6)} />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 30,
    gap: 30,
    alignSelf: "center",
    width: "80%"
  }
});
