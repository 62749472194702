import React, { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import { BookingOiqiaQl, InfoCardTextProps } from "../../../types/types";
import Queries from "../../../api/queries";
import { mobUser } from "../../../mobx/MobUser";
import { ApolloQueryResult } from "@apollo/client";
import i18n from "../../../i18n";
import InvoiceForm from "./InvoiceForm";
import InfoCard from "../../../components/InfosCard";

const Invoice = () => {
  const [fields, setFields] = useState<InfoCardTextProps[]>();
  const [resaInfos, setResaInfos] = useState<BookingOiqiaQl>();
  const queries = new Queries();

  useEffect(() => {
    let subscription;

    async function subscribeToBookingData() {
      try {
        const observable = await queries.getBookingById(mobUser.OiqiaBookingId);
        subscription = observable.subscribe({
          next(response: ApolloQueryResult<{ NTA_getBookingById: BookingOiqiaQl }>) {
            if (response.data?.NTA_getBookingById) {
              setResaInfos(response.data?.NTA_getBookingById);
              setFields([
                { title: i18n.t("pages.checkIn.firstName"), text: response.data.NTA_getBookingById?.firstName },
                { title: i18n.t("pages.checkIn.lastName"), text: response.data.NTA_getBookingById?.lastName },
              ]);
            }
          },
          error(err) {
            console.error("Error fetching booking data:", err);
          }
        });
      } catch (error) {
        console.error("Error setting up subscription:", error);
      }
    }

    subscribeToBookingData();

    return () => {
      subscription?.unsubscribe();
    };
  }, []);

  return (
    <View style={styles.container}>
      <View style={styles.subContainer}>
        <InfoCard fieldMap={fields} />
        <InvoiceForm checkInInfos={resaInfos} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    paddingVertical: 20,
  },
  subContainer: {
    width: "80%",
    gap: 25
  },
});

export default Invoice;