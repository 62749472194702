import { ApolloQueryResult } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { ScrollView, StyleSheet } from "react-native";
import { mobUser } from "../../../mobx/MobUser";
import Queries from "../../../api/queries";
import { AppartmentCaractInfo } from "../../../types/types";
import EquipmentDisplayCard from "./EquipmentDisplayCard";
import Placeholder from "../../../components/Placeholder";

const Equipments = () => {
  const [equipments, setEquipments] = useState<object>({});
  const queries = new Queries();

  useEffect(() => {
    let caractSubscription;

    async function subscribeEquipments() {
      try {
        const accessObservable = await queries.getAppartmentEquipments(mobUser.OiqiaBookingId);
        caractSubscription = accessObservable.subscribe({
          next(response: ApolloQueryResult<{ NTA_getAppartmentCaract: AppartmentCaractInfo }>) {
            //console.log(response.data.NTA_getAppartmentCaract?.equipments)
            if (response.data.NTA_getAppartmentCaract?.equipments) setEquipments(response.data.NTA_getAppartmentCaract?.equipments);
          },
          error(err) {
            console.error("Error fetching equipments:", err);
          }
        });
      } catch (error) {
        console.error("Error setting up subscription:", error);
      }
    }

    subscribeEquipments();

    return () => {
      caractSubscription?.unsubscribe();
    };
  }, []);

  return (
    <ScrollView style={styles.container}>
      {Object.keys(equipments).length ?
        (Object.keys(equipments).map((key) => {
          const equipmentItem = equipments[key];
          if (Array.isArray(equipmentItem)) {
            return equipmentItem.map((item, index) => {
              if (item?._trad && item?._trad[mobUser.Language]) {
                return <EquipmentDisplayCard key={`${key}_${index}`} title={item?._trad[mobUser.Language] + " " + (index + 1)} equipment={item} />;
              }
            });
          } else {
            if (equipmentItem?._trad && equipmentItem._trad[mobUser.Language]) {
              return <EquipmentDisplayCard key={key} title={equipmentItem?._trad[mobUser.Language]} equipment={equipmentItem} />;
            }
          }
        }))
        : <Placeholder/>
      }
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  }
});

export default Equipments;