import React from "react";
import { View, TouchableOpacity, Text, StyleSheet } from "react-native";
import { MD3Theme, useTheme } from "react-native-paper";

const RadioButton = ({ options, value, onChange, editable = true, sizeFactor = 1 }) => {
  const theme = useTheme();
  const styles = stylesGen(theme, sizeFactor);

  return (
    <View style={styles.container}>
      {options.map((option) => (
        <TouchableOpacity
          key={option.value}
          style={styles.button}
          onPress={() => editable && onChange(option.value)}
          disabled={!editable}
        >
          <View style={[
            styles.outerCircle,
            value === option.value && styles.selectedOuterCircle,
          ]}>
            {value === option.value && <View style={styles.innerCircle} />}
          </View>
          <Text style={styles.text}>{option.label}</Text>
        </TouchableOpacity>
      ))}
    </View>
  );
};

const stylesGen = (theme: MD3Theme, sizeFactor: number) => StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  button: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 10,
  },
  outerCircle: {
    height: 20*sizeFactor,
    width: 20*sizeFactor,
    borderRadius: 10*sizeFactor,
    borderWidth: 1,
    borderColor: theme.colors.onBackground,
    alignItems: "center",
    justifyContent: "center",
  },
  selectedOuterCircle: {
    borderColor: theme.colors.primary,
  },
  innerCircle: {
    height: 12*sizeFactor,
    width: 12*sizeFactor,
    borderRadius: 6*sizeFactor,
    backgroundColor: theme.colors.primary,
  },
  text: {
    marginLeft: 5,
    fontSize: 18*sizeFactor,
    marginBottom: 3
  },
});

export default RadioButton;
