import React from "react";
import { Dimensions, StyleSheet, TouchableHighlight, View } from "react-native";
import ChangeLanguageForm from "./ChooseLanguageForm";
import { CheckMarkIcon, ColoredOiqiaIcon } from "../../components/Icons";
import { useNavigation } from "@react-navigation/native";
import { useTheme } from "react-native-paper";

export default function ChooseLanguage() {
  const theme = useTheme();
  const styles = styleGen(theme);
  const navigation = useNavigation();

  const touchProps = {
    underlayColor: theme.colors.primary,
    style: { ...styles.button, backgroundColor: theme.colors.secondary },
  };

  return (
    <View style={styles.container}>
      <ColoredOiqiaIcon
        width={Dimensions.get("screen").height / 4}
        height={Dimensions.get("screen").height / 4}
        style={styles.logo}
      />
      <ChangeLanguageForm />
      <TouchableHighlight onPress={() => navigation.navigate("Login")} {...touchProps}>
        <CheckMarkIcon />
      </TouchableHighlight>
    </View>
  );
}

const styleGen = (theme) => StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "flex-start",
    backgroundColor: theme.colors.background
  },
  logo: {
    alignSelf: "center",
    margin: 50,
  },
  button: {
    alignSelf: "center",
    borderRadius: 20
  },
});
