import React from "react";
import { FontAwesome } from "@expo/vector-icons";
import { Send } from "react-native-gifted-chat";
import { useTheme } from "react-native-paper";

const SendButton = (props) => {
  return (
    <Send {...props} containerStyle={{ marginRight: 10, marginBottom: 15, marginTop: 5 }} >
      <FontAwesome name="send" size={24} color={useTheme().colors.onBackground} />
    </Send>
  );
};

export default SendButton;