import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import CustomHeader from "./CustomHeader";

import Home from "../pages/Home";
import Housing from "../pages/Home/Housing";
import Access from "../pages/Home/Access";
import Wifi from "../pages/Home/Wifi";
import BookingInfos from "../pages/Home/BookingInfos";
import CheckIn from "../pages/Home/CheckIn";
import CheckOut from "../pages/Home/CheckOut";
import Manuals from "../pages/Home/Manuals";
import Rules from "../pages/Home/Rules";
import Welcome from "../pages/Home/Welcome";
import Assistance from "../pages/Home/Assistance";
import Equipments from "../pages/Home/Equipments";
import Invoice from "../pages/Home/Invoice";
import Language from "../pages/Home/Language";
import Parking from "../pages/Home/Parking";
import i18n from "../i18n";
import Guestbook from "../pages/Home/Explore";
import { mobUser } from "../mobx/MobUser";
import { observer } from "mobx-react";
import { useTheme } from "react-native-paper";

const HomeStack = createNativeStackNavigator();

interface Route {
  name: string,
  component: () => JSX.Element,
  headerTitle: string,
  headerHasArrow: boolean,
  backPath?: string
}

const HomeStackScreen = observer(() => {
  const theme = useTheme();

  const ROUTES: Route[] = [
    { name: "Main", component: Home, headerTitle: i18n.t("home.main"), headerHasArrow: false },
    { name: "Housing", component: Housing, headerTitle: i18n.t("home.housing"), headerHasArrow: true, backPath: "Main" },
    { name: "Access", component: Access, headerTitle: i18n.t("home.access"), headerHasArrow: true, backPath: "Main" },
    { name: "Wifi", component: Wifi, headerTitle: i18n.t("home.wifi"), headerHasArrow: true },
    { name: "BookingInfos", component: BookingInfos, headerTitle: i18n.t("home.booking"), headerHasArrow: true, backPath: "Main" },
    { name: "CheckIn", component: CheckIn, headerTitle: i18n.t("home.checkIn"), headerHasArrow: true, backPath: "Main" },
    { name: "CheckOut", component: CheckOut, headerTitle: i18n.t("home.checkOut"), headerHasArrow: true },
    { name: "Manuals", component: Manuals, headerTitle: i18n.t("home.manuals"), headerHasArrow: true },
    { name: "Rules", component: Rules, headerTitle: i18n.t("home.houseRules"), headerHasArrow: true },
    { name: "Welcome", component: Welcome, headerTitle: i18n.t("home.welcomeNotes"), headerHasArrow: true },
    { name: "Assistance", component: Assistance, headerTitle: i18n.t("home.assistance"), headerHasArrow: true },
    { name: "Equipments", component: Equipments, headerTitle: i18n.t("home.equipments"), headerHasArrow: true },
    { name: "Invoice", component: Invoice, headerTitle: i18n.t("home.invoice"), headerHasArrow: true },
    { name: "Language", component: Language, headerTitle: i18n.t("home.language"), headerHasArrow: true },
    { name: "Parking", component: Parking, headerTitle: i18n.t("home.carPark"), headerHasArrow: true },
    { name: "Guestbook", component: Guestbook, headerTitle: i18n.t("home.guestbook"), headerHasArrow: true },
  ];

  return (
    <HomeStack.Navigator screenOptions={{
      statusBarColor: theme.colors.primary,
      navigationBarHidden: true,
      contentStyle: {
        backgroundColor: theme.colors.background
      }
    }}
    >
      {ROUTES.map((route: Route, index: number) => (
        <HomeStack.Screen
          key={index + mobUser.Language}
          name={route.name}
          component={route.component}
          options={{
            header: () => CustomHeader({ text: route.headerTitle, hasArrow: route.headerHasArrow, backPath: route.backPath, theme })
          }}
        />
      ))}
    </HomeStack.Navigator>
  );
});

export default HomeStackScreen;