import React, { useEffect, useState } from "react";
import { Dimensions, ScrollView, StyleSheet, View } from "react-native";
import { ColoredOiqiaIcon } from "../../components/Icons";
import { MD3Theme, useTheme } from "react-native-paper";
import Markdown from "react-native-markdown-display";
import { Text } from "../../utils/fontsLoader";
import { TermsAgreement } from "../../types/types";
import { ApolloQueryResult } from "@apollo/client";
import { Subscription } from "zen-observable-ts";
import Queries from "../../api/queries";

const rules = {
  text: (node, children, parent, styles, inheritedStyles = {}) =>
    <Text key={node.key} style={[styles.text, inheritedStyles]}>
      {node.content}
    </Text>,
};

export default function Therms() {
  const theme = useTheme();
  const styles = styleGen(theme);
  const size = Dimensions.get("screen").height / 10;
  const [termsData, setTermsData] = useState<TermsAgreement>({ terms: "" });
  const queries = new Queries();

  useEffect(() => {
    let termsDataSubscription: Subscription;

    async function subscribeToTerms() {
      try {
        const termsObservable = await queries.getLatestTerms();
        termsDataSubscription = termsObservable.subscribe({
          next(response: ApolloQueryResult<{ getLatestTerms: TermsAgreement }>) {
            setTermsData(response.data.getLatestTerms);
          },
          error(err) {
            console.error("Error fetching terms data:", err);
          }
        });
      } catch (error) {
        console.error("Error setting up terms subscription:", error);
      }
    }

    subscribeToTerms();

    // Cleanup subscription on component unmount
    return () => {
      termsDataSubscription?.unsubscribe();
    };
  }, []);

  return (
    <ScrollView
      contentInsetAdjustmentBehavior="automatic"
      style={styles.container}>
      <View style={styles.subContainer}>
        <Markdown
          style={{
            textgroup: { marginVertical: 10, },
            bullet_list_icon:{ marginVertical: 10,},
            heading1: {
              fontSize: 24,
              textDecorationLine: "underline",
              alignSelf:"center"
            },
            heading2: {
              fontSize: 18,
              textDecorationLine: "underline",
            },
            heading3: {
              fontSize: 16,
            },
            heading4: {
              fontSize: 13,
            },
            heading5: {
              fontSize: 11,
            },
            heading6: {
              fontSize: 9,
            },
            strong: { fontFamily: "Gilroy-Bold" },
            em: { fontFamily: "Gilroy-RegularItalic" },
            link: { color: "blue" },
          }}
          rules={rules}
        >
          {termsData?.terms}
        </Markdown>
        <ColoredOiqiaIcon width={size} height={size} />
      </View>
    </ScrollView>
  );
}

const styleGen = (theme: MD3Theme) => StyleSheet.create({
  container: {
    backgroundColor: theme.colors.background,
    flex: 1,
  },
  subContainer: {
    margin: 20,
    gap: 20,
    alignItems: "center"
  }
});
