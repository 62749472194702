import React from "react";
import { View, StyleSheet, Dimensions } from "react-native";
import i18n from "../../../i18n";
import { HouseRulesIcon, InfosIcon, WifiIcon } from "../../../components/Icons";
import { IconType } from "..";
import HomePageButton from "../../../components/HomePageButton";
import { BookingOiqiaQl, PictureData } from "../../../types/types";
import HousingDisplayData from "./HousingDisplayData";
import ImageGallery from "../../../components/ImageGallery";
import Skeleton from "../../../components/Skeleton";
import { Text } from "../../../utils/fontsLoader";

interface GridLayoutProps {
  isDataLoading: boolean,
  isAttachmentLoading: boolean,
  data: BookingOiqiaQl,
  pictureData: PictureData,
}
const picSize = Dimensions.get("screen").width*0.73;

export default function GridLayout({ isDataLoading, isAttachmentLoading, data, pictureData }: GridLayoutProps) {

  const HOUSING_ICONS_LIST: IconType[][] = [
    [
      { icon: <InfosIcon />, title: i18n.t("home.manuals"), page: "Manuals" },
      { icon: <HouseRulesIcon />, title: i18n.t("home.houseRules"), page: "Rules" },
      { icon: <WifiIcon />, title: i18n.t("home.wifi"), page: "Wifi" },
    ]
  ];

  return (
    <View style={styles.container}>
      <View>
        <HousingDisplayData isDataLoading={isDataLoading} data={data} />
      </View>
      <View style={styles.row}>
        {HOUSING_ICONS_LIST[0].map((component: IconType, index: number) => (
          <View key={index} style={styles.buttonSize}>
            <HomePageButton component={component} />
          </View>
        ))}
      </View>
      {isAttachmentLoading ?
        <View style={styles.imageSkeleton}>
          <Skeleton />
        </View>
        :
        <View style={styles.imageSkeleton}>
          <Text style={styles.imagesTitle}>
            {i18n.t("pages.housing.pictures")}
          </Text>
          <ImageGallery pictureData={pictureData} />
        </View>
      }
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width:"100%",
    gap: 20,
    justifyContent: "center",
  },
  row: {
    justifyContent: "center",
    flexDirection: "row",
    gap: 20
  },
  buttonSize:{
    width:"30%"
  },
  imageSkeleton: {
    width: picSize,
    height: picSize,
    borderRadius: 20,
    alignSelf: "center"
  },
  imagesTitle: {
    fontFamily: "Gilroy-Bold",
    fontSize: 30,
    marginBottom:10,
  }
});
