import React, { ReactNode } from "react";
import { View, StyleSheet, Platform } from "react-native";
import { useTheme } from "react-native-paper";
import { mobUser } from "../../../mobx/MobUser";
import { Text } from "../../../utils/fontsLoader";

interface ParamElement {
  number: number;
  label: { fr: string };
  type?: { fr: string };
  quantity?: number;
  additionalDetails?: string;
}

interface EquipmentDisplayProps {
  title: string;
  equipment: ParamElement[];
}

/**
 * Display equipment in a comma separated list (inline intead of column for web-app CSS issue)
 * @param {ParamElement} equipment
 * @returns {ReactNode} a Text tag => equipement1, equipement2, ..., equipementN
 */
const displayWebApp = (equipment: ParamElement[]): ReactNode => {
  const arrayOfValue = Object.keys(equipment).filter(item => item !== "_trad");

  return (
    <Text style={{ marginBottom: 40 }}>{arrayOfValue.join(", ")}</Text>
  );
};

const EquipmentDisplayCard = ({ title, equipment }: EquipmentDisplayProps) => {
  const theme = useTheme();

  return (
    <View style={{ ...styles.container, backgroundColor: theme.colors.secondary }}>
      <Text style={styles.title}>{title}</Text>
      {Platform.OS !== "web" && Object.keys(equipment).map((key) => {
        if (key === "_trad") return null;
        const element = equipment[key];
        if (Array.isArray(element)) {
          for (const e of element) {
            return (
              <View key={key} style={styles.item}>
                <Text style={styles.label}>
                  {e.quantity ? e.quantity + " " : "1 "}
                  {e._trad[mobUser.Language] ? e._trad[mobUser.Language] : ""}
                  {e.additionalDetails ? " " + e.additionalDetails : ""}
                </Text>
              </View>
            );
          }
        }
        return (
          <View key={key} style={styles.item}>
            <Text style={styles.label}>
              {element.quantity ? element.quantity + " " : ""}
              {element._trad[mobUser.Language] ? element._trad[mobUser.Language] : ""}
              {element.additionalDetails ? " " + element.additionalDetails : ""}
            </Text>
          </View>
        );
      })}
      {Platform.OS === "web" &&
        displayWebApp(equipment)
      }
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "80%",
    alignSelf: "center",
    marginVertical: 10,
    borderRadius: 20,
    padding: 20,
  },
  title: {
    fontSize: 18,
    fontFamily: "Gilroy-Bold",
    marginBottom: 10,
  },
  item: {
    marginBottom: 10,
    borderRadius: 5,
  },
  label: {
    marginLeft: 15,
    fontSize: 16,
    fontFamily: "Gilroy-Medium",
  },
  details: {
    fontSize: 14,
    color: "#555",
  },
});

export default EquipmentDisplayCard;