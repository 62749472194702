import React from "react";
import { StyleSheet, View } from "react-native";
import { Controller, useForm } from "react-hook-form";
import { useTheme } from "react-native-paper";

import { observer } from "mobx-react-lite";

import AppInput from "../../components/AppInput";
import AppButton from "../../components/AppButton";
import FieldErrorText from "../../components/FieldErrortext";
import AcceptTherms from "./AcceptTherms";

import { mobUser } from "../../mobx/MobUser";
import i18n from "../../i18n";

// main function
const LoginForm = observer(() => {
  const theme = useTheme();
  const styles = styleGen();
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      bookingId: "",
      CGUStatus: false
    },
  });

  async function onSubmit(data) {
    console.log("form data", data);
    await mobUser.AS_SignIn(data?.email, data?.bookingId);

    try {
      //const bookingInfos = await queries.getBookingByExtId(data.bookingId);
      //if (bookingInfos.data.getBookingByExtId != null) {
      //  console.log(bookingInfos);
      //}
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <View style={styles.constainer}>
      <Controller
        control={control}
        rules={{
          required: i18n.t("errors.missingField"),
          pattern: {
            value: /^[\w.%+-]+@[a-z0-9-]+\.[a-z]{2,}$/i,
            message: i18n.t("errors.emailFormatError")
          }
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <View style={styles.field}>
            <AppInput
              label={i18n.t("login.enterEmail")}
              onBlur={onBlur}
              onChangeText={onChange}
              autoCapitalize="none"
              keyboardType="email-address"
              value={value}
            />
            {errors.email && <FieldErrorText errorMessage={errors.email.message} />}
          </View>

        )}
        name="email"
      />

      <Controller
        control={control}
        rules={{
          required: i18n.t("errors.missingField"),
          validate: value => (value.length >= 6 && value.length <= 8) || i18n.t("login.badLength")
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <View style={styles.field}>
            <AppInput
              label={i18n.t("login.enterId")}
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
              keyboardType='numeric'
            />
            {errors.bookingId && <FieldErrorText errorMessage={errors.bookingId.message} />}
          </View>
        )}
        name="bookingId"
      />
      <Controller
        control={control}
        rules={{
          required: i18n.t("login.acceptTherms"),
        }}
        render={({ field: { value } }) => (
          <View style={styles.CGUcontainer}>
            <AcceptTherms buttonStatus={value} setButtonStatus={() => setValue("CGUStatus", !value)} />
            <View style={styles.field}>
              {errors.CGUStatus && <FieldErrorText errorMessage={errors.CGUStatus.message} />}
            </View>
          </View>
        )}
        name="CGUStatus"
      />
      <AppButton color={theme.colors.primary} extraStyle={{ top: 20 }} extraTextStyle={{ color: theme.colors.background, fontFamily: "Gilroy-Medium" }} onPress={handleSubmit(onSubmit)}>
        {i18n.t("login.submit")}
      </AppButton>
    </View>
  );
}
);

const styleGen = () => StyleSheet.create({
  constainer: {
    width: "80%",
    alignItems: "center",
    gap: 35
  },
  CGUcontainer: {
    width: "100%",
  },
  field: {
    alignItems: "center",
    width: "100%",
  }
});

export default LoginForm;
