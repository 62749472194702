import React, { useEffect, forwardRef, RefObject } from "react";
import { Dimensions, StyleSheet } from "react-native";
import { MD3Theme, useTheme } from "react-native-paper";
import PhoneInput from "react-native-phone-input";
import { PhoneInputProps } from "../types/types";

const PhoneInputCustom = forwardRef((props: PhoneInputProps, ref: RefObject<PhoneInput>) => {
  const { value, onChangeText, placeholder, additionalStyle, editable } = props;
  const theme = useTheme();
  const styles = stylesGen(theme);

  useEffect(() => {
    if (ref?.current) {
      ref.current.setValue(value);
    }
  }, [value, ref]);

  return (
    <PhoneInput
      ref={ref}
      initialCountry="FR"
      autoFormat={true}
      style={{ ...styles.input, ...additionalStyle, backgroundColor: theme.colors.secondary }}
      textStyle={{ ...styles.text, ...additionalStyle }}
      initialValue={value}
      textProps={{
        placeholder: placeholder,
      }}
      onChangePhoneNumber={onChangeText}
      disabled={!editable}
    />
  );
});

const stylesGen= (theme: MD3Theme) => StyleSheet.create({
  input: {
    borderWidth: 1,
    width: "100%",
    borderColor: "lightgray",
    paddingHorizontal: 10,
    paddingTop:4,
    borderRadius: 15,
    height: 35,
    lineHeight: 20
  },
  text: {
    textAlign: "center",
    color:theme.colors.onBackground,
    fontFamily: "Gilroy-Regular",
    fontSize: Dimensions.get("screen").height / 45,
  },
});

export default PhoneInputCustom;