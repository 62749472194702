import React from "react";
import { mobAlert } from "../../mobx/MobAlert";
import OiqiaErrorModal from "./OiqiaErrorModal";
import OiqiaLoadingModal from "./OiqiaLoadingModal";
import OiqiaModal from "./OiqiaModal";
import OiqiaSuccessModal from "./OiqiaSuccessModal";
import { Modal } from "react-native";
import { useTheme } from "react-native-paper";
import CameraScreen from "../../pages/Home/Camera";
import ImageViewerModal from "./ImageViewerModal";
import { observer } from "mobx-react";

const GetModalComponent = ({ modalType = "" }) => {
  const theme = useTheme();
  switch (modalType) {
  case "errorModal":
    return <OiqiaErrorModal
      title={mobAlert.title}
      message={mobAlert.message}
      onClose={mobAlert.closeError}
    />;
  case "genericModal":
    return <OiqiaModal
      titleText={mobAlert.title}
      bodyText={mobAlert.message}
      firstButtonData={{
        text: mobAlert.button1Text,
        callback: mobAlert.button1Action,
        fontColor: theme.colors.background,
        backgroundColor: theme.colors.primary,
      }}
      secondButtonData={mobAlert.button2Text ? {
        text: mobAlert.button2Text,
        callback: mobAlert.button2Action,
        fontColor: theme.colors.background,
        backgroundColor: theme.colors.primary,
      } : null}
      onClose={mobAlert.closeAlert}
    />;
  case "successModal":
    return <OiqiaSuccessModal
      title={mobAlert.title}
      message={mobAlert.message}
      onClose={mobAlert.button1Action}
    />;
  case "loadingModal":
    return <OiqiaLoadingModal />;

  case "cameraModal":
    return <CameraScreen
      closeCamera={mobAlert.closeCamera}
      isChat={true}
      sendPicture={mobAlert.takePicture}
      isPortrait={mobAlert.isCameraPortrait}
    />;
  case "imageModal":
    return <ImageViewerModal />;
  default:
    return null;
  }
};

const OnlyOneModal = observer(() => {
  return (
    <Modal
      transparent
      animationType={mobAlert.modalType == "genericModal" ? "slide" : "fade"}
      visible={!!mobAlert.modalType}
      onRequestClose={mobAlert.modalType == "cameraModal" ? mobAlert.closeCamera : () => null}
    >
      <GetModalComponent modalType={mobAlert.modalType} />
    </Modal>
  );
});

export default OnlyOneModal;