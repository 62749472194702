import React from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { Text } from "../../../utils/fontsLoader";
import { Badge, MD3Theme, useTheme } from "react-native-paper";
import { mobNotif } from "../../../mobx/MobNotifs";
interface AppButtonProps {
  color?: string
  children: any
  onPress: any
  extraStyle?: object
  extraTextStyle?: object
  disabled?: boolean
}

export default function ChatButton({ color = null, children, onPress, extraStyle, extraTextStyle, disabled }: AppButtonProps) {
  const theme = useTheme();
  const styles = styleGen(theme);

  return (
    <TouchableOpacity disabled={disabled} onPress={onPress} style={{ ...styles.button, backgroundColor: color || theme.colors.primary, ...extraStyle }}>
      {mobNotif.badge ?
        <Badge size={25} style={styles.badge}>{mobNotif.badge}</Badge> : null
      }
      <Text style={{ ...styles.text, ...extraTextStyle }}>
        {children}
      </Text>
    </TouchableOpacity>
  );
}

const styleGen = (theme: MD3Theme) => StyleSheet.create({
  button: {
    borderRadius: 15,
    //borderWidth: 1,
    width: "100%",
    paddingVertical: 3,
    paddingRight: 10,
    paddingLeft: 10
  },
  badge: {
    position: "absolute",
    top: -5,
    right: -5,
    zIndex: 1,
    fontFamily: "Gilroy-Bold",
    color: theme.colors.primary,
    backgroundColor:theme.colors.background,
    borderWidth:1,
    borderColor:"rgba(0,0,0,0.2)",
  },
  text: {
    fontSize: 23,
    fontFamily: "Gilroy-Medium",
    textAlign: "center",
    lineHeight: 33,
    color: theme.colors.background
  }
});
