import React, { useEffect, useRef, useState } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import { Subscription } from "zen-observable-ts";
import { CheckInData, Guestbook, InfoCardTextProps } from "../../../types/types";
import Queries from "../../../api/queries";
import { mobUser } from "../../../mobx/MobUser";
import { ApolloQueryResult } from "@apollo/client";
import ExploreForm from "./ExploreForm";
import InfoCard from "../../../components/InfosCard";
import ScrollFAB from "../../../components/FloatingScrollButton";
import HintCard from "./HintCard";
import i18n from "../../../i18n";

const Invoice = () => {
  const [checkInInfos, setCheckInInfos] = useState<CheckInData>();
  const [guestbookData, setGuestbookData] = useState<InfoCardTextProps[]>();
  const [visibleFAB, setVisibleFAB] = useState(false);
  const queries = new Queries();
  const Scrollref = useRef();

  useEffect(() => {
    let checkInDataSubscription: Subscription;
    let gestbookDataSubscription: Subscription;

    async function subscribeToData() {
      try {
        const checkInObservable = await queries.getCheckIn(mobUser.OiqiaBookingId);
        checkInDataSubscription = checkInObservable.subscribe({
          next(response: ApolloQueryResult<{ NTA_getCheckIn: CheckInData }>) {
            setCheckInInfos(response.data.NTA_getCheckIn);
          },
          error(err) {
            console.error("Error fetching check-in data:", err);
          }
        });
        const guestbookObservable = await queries.getGuestbook(mobUser.OiqiaBookingId);
        gestbookDataSubscription = guestbookObservable.subscribe({
          next(response: ApolloQueryResult<{ getGuestbook: Guestbook[] }>) {
            const newFields = response.data.getGuestbook?.map(guestbookComment => {
              return {
                title: guestbookComment?.firstName + " :",
                text: guestbookComment?.comment
              };
            }) || [];
            setGuestbookData(newFields);
          },
          error(err) {
            console.error("Error fetching check-in data:", err);
          }
        });
      } catch (error) {
        console.error("Error setting up subscription:", error);
      }
    }

    subscribeToData();

    return () => {
      checkInDataSubscription?.unsubscribe();
      gestbookDataSubscription?.unsubscribe();
    };
  }, []);

  const handleScroll = (event) => {
    const { contentOffset, layoutMeasurement, contentSize } = event.nativeEvent;
    const distanceFromBottom = contentSize.height - layoutMeasurement.height - contentOffset.y;
    setVisibleFAB(distanceFromBottom > (layoutMeasurement.height / 2));
  };

  return (
    <>
      <ScrollView ref={Scrollref} onScroll={handleScroll} style={styles.container}>
        <View style={styles.subContainer}>
          <HintCard />
          {guestbookData?.length ?
            guestbookData.map((comment, idx) => {
              return (
                <InfoCard key={idx} fieldMap={[comment]} />
              );
            })
            : mobUser.isGuestbookDone ?
              <View style={{alignItems:"center"}}>
                <InfoCard fieldMap={[{ text: i18n.t("errors.emptyContent") }]} />
              </View>
              : null
          }
          {!mobUser.isGuestbookDone && <ExploreForm checkInInfos={checkInInfos} />}
        </View>
      </ScrollView>
      {visibleFAB && <ScrollFAB ScrollViewRef={Scrollref} />}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: 0,
  },
  subContainer: {
    width: "80%",
    alignSelf: "center",
    gap: 25,
    marginVertical: 20,
  },
});

export default Invoice;