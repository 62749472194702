import React from "react";
import { StyleSheet } from "react-native";
import { InfoCardTextProps } from "../types/types";
import { MD3Theme, useTheme } from "react-native-paper";
import LinkText from "./LinkText";

export default function BookingIDText({ title = null, text = null }: InfoCardTextProps) {
  const theme = useTheme();
  const styles = genStyles(theme);

  return (
    <>
      {title && <LinkText style={styles.title}>
        {title}
      </LinkText>
      }
      {text &&
        <LinkText selectable style={styles.text}>
          {text}
        </LinkText>
      }
    </>
  );
}

const genStyles = (theme: MD3Theme) => StyleSheet.create({
  title: {
    fontFamily: "Gilroy-Bold",
    fontSize: 20,
    padding: 5,
    textAlign: "center",
  },
  text: {
    color: theme.colors.primary,
    backgroundColor:theme.colors.background,
    fontSize: 20,
    fontFamily: "Gilroy-SemiBold",
    borderColor: "rgba(0, 0, 0, 0.4)",
    //borderWidth: 1.5,
    borderRadius: 10,
    overflow:"hidden",
    padding: 10,
    textAlign: "center",
  }
});