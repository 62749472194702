import React from "react";
import { StyleSheet, View, Image, TouchableOpacity } from "react-native";
import { MD3Theme, useTheme } from "react-native-paper";
import InfoCardText from "./InfoCardText";
import { InfoCardTextProps } from "../types/types";
import { mobAlert } from "../mobx/MobAlert";

interface InfoCardProps {
  fieldMap: InfoCardTextProps[]
}

export default function InfoCard({ fieldMap }: InfoCardProps) {
  const theme = useTheme();
  const styles = styleGen(theme);

  return (
    <View style={styles.container}>
      {fieldMap?.map((field, idx) => (
        <View key={idx}>
          <InfoCardText title={field.title} text={field.text} />
          {field.container && field.name &&
            <TouchableOpacity style={styles.image} onPress={() => mobAlert.setImageModal(0, [{ url: field.container + "/" + field.name }])}>
              <Image style={styles.image} source={{ uri: field.container + "/" + field.name }} />
            </TouchableOpacity>
          }
        </View>
      ))}
    </View>
  );
}

const styleGen = (theme: MD3Theme) => StyleSheet.create({
  container: {
    backgroundColor: theme.colors.secondary,
    padding: 10,
    borderRadius: 20,
    gap: 20
  },
  image: {
    alignSelf: "center",
    marginTop: 15,
    borderRadius: 20,
    width: "90%",
    height: 200,
  }
});
