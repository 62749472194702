import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import { AppartDoorsWithPictures } from "../../../types/types";
import i18n from "../../../i18n";
import { MD3Theme, useTheme } from "react-native-paper";
import AppButton from "../../../components/AppButton";

import Mutations from "../../../api/mutations";
import DoorCardCommonHeader from "./DoorCardCommonHeader";
import { mobUser } from "../../../mobx/MobUser";
import { mobAlert } from "../../../mobx/MobAlert";
import { Text } from "../../../utils/fontsLoader";

interface DoorCardProps {
    door: AppartDoorsWithPictures;
    idx: number;
}

export default function DoorCardOpener({ door, idx }: DoorCardProps) {
    const [actionInProgress, setActionInProgress] = useState<boolean>(false);

    const mutations = new Mutations();

    const theme = useTheme();
    const styles = styleGen(theme);

    const openOpener = () => {
        mobAlert.closeAlert();
        setActionInProgress(true);
        mobAlert.setLoading(true);
        mutations.openDoor(mobUser.OiqiaBookingId, door.doorDeviceId).then(response => {
            mobAlert.setLoading(false);
            if (response.data?.openDoor?.success === true) {
                mobAlert.setSuccess(
                    i18n.t("generic.success"),
                    i18n.t("pages.access.unlockSuccess"),
                );
            }
            setActionInProgress(false);
        }).catch(() => {
            mobAlert.setLoading(false);
            setActionInProgress(false);
        });
    };

    const askConfirmation = () => {
        mobAlert.setAlert(
            i18n.t("pages.access.confirmActionTitle"),
            i18n.t("pages.access.confirmActionTextUNLOCK"),
            i18n.t("generic.yes"),
            () => openOpener(),
            i18n.t("generic.no"),
            mobAlert.closeAlert
        );
    };

    return (
        <View>
            <View style={styles.container}>
                <DoorCardCommonHeader
                    idx={idx}
                    digitCode={(door.digitCode || door.manualCode) ?? null}
                    textMultiName={door.textMultiName}
                    textMultiDesc={door.textMultiDesc}
                    doorPictureURL={door.doorPictureURL}
                />
                <View style={styles.actions}>
                    <Text style={styles.actionsTitle}>
                        {i18n.t("pages.access.openTitle")}
                    </Text>
                    <AppButton
                        extraStyle={styles.extraStyleBtn}
                        extraTextStyle={styles.extraStyleBtnText}
                        disabled={actionInProgress}
                        children={i18n.t("pages.access.unlock").toUpperCase()}
                        onPress={askConfirmation}
                    />
                </View>
            </View>
        </View>
    );
}

const styleGen = (theme: MD3Theme) => StyleSheet.create({
    container: {
        backgroundColor: theme.colors.secondary,
        padding: 20,
        paddingBottom: 0,
        borderRadius: 20,
        alignItems: "center",
        gap: 20,
    },
    title: {
        fontSize: 25,
        textAlign: "center",
        fontFamily: "Gilroy-Bold",
    },
    actions: {
        width: "80%",
        marginTop: 0,
    },
    actionsTitle: {
        fontSize: 20,
        textAlign: "center",
        fontFamily: "Gilroy-Light",
        marginBottom: 20,
    },
    actionsText: {
        fontSize: 20,
        textAlign: "center",
        fontFamily: "Gilroy-Light",
    },
    orText: {
        fontSize: 20,
        textAlign: "center",
        fontFamily: "Gilroy-Light",
        marginTop: 10,
    },
    extraStyleBtn: {
        marginTop: 0,
        marginBottom: 20,
        paddingVertical: 7,
    },
    extraStyleBtnText: { fontFamily: "Gilroy-Medium" },
    oiqia: {
        fontSize: 20,
        textAlign: "center",
        fontFamily: "Gilroy-Bold",
        color: theme.colors.primary,
        textTransform: "capitalize"
    },
    laoding: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
});
