import React from "react";
import { View, StyleSheet, Linking } from "react-native";
import HomePageButton from "./HomePageButton";
import { AttachmentInfo } from "../types/types";
import Placeholder from "./Placeholder";

interface GridLayoutProps {
  data: AttachmentInfo[],
  IconComponent: React.ElementType
}

export default function PDFGridLayout({ data, IconComponent }: GridLayoutProps) {
  return (
    <View style={styles.container}>
      {(data && data.length) ?
        data.map((item, index: number) => {
          const component = { icon: <IconComponent />, title: item.title, onPress: () => Linking.openURL(item.container + "/" + item.name) };
          return <HomePageButton key={index} component={component} />;
        })
        :
        <Placeholder/>
      }
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: 50,
    rowGap: 30
  }
});
