import React, { useEffect, useRef, useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import Queries from "../../../api/queries";
import { mobUser } from "../../../mobx/MobUser";
import { AppartDoorsWithPictures } from "../../../types/types";
import { ApolloQueryResult } from "@apollo/client";
import IntroPage from "./IntroPage";

import DoorCard from "./DoorCard";
import DoorCardNuki from "./DoorCardNuki";
import DoorCardOpener from "./DoorCardOpener";
import DoorCardIntratone from "./DoorCardIntratone";
import DoorCardIglooLock from "./DoorCardIglooLock";
import DoorCardIglooBac from "./DoorCardIglooBac";

import ScrollFAB from "../../../components/FloatingScrollButton";

export default function Access() {
  const [apartDoors, setApartDoors] = useState<AppartDoorsWithPictures[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [visibleFAB, setVisibleFAB] = useState(false);
  const queries = new Queries();
  const Scrollref = useRef();

  useEffect(() => {
    let accessSubscription;

    async function subscribeToWifiData() {
      try {
        const accessObservable = await queries.getAppartementDoorsWithPictures(
          mobUser.OiqiaBookingId
        );
        accessSubscription = accessObservable.subscribe({
          next(
            response: ApolloQueryResult<{
              getAppartementDoorsWithPictures: AppartDoorsWithPictures[];
            }>
          ) {
            setApartDoors(response.data.getAppartementDoorsWithPictures);
            setIsLoading(false);
          },
          error(err) {
            console.error("Error fetching wifi data:", err);
          },
        });
      } catch (error) {
        console.error("Error setting up subscription:", error);
      }
    }

    subscribeToWifiData();

    return () => {
      accessSubscription?.unsubscribe();
    };
  }, []);

  const renderAccessComponent = (door, idx) => {
    switch (door.doorType) {
    case "PORTE":
      return <DoorCardNuki key={idx} idx={idx} door={door} />;
      break;
    case "AUTRE":
      return <DoorCard key={idx} idx={idx} door={door} />;
      break;
    case "SAS":
      return <DoorCardOpener key={idx} idx={idx} door={door} />;
      break;
    case "INTRATONE-CALL":
      return <DoorCardIntratone key={idx} idx={idx} door={door} />;
      break;
    case "IGLOO-LOCK":
      return <DoorCardIglooLock key={idx} idx={idx} door={door} />;
      break;
    case "IGLOO-BAC":
      return <DoorCardIglooBac key={idx} idx={idx} door={door} />;
      break;
    default:
      return <DoorCard key={idx} idx={idx} door={door} />;
      break;
    }
  };

  if (isLoading) {
    return <></>;
  }

  const handleScroll = (event) => {
    const { contentOffset, layoutMeasurement, contentSize } = event.nativeEvent;
    const distanceFromBottom = contentSize.height - layoutMeasurement.height - contentOffset.y;
    setVisibleFAB(distanceFromBottom > (layoutMeasurement.height / 2));
  };

  return (
    <>
      <ScrollView ref={Scrollref} onScroll={handleScroll} contentContainerStyle={styles.container}>
        <View style={styles.subContainer}>
          <IntroPage />
          {/*apartDoors.length > 1 && <Summary doors={apartDoors} />*/}
          <View style={styles.cardsList}>
            {apartDoors.map((door, idx) => renderAccessComponent(door, idx))}
          </View>
        </View>
      </ScrollView>
      {visibleFAB && <ScrollFAB ScrollViewRef={Scrollref} />}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    alignItems: "center",
  },
  subContainer: {
    width: "80%",
    alignItems: "center",
    paddingVertical: 30,
    gap: 30,
  },
  cardsList: {
    width: "100%",
    gap: 30,
  },
});
