import React from "react";

import { StyleSheet, Image, TouchableOpacity } from "react-native";

import { useTheme } from "react-native-paper";

import i18n from "../../../i18n";
import Divider from "../../../components/Divider";
import getTextFromMultilang from "../../../utils/getTextFromMultiLang";
import { Text, capitalizeFirstLetter } from "../../../utils/fontsLoader";
import { mobAlert } from "../../../mobx/MobAlert";

interface Props {
  textMultiName: any;
  textMultiDesc: any;
  doorPictureURL?: string;
  digitCode: string | number;
  idx: number;
}

export default function DoorCardCommonHeader({
  textMultiDesc,
  textMultiName,
  doorPictureURL,
  digitCode,
  idx,
}: Props) {

  const theme = useTheme();
  const styles = stylesGen(theme);

  return (
    <>
      <Text style={styles.title}>
        {`${i18n.t("pages.access.step")} ${idx + 1}: ${getTextFromMultilang(
          i18n.locale,
          textMultiName
        )}`.toUpperCase()}
      </Text>
      {textMultiDesc && <Text style={styles.text}>
        {capitalizeFirstLetter(getTextFromMultilang(i18n.locale, textMultiDesc))}
      </Text>}
      <Divider extraStyle={{ width: "33%" }} />
      {digitCode &&
        <Text style={styles.actionsText}>
          {i18n.t("pages.access.codeText")}
          <Text style={styles.oiqia}>&nbsp;{digitCode}</Text>
        </Text>
      }
      {doorPictureURL && doorPictureURL !== "" && (
        <TouchableOpacity style={styles.image} onPress={() => mobAlert.setImageModal(0, [{ url: doorPictureURL }])}>
          <Image style={styles.image} source={{ uri: doorPictureURL }} />
        </TouchableOpacity>
      )}
    </>
  );
}

const stylesGen = (theme) => StyleSheet.create({
  container: {
    padding: 20,
    paddingBottom: 0,
    borderRadius: 20,
    alignItems: "center",
    gap: 20,
    backgroundColor: theme.colors.secondary,
  },
  title: {
    fontSize: 25,
    textAlign: "center",
    fontFamily: "Gilroy-Bold",
  },
  text: {
    fontSize: 20,
    textAlign: "center",
    fontFamily: "Gilroy-Light",
  },
  image: {
    alignSelf: "center",
    borderRadius: 20,
    width: "90%",
    height: 150,
    marginBottom: 10,
  },
  oiqia: {
    fontSize: 20,
    textAlign: "center",
    fontFamily: "Gilroy-Bold",
    color: theme.colors.primary,
  },
  actionsText: {
    fontSize: 20,
    textAlign: "center",
    fontFamily: "Gilroy-Light",
  },
});
