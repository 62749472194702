import { MultiLang } from "../types/types";

export default function getTextFromMultilang(lng: string, texts: MultiLang): string {
  switch(lng) {
  case "en":
    return texts.en;
  case "es":
    return texts.es;
  case "fr":
    return texts.fr;
  case "it":
    return texts.it;
  case "pt":
    return texts.pt;
  case "ru":
    return texts.ru;
  }
  return "error";
}
