import React, { useState, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { StyleSheet, TouchableOpacity, View, Image, Dimensions } from "react-native";
import { MaterialIcons } from "@expo/vector-icons";
import { mobUser } from "../mobx/MobUser";
import Mutations from "../api/mutations";
import * as ImagePicker from "expo-image-picker";
import AppInput from "./AppInput";
import FieldErrorText from "./FieldErrortext";
import i18n from "../i18n";
import RadioButton from "./RadioButton";
import { MD3Theme, useTheme } from "react-native-paper";
import { Text } from "../utils/fontsLoader";
import { mobAlert } from "../mobx/MobAlert";

const ProblemSection = () => {
  const theme = useTheme();
  const styles = styleGen(theme);
  const mutations = new Mutations();
  const { control, watch, formState: { errors }, setValue, getValues } = useFormContext();
  const [isPortrait, setIsPortrait] = useState(true);

  // Function to check if the screen is in portrait mode
  const checkIfPortrait = () => {
    const dim = Dimensions.get("screen");
    setIsPortrait(dim.height >= dim.width);
  };

  useEffect(() => {
    checkIfPortrait();
  }, []);

  const updateAlert = (title) => {
    mobAlert.setAlert(title, null);
  };

  const isSatisfied = watch("isSatisfied");
  const isCheckDone = watch("isCheckinDone") === true || watch("isCheckoutDone") === true;
  const images = watch("problem.images");

  const takePicture = async (picture) => {
    const currentImages = getValues("problem.images");
    if (currentImages?.length >= 10) {
      updateAlert(i18n.t("commonForm.maxImagesReached"));
      return;
    }
    try {
      const link = await mutations.uploadComplaintImage(
        mobUser.OiqiaBookingId,
        `data:image/jpeg;base64,${picture.base64}`,
      );
      const updatedImages = [...currentImages, link.data.uploadComplaintImage];
      setValue("problem.images", updatedImages);
    } catch (err) {
      console.error("Erreur lors du téléchargement de l'image :", err);
      updateAlert(i18n.t("errors.IMG_UPLOAD"));
    }
  };

  const pickImage = async () => {
    const { granted } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (!granted) {
      updateAlert(i18n.t("permissions.MSG_PERMISSION_LIBRARY"));
      return;
    }
    const currentImages = getValues("problem.images");
    if (currentImages.length >= 10) {
      updateAlert(i18n.t("commonForm.maxImagesReached"));
      return;
    }
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      selectionLimit: 10 - currentImages.length,
      allowsMultipleSelection: true,
      base64: true,
      quality: 0.5,
    });
    if (!result.canceled && result.assets) {
      for (const picture of result.assets) {
        await takePicture(picture);
      }
    }
  };

  const radioOptions = [
    { label: i18n.t("generic.yes"), value: false },
    { label: i18n.t("generic.no"), value: true }
  ];

  return (
    <>
      <View style={styles.field}>
        <Text style={styles.label}>
          {i18n.t("commonForm.isSatisfied")}
        </Text>
        <Controller
          control={control}
          name="isSatisfied"
          render={({ field: { onChange, value } }) => (
            <RadioButton
              options={radioOptions}
              value={value}
              onChange={onChange}
              editable={!isCheckDone}
            />
          )}
        />
      </View>
      {!isSatisfied && (
        <>
          <Controller
            control={control}
            rules={{
              required: i18n.t("errors.missingField"),
            }}
            name="problem.description"
            render={({ field: { onChange, value } }) => (
              <View style={styles.field}>
                <Text style={styles.label}>
                  {i18n.t("commonForm.problemDescription")}
                </Text>
                <AppInput
                  value={value}
                  additionalStyle={styles.inputText}
                  onChangeText={onChange}
                  multiline
                  editable={!isCheckDone}
                />
                {errors.problem?.description && <FieldErrorText errorMessage={errors.problem.description.message} />}
              </View>
            )}
          />
          <View style={styles.field}>
            {images?.length ?
              <View style={styles.row}>
                {images.map((imageUrl, index) => (
                  <Image key={index} source={{ uri: imageUrl }} style={styles.uploadedImage} />
                ))}
              </View> :
              <Text style={styles.label}>
                {i18n.t("commonForm.noImages")}
              </Text>
            }
          </View>
          {!isCheckDone && (
            <View style={styles.row}>
              <TouchableOpacity style={styles.button} onPress={() => mobAlert.openCamera(takePicture, isPortrait)}>
                <MaterialIcons name="camera-alt" size={24} color="white" style={styles.icon} />
                <Text style={styles.buttonText}>{i18n.t("generic.takePhoto")}</Text>
              </TouchableOpacity>
              <TouchableOpacity style={styles.button} onPress={pickImage}>
                <MaterialIcons name="photo-library" size={24} color="white" style={styles.icon} />
                <Text style={styles.buttonText}>{i18n.t("generic.openGallery")}</Text>
              </TouchableOpacity>
            </View>
          )}
        </>
      )}
    </>
  );
};

const styleGen = (theme: MD3Theme) => StyleSheet.create({
  field: {
    alignItems: "center",
    width: "100%",
    gap: 10
  },
  label: {
    fontSize: 20,
    textAlign: "center"
  },
  inputText: {
    fontSize: 20,
    fontFamily: "Gilroy-Light",
  },
  row: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    flexWrap: "wrap",
  },
  button: {
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
    backgroundColor: "#007bff",
    borderRadius: 22,
  },
  buttonText: {
    fontFamily: "Gilroy-SemiBold",
    color: theme.colors.background,
  },
  icon: {
    marginRight: 8,
  },
  uploadedImage: {
    width: 100,
    height: 100,
    margin: 5,
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 10,
  },
});

export default ProblemSection;