import { I18n } from "i18n-js";

import fr_FR from "../locales/fr_FR.json";
import en_GB from "../locales/en_GB.json";
import it_IT from "../locales/it_IT.json";
import pt_PT from "../locales/pt_PT.json";
import ru_RU from "../locales/ru_RU.json";
import es_ES from "../locales/es_ES.json";

const i18n = new I18n({
  fr: fr_FR,
  en: en_GB,
  it: it_IT,
  pt: pt_PT,
  ru: ru_RU,
  es: es_ES,
});

i18n.defaultLocale = "fr";
i18n.locale = "fr";
i18n.enableFallback = true;

export type availableLanguages = "fr" | "en" | "it" | "pt" | "ru" | "es";

export const allLanguages: availableLanguages[] = [
  "fr", "en", "it", "pt", "ru", "es"
];

export const changeLanguage = (lang: availableLanguages) => {
  i18n.locale = lang;
};

export default i18n;
