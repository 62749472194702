import React from "react";
import { StyleSheet, View } from "react-native";
import { MD3Theme, useTheme } from "react-native-paper";

interface DividerProps {
  extraStyle?: object
}

export default function Divider({ extraStyle }: DividerProps) {
  const theme = useTheme();
  const styles = styleGen(theme);
  return (
    <View style={{ ...styles.divider, ...extraStyle }} />
  );
}

const styleGen = (theme: MD3Theme) => StyleSheet.create({
  divider: {
    backgroundColor: theme.colors.onBackground,
    height: 1,
    width: "100%",
  }
});
