import { Linking, Platform } from "react-native";

export function openMapByLatLng(lat: string, lng: string) {
  const base = Platform.select({ ios: "maps://0,0?q=", android: "geo:0,0?q=", web: "https://www.google.fr/maps?q=" });
  const latLng = `${lat},${lng}`;
  const url = Platform.select({
    ios: `${base}${latLng}`,
    android: `${base}${latLng}`,
    web: `${base}${latLng}`
  });

  try{
    Linking.openURL(url);
  } catch {
    // impossible d'ouvrir la map
  }
}
