import React from "react";
import { StyleSheet, View } from "react-native";
import { AppartDoorsWithPictures } from "../../../types/types";
import i18n from "../../../i18n";
import { MD3Theme, useTheme } from "react-native-paper";

import DoorCardCommonHeader from "./DoorCardCommonHeader";
import { Text } from "../../../utils/fontsLoader";

interface DoorCardProps {
  door: AppartDoorsWithPictures;
  idx: number;
}

export default function DoorCardIglooBac({ door, idx }: DoorCardProps) {
  const theme = useTheme();
  const styles = styleGen(theme);

  return (
    <View>
      <View style={styles.container}>
        <DoorCardCommonHeader
          idx={idx}
          digitCode={null}
          textMultiName={door.textMultiName}
          textMultiDesc={door.textMultiDesc}
          doorPictureURL={door.doorPictureURL}
        />
        <View style={styles.actions}>
          <Text style={styles.actionsTitle}>
            {i18n.t("pages.access.openTitleBAC")}
          </Text>
          <Text style={styles.actionsText}>
            {i18n.t("pages.access.openText")}
            <Text style={styles.oiqia}>&nbsp;{door.manualCode}</Text>
          </Text>
        </View>
      </View>
    </View>
  );
}

const styleGen = (theme: MD3Theme) => StyleSheet.create({
  container: {
    backgroundColor: theme.colors.secondary,
    padding: 20,
    paddingBottom: 20,
    borderRadius: 20,
    alignItems: "center",
    gap: 20,
  },
  title: {
    fontSize: 25,
    textAlign: "center",
    fontFamily: "Gilroy-Bold",
  },
  actions: {
    width: "80%",
    marginTop: 0,
  },
  actionsTitle: {
    fontSize: 20,
    textAlign: "center",
    fontFamily: "Gilroy-Light",
    marginBottom: 20,
  },
  actionsText: {
    fontSize: 20,
    textAlign: "center",
    fontFamily: "Gilroy-Light",
  },
  orText: {
    fontSize: 20,
    textAlign: "center",
    fontFamily: "Gilroy-Light",
    marginTop: 10,
  },
  extraStyleBtn: {
    marginTop: 10,
    paddingVertical: 7,
  },
  extraStyleBtnText: { fontFamily: "Gilroy-Medium" },
  oiqia: {
    fontSize: 20,
    textAlign: "center",
    fontFamily: "Gilroy-Bold",
    color: theme.colors.primary,
  },
  laoding: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});
