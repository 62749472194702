function isObject(item) {
  return (typeof item === "object" && !Array.isArray(item) && item !== null);
}

export function findDifferences(currentValues, defaultValues) {
  const differences = {};

  Object.keys(currentValues).forEach(key => {
    if (isObject(currentValues[key]) && isObject(defaultValues[key])) {
      // Comparaison récursive pour les sous-objets
      const deeperDifferences = findDifferences(currentValues[key], defaultValues[key]);
      if (Object.keys(deeperDifferences).length > 0) {
        differences[key] = deeperDifferences;
      }
    } else if (Array.isArray(currentValues[key]) && Array.isArray(defaultValues[key])) {
      // Comparaison spécifique pour les tableaux
      if (currentValues[key].length !== defaultValues[key].length || !currentValues[key].every((val, index) => val === defaultValues[key][index])) {
        differences[key] = currentValues[key];
      }
    } else if (currentValues[key] !== defaultValues[key]) {
      // Cas général pour les valeurs primitives
      differences[key] = currentValues[key];
    }
  });

  return differences;
}