import { action, makeObservable, observable, runInAction } from "mobx";
import * as Notifications from "expo-notifications";

import DDP from "../api/DDP/DDP";
import { mobUser } from "./MobUser";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Queries from "../api/queries";

//msgNumberSub?.unsubscribe();

class MobNotif {
  chatSub: any;
  chatData = [];
  isInChat = false;
  badge = 0;
  lastMessageID = "";

  constructor() {
    this.init();
    makeObservable(this, {
      chatData: observable,
      isInChat: observable,
      badge: observable,
      lastMessageID: observable,
      setChatSub: action,
      resetChat: action,
      incrementAppBadgeCount: action,
      setAppBadgeCount: action,
      subscribeToUnreadNumber: action,
    });
  }

  subscribeToUnreadNumber = async () => {
    const queries = new Queries();
    const response = await queries.countUnreadMessage(mobUser.OiqiaBookingId, this.lastMessageID);
    this.setAppBadgeCount(response.data.countUnreadMessage?.result);
  };

  init = async () => {
    const currentBadgeCount = await Notifications.getBadgeCountAsync();
    const lastMessageID = await AsyncStorage.getItem("lastMessageID") || "";
    runInAction(() => {
      this.badge = currentBadgeCount;
      this.lastMessageID = lastMessageID;
    });
    this.subscribeToUnreadNumber();
  };

  resetChat = async () => {

    console.log("reset chat store");

    if (this.chatSub) {
      console.log("SUB Stop");
      await this.chatSub.remove();
    }
    this.chatSub = null;
    this.chatData = [];
  };

  InitChatSub = async (oiqiaId: string) => {
    mobUser.setConversationEngaged(true);
    this.setAppBadgeCount(0);
    try {

      if (!this.chatSub) {
        this.chatSub = DDP.subscribe(
          "Notifications.SubChat",
          oiqiaId
        );

        //console.log("mideal Init notifications", this.chatSub);

        await this.chatSub.ready();

        //  console.log("end Init notifications", oiqiaId);
      }
    } catch (err) {
      console.log("Error Init Notifications.SubChat ", err);
    }
  };

  setChatSub = async (oiqiaId: string) => {
    if (!this.chatSub) await this.InitChatSub(oiqiaId);
    runInAction(async () => {
      const chatMsgCollection = DDP.collection("chat-incident-msg").reactive();
      this.chatData = chatMsgCollection.data();
      if (this.chatData.length) {
        const dernierMessage = this.chatData[0].conversation.reduce((acc, message) => {
          return new Date(acc.createdAt) > new Date(message.createdAt) ? acc : message;
        });
        this.lastMessageID = dernierMessage._id;
        await AsyncStorage.setItem("lastMessageID", this.lastMessageID);
      }
      //console.log("data subscribe", this.chatData)
      chatMsgCollection.onChange((newData: any) => {
        runInAction(async () => {
          //console.log("detect subscribe new data", newData);
          this.chatData = newData;
          if (this.chatData.length) {
            const dernierMessage = this.chatData[0].conversation.reduce((acc, message) => {
              return new Date(acc.createdAt) > new Date(message.createdAt) ? acc : message;
            });
            this.lastMessageID = dernierMessage._id;
            await AsyncStorage.setItem("lastMessageID", this.lastMessageID);
          }
        });
      });
    });
  };

  incrementAppBadgeCount = async () => {
    runInAction(async () => {
      const currentBadgeCount = await Notifications.getBadgeCountAsync();
      this.badge = currentBadgeCount + 1;
      return await Notifications.setBadgeCountAsync(currentBadgeCount + 1);
    });
  };

  setAppBadgeCount = async (number: number) => {
    runInAction(async () => {
      this.badge = number;
      await Notifications.setBadgeCountAsync(number);
    });
  };

}

export const mobNotif = new MobNotif();