import { mobAlert } from "../mobx/MobAlert";
import i18n from "../i18n";

export const handleGraphQLError = (message, locations, path, extensions) => {
  switch (extensions?.exception?.error) {
  case "OUT_OF_RESERVATION":
    //console.error("Action interdite en dehors de votre réservation", `Localisation: ${locations}`, `Chemin: ${path}`);
    mobAlert.setError(i18n.t("generic.error"), i18n.t("pages.access.outsideBooking"));
    break;
  default:
    console.error(`[Erreur non gérée]: Message: ${message}, Localisation: ${locations}, Chemin: ${path}`);
    mobAlert.setError(i18n.t("errors.UNKNOWN_ERROR_TITLE"), i18n.t("errors.UNKNOWN_ERROR_TEXT", { errorCode: message }));
  }
};

