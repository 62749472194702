import React from "react";
import { StyleSheet } from "react-native";
import { InfoCardTextProps } from "../types/types";
import { Text } from "../utils/fontsLoader";

export default function InfoCardText({ title = null, text = null }: InfoCardTextProps) {
  return (
    <>
      {title && <Text style={styles.title}>
        {title}
      </Text>
      }
      {text &&
        <Text style={styles.text}>
          {text}
        </Text>
      }
    </>
  );
}

const styles = StyleSheet.create({
  title: {
    fontFamily: "Gilroy-Bold",
    fontSize: 20,
    padding: 3,
  },
  text: {
    fontSize: 18,
    padding: 3,

  }
});