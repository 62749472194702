import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useTheme } from "react-native-paper";
import CustomHeader from "./CustomHeader";
import Therms from "../pages/Login/Therms";
import Login from "../pages/Login";
import ChooseLanguage from "../pages/Login/ChooseLanguage";

const LoginStack = createNativeStackNavigator();

export function LoginStackScreen() {
  const theme = useTheme();

  return (
    <LoginStack.Navigator screenOptions={{
      statusBarColor: theme.colors.primary,
      headerStyle: { backgroundColor: theme.colors.secondary },
      navigationBarHidden: true,
    }}
    >
      <LoginStack.Screen name="Language" component={ChooseLanguage} options={{ headerShown: false }} />
      <LoginStack.Screen name="Login" component={Login} options={{ headerShown: false }} />
      <LoginStack.Screen
        name="Therms"
        component={Therms}
        options={{
          statusBarColor: theme.colors.secondary,
          header: () => CustomHeader({ text: `Conditions${"\n"}générales d'utilisation`, hasArrow: true, theme })
        }}
      />
    </LoginStack.Navigator>
  );
}
