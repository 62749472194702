import React, { useState } from "react";
import { Image, Pressable, StyleSheet, View } from "react-native";
import i18n, { allLanguages, availableLanguages, changeLanguage } from "../../i18n";
import { mobUser } from "../../mobx/MobUser";
import { observer } from "mobx-react";
import { Text } from "../../utils/fontsLoader";
import { useTheme } from "react-native-paper";

function getFlagImageSource(lng: availableLanguages) {
  switch (lng) {
  case "fr":
    return require("../../../assets/flags/fr.png");
  case "en":
    return require("../../../assets/flags/en.png");
  case "it":
    return require("../../../assets/flags/it.png");
  case "pt":
    return require("../../../assets/flags/pt.png");
  case "ru":
    return require("../../../assets/flags/ru.png");
  case "es":
    return require("../../../assets/flags/es.png");
  default:
    return require("../../../assets/flags/fr.png");
  }
}

interface LanguageButtonProps {
  lng: availableLanguages
}

const LanguageButton = observer(({ lng }: LanguageButtonProps) => {
  const theme = useTheme();

  async function updateSelectedLng() {
    mobUser.AS_SetLangage(lng);
    changeLanguage(lng);
  }

  return (
    <Pressable
      onPress={updateSelectedLng}
      style={{
        ...styles.button,
        backgroundColor: mobUser.Language === lng ? theme.colors.primary : theme.colors.secondary
      }}
    >
      <Image style={styles.flag} source={getFlagImageSource(lng)} />
    </Pressable>
  );
});

const ChangeLanguageForm = observer(() => {
  const [chooseLanguageText, setChooseLanguageText] = useState<string>(i18n.t("chooseLanguage.choose"));

  i18n.onChange(() => {
    setChooseLanguageText(i18n.t("chooseLanguage.choose"));
  });

  return (
    <View style={styles.container}>
      <Text style={styles.text}>
        {chooseLanguageText}
      </Text>
      <View style={styles.lngList}>
        {allLanguages.map((value: availableLanguages, idx: number) => (
          <LanguageButton
            key={idx}
            lng={value}
          />
        ))}
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 50,
  },
  lngList: {
    margin: 30,
    gap: 30,
    width: "80%",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "center",
  },
  button: {
    width: 65,
    height: 65,
    borderRadius: 100,
    justifyContent: "center",
    alignItems: "center",
  },
  flag: {
    width: 1080 / 25,
    height: 720 / 25,
    borderRadius: 5
  },
  text: {
    fontSize: 20,
    fontFamily: "Gilroy-Light"
  }
});

export default ChangeLanguageForm;