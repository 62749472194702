import React from "react";
import { StyleSheet, View } from "react-native";
import i18n from "../../../i18n";
import { Text } from "../../../utils/fontsLoader";

export default function IntroPage() {

  return (
    <View style={styles.container}>
      <Text style={styles.text}>
        {i18n.t("pages.access.intro")}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
    gap: 60
  },
  text: {
    fontSize: 17,
    textAlign: "center",
    fontFamily: "Gilroy-Bold",
    lineHeight: 40
  },
});
