import { action, makeObservable, observable, runInAction } from "mobx";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Queries from "../api/queries";
import Mutations from "../api/mutations";
import { registerForPushNotificationsAsync } from "../notifications";
import { mobAlert } from "./MobAlert";
import i18n from "../i18n";

const queries = new Queries();
const mutation = new Mutations();
class MobUser {
  OiqiaBookingId = "";
  smoobuId = "";
  UserLogged = false;
  isConversationEngaged = false;
  isGuestbookDone = false;
  isInvoiceAsked = false;
  Language = "fr";
  Email = "";
  IsLoading = true;
  history: string[] = [];

  constructor() {
    this.InitStore();
    makeObservable(this, {
      OiqiaBookingId: observable,
      Language: observable,
      isConversationEngaged: observable,
      UserLogged: observable,
      setConversationEngaged: action,
      isGuestbookDone: observable,
      setGestbookDone: action,
      isInvoiceAsked: observable,
      setInvoiceAsked: action,
      InitStore: action,

      AS_SetLangage: action,
      AS_SignIn: action,
      LogOut: action
    });

    // makeAutoObservable(this)
  }

  /*   get IsLogged() {
      return !(!this.OiqiaBookingId || !this.Language);
    }
   */

  verifyUser() {//check verify
    runInAction(() => {
      this.UserLogged = !(!this.OiqiaBookingId || !this.Language);
    });
  }

  InitStore = async () => {
    try {
      const language = await AsyncStorage.getItem("Language") || "fr";
      const email = await AsyncStorage.getItem("Email");
      const oiqiaBookingId = await AsyncStorage.getItem("OiqiaBookingId");
      const isConversationEngaged = await AsyncStorage.getItem("isConversationEngaged") === "true";
      const isGuestbookDone = await AsyncStorage.getItem("isGuestbookDone") === "true";
      const isInvoiceAsked = await AsyncStorage.getItem("isInvoiceAsked") === "true";
      const history = JSON.parse(await AsyncStorage.getItem("connectHistory") || "[]");

      runInAction(() => {
        this.Language = language;
        this.Email = email;
        this.OiqiaBookingId = oiqiaBookingId;
        this.isConversationEngaged = isConversationEngaged;
        this.isGuestbookDone = isGuestbookDone;
        this.isInvoiceAsked = isInvoiceAsked;
        this.IsLoading = false;
        this.history = history;
        this.verifyUser();
      });

    } catch (error) {
      console.log("ERROR during intialisation get", error);
      // Gestion de l'erreur
    }

    console.log("init store done value :", this.Language, this.Email, this.OiqiaBookingId);
  };

  AS_SetLangage = async (language) => {
    try {
      await AsyncStorage.setItem("Language", language);
    } catch (error) {
      console.log("ERROR during set Language", error);
    }
    runInAction(() => {
      this.Language = language;
      this.verifyUser();
    });
  };

  AS_SignIn = async (email, smoobuId,) => {
    try {
      await AsyncStorage.setItem("Email", email);
    } catch (error) {
      console.log("ERROR during set Email", error);
    }

    this.Email = email;
    // add call to graphql must be midified adding language
    // simulator

    this.smoobuId = smoobuId;

    const response = await queries.getBookingIdByExtId(smoobuId);
    this.handleConnectionProcess(response, smoobuId, email);

    console.log("end mobx signin", this.OiqiaBookingId);
  };

  handleConnectionProcess = async (response: any, smoobuId: string, email: string) => {

    const saveID = async (id: string) => {
      try {
        await AsyncStorage.setItem("OiqiaBookingId", id);
      } catch (error) {
        console.log("ERROR during set OiqiaBookingId", error);
      }
    };

    if (response.data.getOiqiaIdByExtId?.bookingIdOiqia) {
      this.OiqiaBookingId = response.data.getOiqiaIdByExtId.bookingIdOiqia;
      saveID(this.OiqiaBookingId);

      let token;
      try {
        token = await registerForPushNotificationsAsync();
      } catch (err) {// case of web or else managed into de the back end filtered
        mutation.pushTokenNotif("", smoobuId, this.Language);
      }

      if (token) mutation.pushTokenNotif(token, smoobuId, this.Language);

      mutation.agreeTerms(this.OiqiaBookingId, email);

      //get first name to custom welcome pop up
      const resa = await queries.getResaInfo(this.OiqiaBookingId);
      if (!this.history.includes(this.OiqiaBookingId)) {
        this.history.push(this.OiqiaBookingId);
        AsyncStorage.setItem("connectHistory", JSON.stringify(this.history));
        mobAlert.setAlert(
          i18n.t("home.welcomeTitle", { firstName: resa.firstName }),
          i18n.t("home.welcomeText")
        );
      }
    } else {
      mobAlert.setError(i18n.t("login.errorTitle"), i18n.t("login.errorMessage"));
    }
    this.verifyUser();
  };

  setConversationEngaged = async (engaged) => {
    try {
      await AsyncStorage.setItem("isConversationEngaged", engaged.toString());
      runInAction(() => {
        this.isConversationEngaged = engaged;
      });
    } catch (error) {
      console.error("ERROR during set isConversationEngaged", error);
    }
  };

  setGestbookDone = async (done) => {
    try {
      await AsyncStorage.setItem("isGuestbookDone", done.toString());
      runInAction(() => {
        this.isGuestbookDone = done;
      });
    } catch (error) {
      console.error("ERROR during set isGuestbookDone", error);
    }
  };

  setInvoiceAsked = async (done) => {
    try {
      await AsyncStorage.setItem("isInvoiceAsked", done.toString());
      runInAction(() => {
        this.isInvoiceAsked = done;
      });
    } catch (error) {
      console.error("ERROR during set isInvoiceAsked", error);
    }
  };

  LogOut = () => {
    AsyncStorage.removeItem("Email");
    AsyncStorage.removeItem("OiqiaBookingId");
    AsyncStorage.removeItem("isConversationEngaged");
    AsyncStorage.removeItem("isGuestbookDone");
    AsyncStorage.removeItem("isInvoiceAsked");
    this.OiqiaBookingId = "";
    this.smoobuId = "";
    this.isConversationEngaged = false;
    this.isGuestbookDone = false;
    this.Language = "fr";
    this.Email = "";
    this.verifyUser();
  };
}

export const mobUser = new MobUser();