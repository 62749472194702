import React from "react";
import { StyleSheet, View } from "react-native";
import { AppartDoorsWithPictures } from "../../../types/types";
import { MD3Theme, useTheme } from "react-native-paper";
import DoorCardCommonHeader from "./DoorCardCommonHeader";

interface DoorCardProps {
  door: AppartDoorsWithPictures;
  idx: number;
}

export default function DoorCard({ door, idx }: DoorCardProps) {
  const theme = useTheme();
  const styles = stylesGen(theme);

  return (
    <View style={styles.container}>
      <DoorCardCommonHeader
        idx={idx}
        digitCode={door.digitCode ? door.digitCode : door.manualCode}
        textMultiName={door.textMultiName}
        textMultiDesc={door.textMultiDesc}
        doorPictureURL={door.doorPictureURL}
      />
    </View>
  );
}

const stylesGen = (theme: MD3Theme) => StyleSheet.create({
  container: {
    padding: 20,
    borderRadius: 20,
    alignItems: "center",
    backgroundColor: theme.colors.secondary,
    gap: 20,
  },
});
