import React from "react";
import { View, StyleSheet } from "react-native";
import ChooseLanguageForm from "../../Login/ChooseLanguageForm";

const Language = () => {
  return (
    <View style={styles.container}>
      <ChooseLanguageForm isHomePage />
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    //paddingVertical:"50%"
  },
});
export default Language;
