import React, { useEffect, useRef } from "react";
import { Platform, StyleSheet, View } from "react-native";
import { useForm, Controller, FormProvider } from "react-hook-form";
import Rating from "../../../components/Rating";
import AppInput from "../../../components/AppInput";
import FieldErrorText from "../../../components/FieldErrortext";
import AppButton from "../../../components/AppButton";
import i18n from "../../../i18n";
import { CheckInData } from "../../../types/types";
import { mobUser } from "../../../mobx/MobUser";
import Mutations from "../../../api/mutations";
import PhoneInputCustom from "../../../components/PhoneInputCustom";
import ProblemSection from "../../../components/ProblemSection";
import { mobAlert } from "../../../mobx/MobAlert";
import { Text } from "../../../utils/fontsLoader";
import DoneCard from "../../../components/DoneCard";
import { findDifferences } from "../../../utils/formUtils";
import { useNavigation } from "@react-navigation/native";

interface CheckInForm {
  checkInInfos: CheckInData
}

export default function CheckInForm({ checkInInfos }) {
  const navigation = useNavigation();
  const styles = stylesGen();
  const mutations = new Mutations();

  const defaultValues = {
    ratingCleanliness: 0,
    firstName: "",
    lastName: "",
    email: mobUser.Email || "",
    phoneNumber: "",
    nbAdults: "",
    nbChildren: "",
    isSatisfied: true,
    problem: {
      description: "",
      images: []
    },
    isCheckinDone: false
  };
  const formMethods = useForm({
    defaultValues,
  });

  const {
    control,
    reset,
    watch,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const isCheckinDone = watch("isCheckinDone");

  useEffect(() => {
    // Mise à jour des valeurs par défaut du formulaire avec les nouvelles données de checkInInfos
    const currentValues = getValues();

    const updated_value = {
      ...currentValues,
      ratingCleanliness: checkInInfos?.ratingCleanliness || 0,
      firstName: checkInInfos?.firstName || "",
      lastName: checkInInfos?.lastName || "",
      email: checkInInfos?.email || mobUser.Email || "",
      phoneNumber: checkInInfos?.phone || "",
      nbAdults: checkInInfos?.adults.toString() || "",
      nbChildren: checkInInfos?.children.toString() || "",
      isSatisfied: checkInInfos?.isSatisfied === undefined ? true : checkInInfos?.isSatisfied,
      problem: {
        description: checkInInfos?.problem?.description || "",
        images: checkInInfos?.problem?.image || []
      },
      isCheckinDone: checkInInfos?.isCheckinDone || false,
    };

    const diff = findDifferences(currentValues, defaultValues);

    reset({
      ...updated_value,
      ...diff
    });
  }, [checkInInfos, reset, getValues]);

  useEffect(() => {
    return () => {
      const currentFormData = getValues();
      if (!currentFormData.isCheckinDone) {
        updateCheckIn(currentFormData);
      }
    };
  }, []);

  const onError = () => {
    mobAlert.closeAlert();
    mobAlert.setError(i18n.t("errors.missingField"), i18n.t("errors.missingFieldText"));
  };

  const updateCheckIn = async (data) => {
    const transformedData = {
      bookingId: mobUser.OiqiaBookingId,
      email: data.email,
      firstName: data.firstName,
      isSatisfied: data.isSatisfied,
      lastName: data.lastName,
      adults: parseInt(data.nbAdults, 10),
      children: parseInt(data.nbChildren, 10),
      phone: data.phoneNumber,
      problem: {
        description: data.problem.description,
        image: data.problem.images,
      },
      ratingCleanliness: data.ratingCleanliness,
      isCheckinDone: data.isCheckinDone
    };
    try {
      //await mutations.setCheckIn(transformedData);
      if (data.isCheckinDone) mobAlert.setLoading(true);
      const response = await mutations.setCheckIn(transformedData).then(() => {
        if (data.isCheckinDone) {
          mobAlert.setLoading(false);
          mobAlert.setSuccess(
            i18n.t("pages.checkIn.successTitle"),
            i18n.t("pages.checkIn.successMessage"),
            () => {
              mobAlert.closeSuccess();
              //scrollRef.current.scrollTo({ x: 0, y: 0, animated: true });
              navigation.goBack();
            }
          );
        }
      });
      console.log("Check-in successful:", response);
    } catch (error) {
      mobAlert.setLoading(false);
      console.error("Check-in failed:", error);
    }
  };

  async function onSubmit(data) {
    mobAlert.closeAlert();
    setValue("isCheckinDone", true);
    updateCheckIn({ ...data, isCheckinDone: true });
  }

  const phoneRef = useRef(null);

  return (
    <View style={styles.container}>
      {isCheckinDone && <DoneCard field={{ title: i18n.t("pages.checkIn.doneMessage") }} />}
      <FormProvider {...formMethods}>
        <Controller
          control={control}
          rules={{
            required: i18n.t("errors.missingField"),
            validate: value => value > 0 || i18n.t("errors.ratingGreaterThanZeroError")
          }}
          render={({ field: { onChange, value } }) => (
            <View style={styles.field}>
              <Text style={styles.label}>
                {Platform.OS !== "web" ?
                  i18n.t("pages.checkIn.housingCleanliness")
                  :
                  i18n.t("pages.checkIn.housingCleanlinessWeb")
                }
              </Text>
              <Rating value={value} setValue={onChange} editable={!isCheckinDone} />
              {(errors.ratingCleanliness) && <FieldErrorText errorMessage={errors.ratingCleanliness.message} />}
            </View>
          )}
          name="ratingCleanliness"
        />
        <Controller
          control={control}
          rules={{
            required: i18n.t("errors.missingField"),
          }}
          render={({ field: { onChange, value } }) => (
            <View style={styles.field}>
              <Text style={styles.label}>
                {i18n.t("pages.checkIn.firstName")}
              </Text>
              <AppInput
                value={value}
                onChangeText={onChange}
                additionalStyle={styles.inputText}
                editable={!isCheckinDone}
              />
              {errors.firstName && <FieldErrorText errorMessage={errors.firstName.message} />}
            </View>
          )}
          name="firstName"
        />
        <Controller
          control={control}
          rules={{
            required: i18n.t("errors.missingField"),
          }}
          render={({ field: { onChange, value } }) => (
            <View style={styles.field}>
              <Text style={styles.label}>
                {i18n.t("pages.checkIn.lastName")}
              </Text>
              <AppInput
                value={value}
                onChangeText={onChange}
                additionalStyle={styles.inputText}
                editable={!isCheckinDone}
              />
              {errors.lastName && <FieldErrorText errorMessage={errors.lastName.message} />}
            </View>
          )}
          name="lastName"
        />
        <Controller
          control={control}
          rules={{
            required: i18n.t("errors.missingField"),
            pattern: {
              value: /^[\w.%+-]+@[a-z0-9-]+\.[a-z]{2,}$/i,
              message: i18n.t("errors.emailFormatError")
            }
          }}
          render={({ field: { onChange, value } }) => (
            <View style={styles.field}>
              <Text style={styles.label}>
                {i18n.t("pages.checkIn.email")}
              </Text>
              <AppInput
                value={value}
                onChangeText={onChange}
                additionalStyle={styles.inputText}
                autoCapitalize="none"
                keyboardType="email-address"
                editable={!isCheckinDone}
              />
              {errors.email && <FieldErrorText errorMessage={errors.email.message} />}
            </View>
          )}
          name="email"
        />
        <Controller
          control={control}
          rules={{
            required: i18n.t("errors.missingField"),
            validate: value => phoneRef.current.isValidNumber(value) || i18n.t("pages.checkIn.badPhoneFormat")
          }}
          render={({ field: { onChange, value } }) => (
            <View style={styles.field}>
              <Text style={styles.label}>
                {i18n.t("pages.checkIn.travelerPhone")}
              </Text>
              <PhoneInputCustom
                ref={phoneRef}
                value={value}
                onChangeText={onChange}
                additionalStyle={styles.inputText}
                editable={!isCheckinDone}
              />
              {errors.phoneNumber && <FieldErrorText errorMessage={errors.phoneNumber.message} />}
            </View>
          )}
          name="phoneNumber"
        />
        <Controller
          control={control}
          rules={{
            required: true,
            validate: value => value > 0 || i18n.t("pages.checkIn.adultsGreaterThanZeroError")
          }}
          render={({ field: { onChange, value, onBlur } }) => (
            <View style={styles.field}>
              <Text style={styles.label}>
                {i18n.t("pages.checkIn.nbAdults")}
              </Text>
              <AppInput
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
                additionalStyle={styles.inputText}
                keyboardType='number-pad'
                editable={!isCheckinDone}
              />
              {errors.nbAdults && <FieldErrorText errorMessage={errors.nbAdults.message} />}

            </View>
          )}
          name="nbAdults"
        />
        <Controller
          control={control}
          rules={{
            required: i18n.t("errors.missingField"),
          }}
          render={({ field: { onChange, value, onBlur } }) => (
            <View style={styles.field}>
              <Text style={styles.label}>
                {i18n.t("pages.checkIn.nbChildren")}
              </Text>
              <AppInput
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
                additionalStyle={styles.inputText}
                keyboardType='number-pad'
                editable={!isCheckinDone}
              />
              {errors.nbChildren && <FieldErrorText errorMessage={errors.nbChildren.message} />}
            </View>
          )}
          name="nbChildren"
        />
        <ProblemSection />
        {!isCheckinDone && (
          <AppButton extraStyle={styles.submitButton} onPress={
            () =>
              mobAlert.setAlert(
                i18n.t("alert.CHECKIN_VALIDATION_TITLE"),
                i18n.t("alert.CHECKIN_VALIDATION_SUBTITLE"),
                i18n.t("pages.checkIn.submit"),
                handleSubmit(onSubmit, onError),
                i18n.t("generic.cancel"),
                mobAlert.closeAlert
              )

            //handleSubmit(onSubmit, onError)
          }>
            {i18n.t("pages.checkIn.submit")}
          </AppButton>
        )}
      </FormProvider>
    </View >
  );
}

const stylesGen = () => StyleSheet.create({
  container: {
    alignItems: "center",
    gap: 25
  },
  field: {
    alignItems: "center",
    width: "100%",
    gap: 10
  },
  label: {
    fontSize: 20,
    textAlign: "center"
  },
  inputText: {
    fontSize: 20,
    fontFamily: "Gilroy-Light",
  },
  submitButton: {
    marginTop: 20
  },
});