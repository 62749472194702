import React, { useEffect, useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import CheckInForm from "./CheckInForm";
import Queries from "../../../api/queries";
import { ApolloQueryResult } from "@apollo/client";
import { CheckInData, InfoCardTextProps } from "../../../types/types";
import { Subscription } from "zen-observable-ts";
import { mobUser } from "../../../mobx/MobUser";
import i18n from "../../../i18n";
import InfoCard from "../../../components/InfosCard";
import { formatDate } from "../../../utils/dateFormater";

export default function CheckIn() {
  const [fields, setFields] = useState<InfoCardTextProps[]>();
  const [checkInInfos, setCheckInInfos] = useState<CheckInData>();
  const queries = new Queries();

  useEffect(() => {
    let checkInDataSubscription: Subscription;

    async function subscribeToData() {
      try {
        const checkInObservable = await queries.getCheckIn(mobUser.OiqiaBookingId);
        checkInDataSubscription = checkInObservable.subscribe({
          next(response: ApolloQueryResult<{ NTA_getCheckIn: CheckInData }>) {
            setCheckInInfos(response.data.NTA_getCheckIn);
            setFields([
              { title: i18n.t("pages.checkIn.arrival"), text: formatDate(response.data.NTA_getCheckIn?.arrival) },
              { title: i18n.t("pages.checkIn.departure"), text: formatDate(response.data.NTA_getCheckIn?.departure) },
            ]);
          },
          error(err) {
            console.error("Error fetching wifi data:", err);
          }
        });
      } catch (error) {
        console.error("Error setting up subscription:", error);
      }
    }

    subscribeToData();

    return () => {
      checkInDataSubscription?.unsubscribe();
    };
  }, []);

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <View style={styles.subContainer}>
        <InfoCard fieldMap={fields} />
        <CheckInForm checkInInfos={checkInInfos}/>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
  },
  subContainer: {
    marginTop: 20,
    marginBottom: 20,
    gap: 10,
    width: "80%"
  }
});
