import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { ApolloQueryResult } from "@apollo/client";
import Queries from "../../../api/queries";
import { mobUser } from "../../../mobx/MobUser";
import { WifiInfos } from "../../../types/types";
import { useTheme } from "react-native-paper";
import WifiCardSkeleton from "./WifiCardSkeleton";
import WifiCardInfos from "./WifiCardInfos";
import { observer } from "mobx-react";

const Wifi = observer(() => {
  const [wifiInfos, setWifiInfos] = useState<WifiInfos>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const queries = new Queries();
  const theme = useTheme();

  useEffect(() => {
    let wifiSubscription;

    async function subscribeToWifiData() {
      try {
        const wifiObservable = await queries.getApartmentWifi(mobUser.OiqiaBookingId);
        wifiSubscription = wifiObservable.subscribe({
          next(response: ApolloQueryResult<{ NTA_getAppartmentCaract: WifiInfos }>) {
            setWifiInfos(response.data.NTA_getAppartmentCaract);
            setIsLoading(false);
          },
          error(err) {
            console.error("Error fetching wifi data:", err);
          }
        });
      } catch (error) {
        console.error("Error setting up subscription:", error);
      }
    }

    subscribeToWifiData();

    return () => {
      wifiSubscription?.unsubscribe();
    };
  }, []);

  return (
    <View style={styles.container}>
      <View style={{
        ...styles.wifiCard,
        backgroundColor: theme.colors.secondary
      }}>
        {isLoading ?
          <WifiCardSkeleton />
          :
          <WifiCardInfos wifiInfos={wifiInfos} />
        }
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    paddingTop: 30
  },
  wifiCard: {
    width: "80%",
    borderRadius: 20,
    padding: 15,
    gap: 15,
    minHeight: 150
  },
  title: {
    fontSize: 20,
    fontFamily: "Gilroy-Bold"
  }
});

export default Wifi;