import React from "react";
import { Dimensions, Pressable, StyleSheet, View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { ArrowIcon } from "../components/Icons";
import { Text } from "../utils/fontsLoader";
import { MD3Theme } from "react-native-paper";

interface CustomHeaderProps {
  text: string,
  hasArrow: boolean,
  backPath?: string
  theme: MD3Theme
}

const CustomHeader = ({ text, hasArrow, backPath, theme }: CustomHeaderProps) => {
  const navigation = useNavigation();
  const styles = stylesGen(theme);

  const handlePress = () => {
    if (backPath) {
      navigation.navigate(backPath);
    } else {
      navigation.goBack();
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.textContainer}>
        <Text style={styles.text}>{text}</Text>
      </View>
      {hasArrow &&
        <Pressable onPress={handlePress} style={styles.icon}>
          <ArrowIcon color={theme.colors.primary} />
        </Pressable>
      }
    </View>
  );
};

const stylesGen = (theme: MD3Theme) => StyleSheet.create({
  container: {
    alignItems: "center",
    padding: 10,
    flexDirection: "row",
    backgroundColor: theme.colors.secondary,
    //marginTop: (Platform.OS === "ios" ? Constants.statusBarHeight : undefined),
  },
  icon: {
    position: "absolute",
    left: 5,
  },
  textContainer: {
    width: "100%",
  },
  text: {
    textAlign: "center",
    color: theme.colors.primary,
    fontSize: Dimensions.get("screen").height / 30
  }
});

export default CustomHeader;