import React from "react";
import { Dimensions, StyleSheet, View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import i18n from "../../i18n";
import RadioButton from "../../components/RadioButton";
import { Text } from "../../utils/fontsLoader";

interface CGUProps {
  buttonStatus: boolean,
  setButtonStatus: any
}

export default function AcceptTherms({ buttonStatus, setButtonStatus }: CGUProps) {
  const navigation = useNavigation();
  const radioOptions = [
    { label: buttonStatus, value: true },
  ];
  return (
    <View style={styles.Therms}>
      <RadioButton
        options={radioOptions}
        value={buttonStatus}
        onChange={setButtonStatus}
        editable={true}
        sizeFactor={1.2}
      />
      <View>
        <Text style={styles.text}>
          {i18n.t("login.acceptCGU1")}{" "}
          <Text onPress={() => navigation.navigate("Therms")} style={{ ...styles.text, ...styles.underline }}>
            {i18n.t("login.acceptCGU2")}
          </Text>
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  Therms: {
    width: "90%",
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
  },
  text: {
    textAlign: "center",
    fontSize: Dimensions.get("screen").height / 40,
    fontFamily: "Gilroy-Light",
    flexWrap: "wrap",
  },
  underline: {
    textDecorationLine: "underline"
  }
});
