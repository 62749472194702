import React from "react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { HomeIcon, HouseIcon, KeyIcon } from "../components/Icons";
import { useTheme } from "react-native-paper";
import HousingTabScreenScreen from "./HousingTabScreen";
import AccessTabScreenScreen from "./AccessTabScreen";
import HomeStackScreen from "./HomeStackScreen";
import TabBar from "../components/TabBar";

const Tab = createBottomTabNavigator();

const LoggedInTabNavigator = () => {
  const theme = useTheme();

  return (
    <Tab.Navigator
      screenOptions={{
        headerTitleAlign: "center",
        tabBarShowLabel: false,
      }}
      initialRouteName="HomeStack"
      tabBar={props => <TabBar {...props} />}
    >
      <Tab.Screen name="HomeStack" component={HomeStackScreen}
        options={{
          headerShown: false,
          tabBarStyle: { backgroundColor: theme.colors.secondary },
          tabBarIcon: () => <HomeIcon />
        }}
      />
      <Tab.Screen name="HousingStack" component={HousingTabScreenScreen}
        options={{
          headerShown: false,
          unmountOnBlur:true,
          tabBarStyle: { backgroundColor: theme.colors.secondary },
          tabBarIcon: () => <HouseIcon />
        }}
      />
      {/*<Tab.Screen name="BookingStack" component={BookingTabScreenScreen}
      options={{
        headerShown: false,
        tabBarStyle: { backgroundColor: theme.colors.secondary },
        tabBarIcon: () => <BookingIcon />
      }}
    />*/}
      <Tab.Screen name="AccessStack" component={AccessTabScreenScreen}
        options={{
          headerShown: false,
          unmountOnBlur:true,
          tabBarStyle: { backgroundColor: theme.colors.secondary },
          tabBarIcon: () => <KeyIcon />
        }}
      />
    </Tab.Navigator>
  );
};

export default LoggedInTabNavigator;