import React, { useRef } from "react";
import { observer } from "mobx-react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { NavigationContainer } from "@react-navigation/native";
import { LoginStackScreen } from "./LoginStackScreen";
import { mobUser } from "../mobx/MobUser";
import LoggedInTabNavigator from "./LoggedInTabNavigator";
import Chat from "../pages/Home/Chat";
import Queries from "../api/queries";
import { mobAlert } from "../mobx/MobAlert";
import i18n from "../i18n";
import { mobNotif } from "../mobx/MobNotifs";
import { useTheme } from "react-native-paper";

const Stack = createNativeStackNavigator();

const Navigation = observer(() => {
  const theme = useTheme();
  const queries = new Queries();
  const navigationRef = useRef();

  const checkInAlert = async () => {
    const resa = await queries.getResaInfo(mobUser.OiqiaBookingId);
    let containsStatus10 = false;
    let containsStatus20 = false;

    resa.bookingStep.forEach(e => {
      if (e.status == 10) containsStatus10 = true;
      if (e.status == 20) containsStatus20 = true;
    });

    if (containsStatus10 && !containsStatus20) {
      mobAlert.setAlert(
        i18n.t("alert.CHECKIN_TITLE"),
        i18n.t("alert.CHECKIN_DESCRIPTION"),
        i18n.t("alert.CHECKIN_BUTTON"),
        () => {
          navigationRef?.current?.navigate("CheckIn");
          mobAlert.closeAlert();
        }
      );
    }
  };

  const getCurrentRouteName = (state) => {
    if (!state?.routes) return null;
    const route = state?.routes[state?.index];
    // Ceci est récursif si plus de niveaux sont imbriqués
    if (route?.state) {
      return getCurrentRouteName(route.state);
    } else {
      return route?.name;
    }
  };

  return (
    <NavigationContainer
      ref={navigationRef}
      onStateChange={async (state) => {
        if (mobUser.UserLogged) {
          const currentRouteName = getCurrentRouteName(state) || "";
          if (!["CheckIn", "Invoice"].includes(currentRouteName)) {
            checkInAlert();
          }
          if (currentRouteName == "Main") {
            mobNotif.subscribeToUnreadNumber();
          }
        }
      }
      }>
      <Stack.Navigator
        screenOptions={{
          statusBarColor: theme.colors.primary,
          navigationBarHidden: true,
          contentStyle: {
            backgroundColor: theme.colors.background
          }
        }}
      >
        {mobUser.UserLogged ?
          <>
            <Stack.Screen
              name="RootStack"
              component={LoggedInTabNavigator}
              options={{
                headerShown: false,
                animation: "fade",
              }} />
            <Stack.Screen
              name={"Chat"}
              component={Chat}
              options={{
                headerShown: false,
              }} />
          </>
          :
          <Stack.Screen name="LoginStack" component={LoginStackScreen}
            options={{
              headerShown: false,
              animation: "fade",
            }}
          />
        }
      </Stack.Navigator>
    </NavigationContainer >
  );
}
);

export default Navigation;
