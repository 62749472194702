/*eslint max-len: ["error", { "code": 300 }]*/
import { mobUser } from "../../mobx/MobUser";
import GQLClient from "../../GQLClient";

import {
  Payload,
  MutationIssueAccessCodeArgs,
  Result,
  ChatIncident,
  CheckOutInput,
  Message,
  CheckInInputType,
  InvoiceData,
  Guestbook,
} from "../../types/types";

import {
  ALERT_CUSTOMER_SERVICE,
  ASK_CHAT_BOT,
  ISSUE_ACCESS_CODE,
  OPEN_INTRATONE_DOOR,
  CHECKIN_NOW,
  OPEN_DOOR,
  CLOSE_DOOR,
  UPLOAD_IMAGE,
  SET_INCIDENT_MSG,
  ADD_CHECKOUT,
  PUSH_TOKEN_NOTIF,
  SEND_MESSAGE_MUTATION,
  SET_CHECKIN_MUTATION,
  UNLOCK_IGLOO_DOOR,
  LOCK_IGLOO_DOOR,
  ASK_INVOICE_MUTATION,
  ADD_GUESTBOOK_ENTRY,
  AGREE_TERMS_MUTATION,
} from "./index.definitions";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";

export type ChatResponse = { askChatBot: string | null };
export type ChatVars = {
  askChatBotInput: { text: string; bookingId: string; languageCode: string };
};

export default class Mutations {
  private clientPromise: Promise<ApolloClient<NormalizedCacheObject>>;

  constructor() {
    this.clientPromise = GQLClient.getInstance().then((client) =>
      client.getApolloClientInstance()
    );
  }

  async askChatBot(text: string, bookingId: string) {
    const client = await this.clientPromise;

    return client.mutate<ChatResponse, ChatVars>({
      mutation: ASK_CHAT_BOT,
      variables: {
        askChatBotInput: {
          text,
          bookingId,
          languageCode: mobUser.Language,
        },
      },
    });
  }

  async issueAccessCode(bookingId: string) {
    const client = await this.clientPromise;

    return client.mutate<
      { issueAccessCode: Payload },
      MutationIssueAccessCodeArgs
    >({
      mutation: ISSUE_ACCESS_CODE,
      variables: {
        bookingId,
      },
    });
  }

  async openIntratoneDoor(bookingId: string, appartementDoorId: string) {
    const client = await this.clientPromise;

    return client.mutate<
      { openIntratone: Payload },
      { appartementDoorId: string; bookingId: string }
    >({
      mutation: OPEN_INTRATONE_DOOR,
      variables: {
        bookingId,
        appartementDoorId,
      },
    });
  }

  async alertCustomerService(bookingId: string) {
    const client = await this.clientPromise;

    return client.mutate<
      { alertCustomerService: boolean },
      MutationIssueAccessCodeArgs
    >({
      mutation: ALERT_CUSTOMER_SERVICE,
      variables: {
        bookingId,
      },
    });
  }

  async lockIglooDoor(bookingId: string, doorDeviceId: string) {
    const client = await this.clientPromise;

    return client.mutate<
      { lockIglooDoor: Payload },
      { bookingId: string; doorDeviceId: string }
    >({
      mutation: LOCK_IGLOO_DOOR,
      variables: {
        bookingId: bookingId,
        doorDeviceId: `${doorDeviceId}`,
      },
    });
  }

  async unlockIglooDoor(bookingId: string, doorDeviceId: string) {
    const client = await this.clientPromise;

    return client.mutate<
      { unlockIglooDoor: Payload },
      { bookingId: string; doorDeviceId: string }
    >({
      mutation: UNLOCK_IGLOO_DOOR,
      variables: {
        bookingId: bookingId,
        doorDeviceId: `${doorDeviceId}`,
      },
    });
  }

  //update checkin data

  async checkInNow(
    bookingId: string,
    firstName: string,
    lastName: string,
    email: string,
    phone: number,
    adults: number,
    children: number,
    isSatisfied: boolean
  ) {
    const data = {
      bookingId: bookingId,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      adults: adults,
      children: children,
      isSatisfied: isSatisfied,
    };
    //console.log("+,,,++++++++++inside mutation++++++",data);
    const client = await this.clientPromise;

    return client.mutate<{ checkInNow: Result }>({
      mutation: CHECKIN_NOW,
      variables: data,
    });
  }

  async addCheckOut(formValue: CheckOutInput) {
    const client = await this.clientPromise;

    return client.mutate<{ addCheckOut: Result }>({
      mutation: ADD_CHECKOUT,
      variables: { formValue },
    });
  }

  //open door
  async openDoor(bookingId: string, doorDeviceId: string) {
    //  console.log("open door......",bookingId,doorDeviceId)
    const client = await this.clientPromise;

    return client.mutate<
      { openDoor: Payload },
      { doorDeviceId: string; bookingId: string }
    >({
      mutation: OPEN_DOOR,
      variables: {
        bookingId,
        doorDeviceId,
      },
    });
  }

  async closeDoor(bookingId: string, doorDeviceId: string) {
    //  console.log("close door....",bookingId,doorDeviceId)
    const client = await this.clientPromise;

    return client.mutate<
      { openDoor: Payload },
      { doorDeviceId: string; bookingId: string }
    >({
      mutation: CLOSE_DOOR,
      variables: {
        bookingId,
        doorDeviceId,
      },
    });
  }

  //image
  async uploadComplaintImage(bookingId: string, image: string) {
    const client = await this.clientPromise;

    return client.mutate<{ uploadComplaintImage: string }>({
      mutation: UPLOAD_IMAGE,
      variables: {
        bookingId,
        image,
      },
    });
  }

  // Chat meassage incident

  async setIncidentMsg(ChatIncident: ChatIncident) {
    const client = await this.clientPromise;

    return client.mutate<{ setIncidentMsg: Result }>({
      mutation: SET_INCIDENT_MSG,
      variables: { ChatIncident },
    });
  }

  async pushTokenNotif(token: string, bookingId: string, lang: string) {
    const client = await this.clientPromise;

    return client.mutate<{ pushTokenNotif: Result }>({
      mutation: PUSH_TOKEN_NOTIF,
      variables: {
        token,
        bookingId,
        lang,
      },
    });
  }

  async sendMessage(message: Message, conversationId: string) {
    const client = await this.clientPromise;

    return client.mutate({
      mutation: SEND_MESSAGE_MUTATION,
      variables: {
        message,
        conversationId,
      },
    });
  }

  async setCheckIn(checkInData: CheckInInputType): Promise<any> {
    const client = await this.clientPromise;

    return client.mutate({
      mutation: SET_CHECKIN_MUTATION,
      variables: {
        data: checkInData,
      },
    });
  }

  async askInvoice(invoiceData: InvoiceData): Promise<any> {
    const client = await this.clientPromise;

    return client.mutate({
      mutation: ASK_INVOICE_MUTATION,
      variables: {
        data: invoiceData,
      },
    });
  }

  async addGuestbookEntry(data: Guestbook) {
    const client = await this.clientPromise;

    return client.mutate({
      mutation: ADD_GUESTBOOK_ENTRY,
      variables: {
        data,
      },
    });
  }

  async agreeTerms(bookingId: string, email: string) {
    const client = await this.clientPromise;

    return client.mutate({
      mutation: AGREE_TERMS_MUTATION,
      variables: {
        bookingId,
        email,
      },
    });
  }
}
