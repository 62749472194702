import React, { useEffect, useRef, useState } from "react";
import { View, ScrollView, TouchableOpacity, StyleSheet } from "react-native";
import { useTheme } from "react-native-paper";
import { ArrowIcon } from "../../../components/Icons";
import i18n from "../../../i18n";
import { useNavigation } from "@react-navigation/native";
import { mobUser } from "../../../mobx/MobUser";
import { Text } from "../../../utils/fontsLoader";
import { observer } from "mobx-react";
import LinkText from "../../../components/LinkText";
import ChatButton from "./ChatButton";
import { mobNotif } from "../../../mobx/MobNotifs";

const Assistance = observer(() => {
  const theme = useTheme();
  const styles = stylesGen(theme);
  const navigation = useNavigation();
  const Scrollref = useRef();

  const questions = [
    {
      title: "Q&A.linens_and_towels_provided.question",
      answer: "Q&A.linens_and_towels_provided.answer"
    },
    {
      title: "Q&A.consumables_provided.question",
      answer: "Q&A.consumables_provided.answer"
    },
    {
      title: "Q&A.multiple_users_app.question",
      answer: "Q&A.multiple_users_app.answer"
    },
    {
      title: "Q&A.late_arrival.question",
      answer: "Q&A.late_arrival.answer"
    },
    {
      title: "Q&A.parking_info.question",
      answer: "Q&A.parking_info.answer"
    },
    {
      title: "Q&A.extend_reservation.question",
      answer: "Q&A.extend_reservation.answer"
    },
    {
      title: "Q&A.access_changes_on_extension.question",
      answer: "Q&A.access_changes_on_extension.answer"
    },
    {
      title: "Q&A.pets_policy.question",
      answer: "Q&A.pets_policy.answer"
    },
    {
      title: "Q&A.invoice_request.question",
      answer: "Q&A.invoice_request.answer"
    },
    {
      title: "Q&A.are_we_a_hotel.question",
      answer: "Q&A.are_we_a_hotel.answer"
    },
    {
      title: "Q&A.contact_support.question",
      answer: mobUser.isConversationEngaged ? "pages.assistance.chat" : "pages.assistance.contact_us"
    },
  ];

  const [selectedId, setSelectedId] = useState(mobUser.isConversationEngaged || mobNotif.badge ? questions.length - 1 : null);

  const toggleAnswer = index => {
    if (index > questions.length / 2) setTimeout(() => Scrollref?.current?.scrollToEnd({ animated: true }), 300);
    if (selectedId === index) {
      setSelectedId(null);
    } else {
      setSelectedId(index);
    }
  };

  useEffect(() => {
    if (mobUser.isConversationEngaged || mobNotif.badge) setTimeout(() => Scrollref?.current?.scrollToEnd({ animated: true }), 300);
  }, []);

  const handleContactPress = () => {
    navigation.navigate("Chat");
  };

  return (
    <ScrollView ref={Scrollref} style={styles.container}>
      {/*mobUser.isConversationEngaged || mobNotif.badge ?
        <View style={{ top: -25 }}>
          <ChatButton color={theme.colors.primary} children={i18n.t("pages.assistance.chat")} extraStyle={styles.button} onPress={handleContactPress} />
        </View>
  : null*/}
      {questions.map((item, index) => (
        <React.Fragment key={index}>
          <TouchableOpacity style={styles.question} onPress={() => toggleAnswer(index)}>
            <Text style={styles.questionText}>{i18n.t(item.title)}</Text>
            <ArrowIcon color={theme.colors.primary} style={{ transform: selectedId === index ? [{ rotate: "90deg" }] : [{ rotate: "270deg" }] }} />
          </TouchableOpacity>
          {selectedId === index && (
            index === questions.length - 1 ?
              <View style={{ flex: 1, margin: 20 }}>
                <ChatButton color={theme.colors.primary} children={i18n.t(item.answer)} extraStyle={styles.button} onPress={handleContactPress} />
              </View>
              : (
                <View style={styles.answer}>
                  <Text style={styles.answer}>
                    <LinkText style={styles.answer}>{i18n.t(item.answer)}</LinkText>
                  </Text>
                </View>
              )
          )}
          {index < questions.length - 1 && <View style={[styles.separator, { backgroundColor: theme.colors.primary }]} />}
        </React.Fragment>
      ))}
    </ScrollView>
  );
});

const stylesGen = (_theme) => StyleSheet.create({
  container: {
    marginVertical: 20,
  },
  question: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  questionText: {
    flex: 1,
    fontSize: 18,
    fontFamily: "Gilroy-Regular",
    marginLeft: 50,
    marginRight: 10,
    textAlign: "center"
  },
  answer: {
    fontFamily: "Gilroy-SemiBold",
    fontSize: 17,
    marginHorizontal: 20,
    marginVertical: 10,
    alignSelf: "center",
    textAlign: "center",
  },
  separator: {
    height: 1,
    width: "70%",
    alignSelf: "center",
  },
  button: {
    marginTop: 50,
    width: "60%",
    alignSelf: "center",
  },
});

export default Assistance;
