import { useFonts } from "expo-font";
import React from "react";
import { StyleSheet, Text as NativeText } from "react-native";
import { MD3Theme, useTheme } from "react-native-paper";

export const useCustomFonts = () => {
  return useFonts({
    "Gilroy-Black": require("../../assets/fonts/Gilroy-Black.ttf"),
    "Gilroy-BlackItalic": require("../../assets/fonts/Gilroy-BlackItalic.ttf"),
    "Gilroy-Bold": require("../../assets/fonts/Gilroy-Bold.ttf"),
    "Gilroy-BoldItalic": require("../../assets/fonts/Gilroy-BoldItalic.ttf"),
    "Gilroy-ExtraBold": require("../../assets/fonts/Gilroy-ExtraBold.ttf"),
    "Gilroy-ExtraBoldItalic": require("../../assets/fonts/Gilroy-ExtraBoldItalic.ttf"),
    "Gilroy-Heavy": require("../../assets/fonts/Gilroy-Heavy.ttf"),
    "Gilroy-HeavyItalic": require("../../assets/fonts/Gilroy-HeavyItalic.ttf"),
    "Gilroy-Light": require("../../assets/fonts/Gilroy-Light.ttf"),
    "Gilroy-LightItalic": require("../../assets/fonts/Gilroy-LightItalic.ttf"),
    "Gilroy-Medium": require("../../assets/fonts/Gilroy-Medium.ttf"),
    "Gilroy-MediumItalic": require("../../assets/fonts/Gilroy-MediumItalic.ttf"),
    "Gilroy-Regular": require("../../assets/fonts/Gilroy-Regular.ttf"),
    "Gilroy-RegularItalic": require("../../assets/fonts/Gilroy-RegularItalic.ttf"),
    "Gilroy-SemiBold": require("../../assets/fonts/Gilroy-SemiBold.ttf"),
    "Gilroy-SemiBoldItalic": require("../../assets/fonts/Gilroy-SemiBoldItalic.ttf"),
    "Gilroy-Thin": require("../../assets/fonts/Gilroy-Thin.ttf"),
    "Gilroy-ThinItalic": require("../../assets/fonts/Gilroy-ThinItalic.ttf"),
    "Gilroy-UltraLight": require("../../assets/fonts/Gilroy-UltraLight.ttf"),
    "Gilroy-UltraLightItalic": require("../../assets/fonts/Gilroy-UltraLightItalic.ttf"),
  });
};

export const Text = ({ style = null, ...props }) => {
  const theme = useTheme();
  const styles = stylesGen(theme);
  return <NativeText style={[styles.customStyle, style]} {...props}>{props.children}</NativeText>;
};

const stylesGen = (theme: MD3Theme) => StyleSheet.create({
  customStyle: {
    fontFamily: "Gilroy-Regular",
    color: theme.colors.onBackground,
  },
});

export function capitalizeFirstLetter(string: string) {
  if (!string) return string; // Retourne la chaîne originale si elle est vide
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function adjustText(text: string, maxWidth: number, fontSize: number) {
  // Hypothèse simpliste : 1 caractère = (fontSize * 0.5) pixels de largeur.
  // Cette hypothèse peut ne pas être précise pour toutes les polices et tous les caractères.
  const maxCharsPerLine = Math.floor(maxWidth / (fontSize * 0.5));
  let adjustedText = "";
  let currentLineLength = 0;

  text.split(" ").forEach(word => {
    // Si l'ajout du mot actuel dépasse la longueur maximale de la ligne,
    // ajoutez un saut de ligne avant ce mot (sauf pour le premier mot).
    if (currentLineLength + word.length > maxCharsPerLine && currentLineLength !== 0) {
      adjustedText += "\n";
      currentLineLength = 0;
    }

    // Ajoutez le mot à la ligne actuelle.
    adjustedText += (currentLineLength > 0 ? " " : "") + word;
    currentLineLength += word.length + (currentLineLength > 0 ? 1 : 0); // Ajoutez 1 pour l'espace si ce n'est pas le premier mot.
  });

  return adjustedText;
}