import React from "react";
import { Provider, observer } from "mobx-react";
import store from "./src/mobx";
import { ApolloProvider } from "@apollo/client";
import GQLClient from "./src/GQLClient";
import { PaperProvider } from "react-native-paper";
import Navigation from "./src/navigation/Navigation";
import CustomStatusBar from "./src/components/CustomStatusBar";
import { View } from "react-native";
import { useCustomFonts } from "./src/utils/fontsLoader";
import OnlyOneModal from "./src/components/OiqiaModal/OnlyOneModal";

let client = null;

GQLClient.getInstance().then(gqlClient => {
  client = gqlClient.getApolloClientInstance();
  // Utilisez apolloClient ici
});

const App = observer(() => {

  const [fontsLoaded] = useCustomFonts();

  if (!fontsLoaded) {
    return null;
  }

  const theme = {
    myOwnProperty: true,
    colors: {
      primary: "#E00078",
      secondary: "#FFF0FA",
      success: "#1AB84D",
      background: "white",
      onBackground: "#12100B",
      error: "#E00078"
    },
  };

  return (
    <ApolloProvider client={client}>
      <PaperProvider theme={theme}>
        <Provider store={store}>
          <View style={{ flex: 1 }}>
            <CustomStatusBar />
            <Navigation />
            <OnlyOneModal/>
          </View>
        </Provider>
      </PaperProvider>
    </ApolloProvider>
  );
});

export default App;
