import React, { useEffect } from "react";
import { View, StyleSheet } from "react-native";
import { Controller, FormProvider, useForm } from "react-hook-form";
import Mutations from "../../../api/mutations";
import { mobUser } from "../../../mobx/MobUser";
import i18n from "../../../i18n";
import FieldErrorText from "../../../components/FieldErrortext";
import AppInput from "../../../components/AppInput";
import AppButton from "../../../components/AppButton";
import { useTheme } from "react-native-paper";
import { Text } from "../../../utils/fontsLoader";
import { useNavigation } from "@react-navigation/native";
import { mobAlert } from "../../../mobx/MobAlert";

const InvoiceForm = ({ checkInInfos }) => {
  const navigation = useNavigation();
  const theme = useTheme();
  const mutations = new Mutations();

  const formMethods = useForm({
    defaultValues: {
      email: checkInInfos?.email || mobUser.Email || "",
      companyName: "",
    },
  });

  const {
    control,
    reset,
    getValues,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  useEffect(() => {
    // Mise à jour des valeurs par défaut du formulaire avec les nouvelles données de checkInInfos
    reset({
      ...getValues(),
      email: checkInInfos?.email || mobUser.Email || "",
    });
  }, [checkInInfos, reset, getValues]);

  const onSubmit = (data) => {
    const InvoiceData = {
      ...data,
      bookingId: mobUser.OiqiaBookingId,
      firstName: checkInInfos?.firstName,
      lastName: checkInInfos?.lastName
    };
    console.log("InvoiceData :", InvoiceData);
    mutations.askInvoice(InvoiceData).then(() => {
      mobUser.setInvoiceAsked(true);
      mobAlert.setSuccess(
        i18n.t("pages.invoice.successTitle"),
        i18n.t("pages.invoice.successMessage"),
        () => {
          mobAlert.closeSuccess();
          navigation.goBack();
        }
      );
    });
  };

  return (
    <FormProvider {...formMethods}>
      <Controller
        control={control}
        rules={{
          required: i18n.t("errors.missingField"),
          pattern: {
            value: /^[\w.%+-]+@[a-z0-9-]+\.[a-z]{2,}$/i,
            message: i18n.t("errors.emailFormatError")
          }
        }}
        render={({ field: { onChange, value } }) => (
          <View style={styles.field}>
            <Text style={styles.label}>
              {i18n.t("pages.checkIn.email")}
            </Text>
            <AppInput
              value={value}
              onChangeText={onChange}
              additionalStyle={styles.inputText}
              autoCapitalize="none"
              keyboardType="email-address"
            />
            {errors.email && <FieldErrorText errorMessage={errors.email.message} />}
          </View>
        )}
        name="email"
      />
      <Controller
        control={control}
        rules={{
          required: false,
        }}
        render={({ field: { onChange, value } }) => (
          <View style={styles.field}>
            <Text style={styles.label}>
              {i18n.t("pages.invoice.companyName")}
            </Text>
            <AppInput
              value={value}
              onChangeText={onChange}
              additionalStyle={styles.inputText}
            />
          </View>
        )}
        name="companyName"
      />
      {!mobUser.isInvoiceAsked &&
        <AppButton extraStyle={styles.submitButton} color={theme.colors.primary} onPress={handleSubmit(onSubmit)}>
          {i18n.t("pages.invoice.submit")}
        </AppButton>
      }
    </FormProvider>
  );
};

const styles = StyleSheet.create({
  field: {
    gap: 10
  },
  label: {
    fontSize: 20,
    textAlign: "center"
  },
  inputText: {
    fontSize: 20,
    fontFamily: "Gilroy-Light",
  },
  submitButton: {
    marginTop: 20
  },
});

export default InvoiceForm;