import React from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { Text } from "../utils/fontsLoader";
import { MD3Theme, useTheme } from "react-native-paper";
interface AppButtonProps {
  color?: string
  children: any
  onPress: any
  extraStyle?: object
  extraTextStyle?: object
  disabled?: boolean
}

export default function AppButton({ color=null, children, onPress, extraStyle, extraTextStyle, disabled }: AppButtonProps) {
  const theme = useTheme();
  const styles = styleGen(theme);

  return (
    <TouchableOpacity disabled={disabled} onPress={onPress} style={{...styles.button, backgroundColor: color || theme.colors.primary, ...extraStyle}}>
      <Text style={{ ...styles.text, ...extraTextStyle}}>
        {children}
      </Text>
    </TouchableOpacity>
  );
}

const styleGen = (theme: MD3Theme) => StyleSheet.create({
  button: {
    borderRadius: 15,
    width: "100%",
    paddingVertical:3,
    paddingRight: 10,
    paddingLeft: 10
  },
  text: {
    fontSize: 23,
    fontFamily: "Gilroy-Medium",
    textAlign: "center",
    lineHeight: 33,
    color:theme.colors.background
  }
});
