import React from "react";
import { Dimensions, StyleSheet, TouchableHighlight, View } from "react-native";
import { IconType } from "../pages/Home";
import { useNavigation } from "@react-navigation/native";
import { Badge, MD3Theme, useTheme } from "react-native-paper";
import { Text, adjustText } from "../utils/fontsLoader";
import i18n from "../i18n";
import { mobNotif } from "../mobx/MobNotifs";
import { observer } from "mobx-react";
interface HomePageButtonProps {
  component: IconType,
  extraStyle?: { iconNormal: object, iconPress: object }
}

const HomePageButton = observer(({ component, extraStyle }: HomePageButtonProps) => {
  const textSize = Dimensions.get("screen").width*0.025;
  const navigation = useNavigation();
  const theme = useTheme();
  const styles = stylesGen(theme,textSize, extraStyle);
  const [isPress, setIsPress] = React.useState(false);
  const touchProps = {
    underlayColor: theme.colors.primary, // <-- "backgroundColor" will be always overwritten by "underlayColor"
    style: isPress ? styles.iconPress : styles.iconNormal, // <-- but you can still apply other style changes
    onHideUnderlay: () => setIsPress(false),
    onShowUnderlay: () => setIsPress(true)
  };

  return (
    <View style={styles.iconContainer}>
      {component.title === i18n.t("home.assistance") && mobNotif.badge ?
        <Badge style={styles.badge}>{mobNotif.badge}</Badge> : null
      }
      <TouchableHighlight
        onPress={() => {
          component.onPress ? component.onPress() : navigation.navigate(component.page);
        }}
        {...touchProps}
      >
        {component.icon}
      </TouchableHighlight>
      <Text style={styles.text}>
        {adjustText(component.title, textSize*7.5, textSize)}
      </Text>
    </View>
  );
});

const stylesGen = (theme: MD3Theme,textSize, extraStyle: { iconNormal: any; iconPress: any; }) => StyleSheet.create({
  badge: {
    position: "absolute",
    top: -5,
    right: -5,
    zIndex: 1,
    fontFamily: "Gilroy-Bold",
    color: theme.colors.background,
  },
  iconContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  iconNormal: {
    backgroundColor: theme.colors.secondary,
    borderRadius: 20,
    ...(extraStyle && extraStyle.iconNormal)
  },
  iconPress: {
    backgroundColor: theme.colors.secondary,
    borderRadius: 20,
    ...(extraStyle && extraStyle.iconPress)
  },
  text: {
    marginTop: 7,
    textAlign: "center",
    fontSize: textSize,
    fontFamily: "Gilroy-Medium"
  }
});

export default HomePageButton;