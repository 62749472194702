/*eslint max-len: ["error", { "code": 300 }]*/
import { gql } from "@apollo/client";

export const ISSUE_ACCESS_CODE = gql`
  mutation GetAccessCode($bookingId: String!) {
    issueAccessCode(bookingId: $bookingId) {
      message
      code
      success
      ressourceId
    }
  }
`;

export const OPEN_INTRATONE_DOOR = gql`
  mutation openIntratoneDoor($bookingId: String!, $appartementDoorId: String!) {
    openIntratoneDoor(
      bookingId: $bookingId
      appartementDoorId: $appartementDoorId
    ) {
      success
    }
  }
`;

export const ASK_CHAT_BOT = gql`
  mutation AskChatBot($askChatBotInput: AskChatBotInput!) {
    askChatBot(askChatBotInput: $askChatBotInput)
  }
`;

export const ALERT_CUSTOMER_SERVICE = gql`
  mutation AlertCustomerService($bookingId: String!) {
    alertCustomerService(bookingId: $bookingId)
  }
`;

export const LOCK_IGLOO_DOOR = gql`
  mutation LockIglooDoor($bookingId: String!, $doorDeviceId: String!) {
    lockIglooDoor(bookingId: $bookingId, doorDeviceId: $doorDeviceId) {
      success
    }
  }
`;

export const UNLOCK_IGLOO_DOOR = gql`
  mutation UnlockIglooDoor($bookingId: String!, $doorDeviceId: String!) {
    unlockIglooDoor(bookingId: $bookingId, doorDeviceId: $doorDeviceId) {
      success
    }
  }
`;

export const CHECKIN_NOW = gql`
  mutation checkInNow(
    $bookingId: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String!
    $adults: Float!
    $children: Float!
    $isSatisfied: Boolean!
  ) {
    checkInNow(
      bookingId: $bookingId
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      adults: $adults
      children: $children
      isSatisfied: $isSatisfied
    ) {
      result
    }
  }
`;

export const ADD_CHECKOUT = gql`
  mutation NTA_addCheckOut($formValue: CheckOutInput!) {
    NTA_addCheckOut(formValue: $formValue) {
      result
    }
  }
`;

//register complaints
export const REGISTER_COMPLAINT = gql`
  mutation registerProblem(
    $bookingId: String!
    $problem: String!
    $image: [String!]!
  ) {
    registerProblem(bookingId: $bookingId, problem: $problem, image: $image)
  }
`;

export const OPEN_DOOR = gql`
  mutation openDoor($bookingId: String!, $doorDeviceId: String!) {
    openDoor(bookingId: $bookingId, doorDeviceId: $doorDeviceId) {
      success
    }
  }
`;

export const CLOSE_DOOR = gql`
  mutation closeDoor($bookingId: String!, $doorDeviceId: String!) {
    closeDoor(bookingId: $bookingId, doorDeviceId: $doorDeviceId) {
      success
    }
  }
`;

export const UPLOAD_IMAGE = gql`
  mutation uploadComplaintImage($bookingId: String!, $image: String!) {
    uploadComplaintImage(bookingId: $bookingId, image: $image)
  }
`;

export const SET_INCIDENT_MSG = gql`
  mutation setIncidentMsg($ChatIncident: ChatIncident!) {
    setIncidentMsg(ChatIncident: $ChatIncident) {
      result
    }
  }
`;

export const PUSH_TOKEN_NOTIF = gql`
  mutation pushTokenNotif($token: String!, $bookingId: String, $lang: String!) {
    pushTokenNotif(token: $token, bookingId: $bookingId, lang: $lang) {
      result
    }
  }
`;

export const SEND_MESSAGE_MUTATION = gql`
  mutation SendMessage($message: Message!, $conversationId: String!) {
    sendMessage(message: $message, conversationId: $conversationId) {
      _id
      text
      image
      createdAt
      user {
        _id
        name
        avatar
      }
      conversationId
    }
  }
`;

export const SET_CHECKIN_MUTATION = gql`
  mutation NTA_setCheckIn($data: CheckInInput!) {
    NTA_setCheckIn(data: $data) {
      result
    }
  }
`;

export const ASK_INVOICE_MUTATION = gql`
  mutation askInvoice($data: InvoiceData!) {
    askInvoice(data: $data) {
      result
    }
  }
`;

export const ADD_GUESTBOOK_ENTRY = gql`
  mutation AddGuestbookEntry($data: GuestbookInput!) {
    addGuestbookEntry(data: $data) {
      result
    }
  }
`;

export const AGREE_TERMS_MUTATION = gql`
  mutation AgreeTerms($bookingId: String!, $email: String!) {
    agreeTerms(bookingId: $bookingId, email: $email)
  }
`;