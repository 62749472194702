import { runOnUI } from "react-native-reanimated";

export interface Point {
  x: number;
  y: number;
}

export interface Size {
  width: number;
  height: number;
}

const defaultAnchorPoint = { x: 0.5, y: 0.5 };

export const withAnchorPoint = (
  transform,
  anchorPointX,
  anchorPointY,
  sizeWidth,
  sizeHeight
) => {
  "worklet";

  const injectedTransform = transform.transform;

  const shiftX = sizeWidth * (anchorPointX - defaultAnchorPoint.x);
  const shiftY = sizeHeight * (anchorPointY - defaultAnchorPoint.y);

  if (anchorPointX !== defaultAnchorPoint.x) {
    // Shift before and after rotation in X
    injectedTransform.unshift({ translateX: shiftX });
    injectedTransform.push({ translateX: -shiftX });
  }

  if (anchorPointY !== defaultAnchorPoint.y) {
    // Shift before and after rotation in Y
    injectedTransform.unshift({ translateY: shiftY });
    injectedTransform.push({ translateY: -shiftY });
  }

  return { transform: injectedTransform };
};

export const withAnchorPointOnUI = (transform, anchorPointX, anchorPointY, anchorPoint, size) => {
  runOnUI(withAnchorPoint)(transform, anchorPointX, anchorPointY, anchorPoint, size);
};