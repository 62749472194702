import React from "react";
import { StyleSheet, View } from "react-native";
import Skeleton from "../../../components/Skeleton";

export default function WifiCardSkeleton() {
  return (
    <>
      <View style={styles.skeleton}>
        <Skeleton/>
      </View>
      <View style={styles.skeleton}>
        <Skeleton/>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  skeleton : {
    width: "100%",
    height: 50
  }
});
