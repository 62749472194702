import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import GQLClient from "../../GQLClient";
import {
  Booking,
  CheckInData,
  BookingOiqiaQl,
  PropertyLock,
  AppartDoors,
  QueryGetBookingByIdAndEmailArgs,
  QueryGetBookingByIdArgs,
  QueryGetCheckInArgs,
  QueryGetOiqiaSmartLockInfoArgs,
  QueryGetNukiSmartLockInfoArgs,
  QueryGetAppartementDoorsArgs,
  CheckOutData,
  QueryGetCheckOutArgs,
  BookingStepResult,
  QueryGetBookingStatusArgs,
  OwnerData,
  QueryGetOwnerDataArgs,
  AppartmentCaractInfo,
  QueryGetAppartmentCaractArgs,
  QueryGetAttachmentInfoArgs,
  ApartmentAttachmentInfo,
  WifiInfos,
  BookingLatLng,
  QueryGetLatLngByIdArgs,
  PictureData,
  ManualsData,
  BookingIdOiqiaQl,
  RulesData,
  WelcomeData,
  AppartDoorsWithPictures,
  QueryGetAppartementDoorsWithPicturesArgs,
  ParkingData,
  Guestbook,
  TermsAgreement,
  Result,
} from "../../types/types";

import {
  GET_BOOKING_BY_ID_AND_EMAIL,
  GET_BOOKING_BY_ID,
  GET_CHECKINDATA,
  GET_PREVIOUS_COMPLAINTS,
  GET_NUKI_INFO,
  GET_APARTMENT_DOOR_LOCKS,
  GET_OIQIA_SMARTLOCKINFO,
  GET_CHECKOUTDATA,
  GET_BOOKING_STEP,
  GET_OWNER_DATA,
  GET_APPART_CARACT,
  GET_ATTACHMENT_INFO,
  GET_APPART_WIFI,
  GET_LAT_LNG_BY_ID,
  GET_PICTURES,
  GET_MANUALS,
  GET_BOOKING_ID_BY_EXT_ID,
  GET_RULES,
  GET_WELCOME_NOTES,
  GET_APARTMENT_DOOR_LOCKS_WITH_PICTURES,
  GET_APPART_EQUIPMENTS,
  GET_PARKING,
  GET_GUESTBOOK,
  GET_LATEST_TERMS,
  COUNT_UNREAD_MESSAGE
} from "./index.definitions";

export default class Queries {
  private clientPromise: Promise<ApolloClient<NormalizedCacheObject>>;

  constructor() {
    this.clientPromise = GQLClient.getInstance().then((client) =>
      client.getApolloClientInstance()
    );
  }

  async getBookingByIdAndEmail(email: string, bookingId: string) {
    const client = await this.clientPromise;

    return client.watchQuery<
      { getBookingByIdAndEmail: Booking },
      QueryGetBookingByIdAndEmailArgs
    >({
      query: GET_BOOKING_BY_ID_AND_EMAIL,
      variables: {
        email,
        bookingId,
      },
    });
  }

  async getNukiSmartLockInfo(smartLockId: number) {
    // console.log("*****************before gaphal ql request test2");
    const client = await this.clientPromise;

    return client.watchQuery<
      { getNukiSmartLockInfo: PropertyLock },
      QueryGetNukiSmartLockInfoArgs
    >({
      query: GET_NUKI_INFO,
      variables: {
        smartLockId,
      },
    });
  }

  async getBookingStatus(bookingId: string) {
    const client = await this.clientPromise;

    return client.watchQuery<
      { getBookingStatus: BookingStepResult },
      QueryGetBookingStatusArgs
    >({
      query: GET_BOOKING_STEP,
      variables: {
        bookingId,
      },
    });
  }

  async getBookingById(bookingId: string) {
    const client = await this.clientPromise;

    return client.watchQuery<
      { NTA_getBookingById: BookingOiqiaQl },
      QueryGetBookingByIdArgs
    >({
      query: GET_BOOKING_BY_ID,
      variables: {
        bookingId,
      },
    });
  }

  async getBookingIdByExtId(bookingId: string) {
    const client = await this.clientPromise;

    return client.query<
      { getOiqiaIdByExtId: BookingIdOiqiaQl },
      QueryGetBookingByIdArgs
    >({
      query: GET_BOOKING_ID_BY_EXT_ID,
      variables: {
        bookingId,
      },
    });
  }

  async getLatLngById(bookingId: string) {
    const client = await this.clientPromise;

    return client.watchQuery<
      { NTA_getBookingById: BookingLatLng },
      QueryGetLatLngByIdArgs
    >({
      query: GET_LAT_LNG_BY_ID,
      variables: {
        bookingId,
      },
    });
  }

  async getOwnerData(bookingId: string) {
    const client = await this.clientPromise;

    return client.watchQuery<
      { getOwnerData: OwnerData },
      QueryGetOwnerDataArgs
    >({
      query: GET_OWNER_DATA,
      variables: {
        bookingId,
      },
    });
  }

  //get checkinData
  async getCheckIn(bookingId: string) {
    const client = await this.clientPromise;

    return client.watchQuery<
      { NTA_getCheckIn: CheckInData },
      QueryGetCheckInArgs
    >({
      query: GET_CHECKINDATA,
      variables: {
        bookingId,
      },
    });
  }

  //get Previous complaints
  async getPreviousComplaints(bookingId: string) {
    const client = await this.clientPromise;

    return client.watchQuery<
      { getPreviousComplaints: Booking },
      QueryGetBookingByIdArgs
    >({
      query: GET_PREVIOUS_COMPLAINTS,
      variables: {
        bookingId,
      },
    });
  }

  // OLD VERSION get apartment door locks
  async getAppartementDoors(bookingId: string) {
    const client = await this.clientPromise;

    return client.watchQuery<
      { getAppartementDoors: Array<AppartDoors> },
      QueryGetAppartementDoorsArgs
    >({
      query: GET_APARTMENT_DOOR_LOCKS,
      variables: {
        bookingId,
      },
    });
  }

  // NEW VERSION get apartment door locks
  async getAppartementDoorsWithPictures(bookingId: string) {
    const client = await this.clientPromise;

    return client.watchQuery<
      { getAppartementDoorsWithPictures: Array<AppartDoorsWithPictures> },
      QueryGetAppartementDoorsWithPicturesArgs
    >({
      query: GET_APARTMENT_DOOR_LOCKS_WITH_PICTURES,
      variables: {
        bookingId,
      },
    });
  }

  // getOiqiaSmartlockInfo
  async getOiqiaSmartLockInfo(bookingId: string, doorDeviceId: string) {
    const client = await this.clientPromise;
    return client.watchQuery<
      { getOiqiaSmartLockInfo: PropertyLock },
      QueryGetOiqiaSmartLockInfoArgs
    >({
      query: GET_OIQIA_SMARTLOCKINFO,
      variables: {
        bookingId,
        doorDeviceId,
      },
    });
  }

  // get checkout info
  async getCheckOut(bookingId: string, language: string) {
    const client = await this.clientPromise;
    return client.watchQuery<
      { NTA_getCheckOut: CheckOutData },
      QueryGetCheckOutArgs
    >({
      query: GET_CHECKOUTDATA,
      variables: {
        bookingId,
        language,
      },
    });
  }

  async getAppartmentCaract(bookingId: string) {
    const client = await this.clientPromise;
    return client.watchQuery<
      { NTA_getAppartmentCaract: AppartmentCaractInfo },
      QueryGetAppartmentCaractArgs
    >({
      query: GET_APPART_CARACT,
      variables: {
        bookingId,
      },
    });
  }

  async getAppartmentEquipments(bookingId: string) {
    const client = await this.clientPromise;
    return client.watchQuery<{ NTA_getAppartmentCaract: AppartmentCaractInfo }, QueryGetAppartmentCaractArgs>({
      query: GET_APPART_EQUIPMENTS,
      variables: {
        bookingId,
      },
    });
  }

  async getAttachmentInfos(bookingId: string) {
    const client = await this.clientPromise;
    return client.watchQuery<
      { getAttachmentInfo: ApartmentAttachmentInfo },
      QueryGetAttachmentInfoArgs
    >({
      query: GET_ATTACHMENT_INFO,
      variables: {
        bookingId,
      },
    });
  }

  async getParking(bookingId: string) {
    const client = await this.clientPromise;
    return client.watchQuery<{ getAttachmentInfo: ParkingData }, QueryGetAppartmentCaractArgs>({
      query: GET_PARKING,
      variables: {
        bookingId,
      },
    });
  }

  async getPictures(bookingId: string) {
    const client = await this.clientPromise;
    return client.watchQuery<
      { getAttachmentInfo: PictureData },
      QueryGetAttachmentInfoArgs
    >({
      query: GET_PICTURES,
      variables: {
        bookingId,
      },
    });
  }

  async getManuals(bookingId: string) {
    const client = await this.clientPromise;
    return client.watchQuery<
      { getAttachmentInfo: ManualsData },
      QueryGetAttachmentInfoArgs
    >({
      query: GET_MANUALS,
      variables: {
        bookingId,
      },
    });
  }

  async getRules(bookingId: string) {
    const client = await this.clientPromise;
    return client.watchQuery<
      { getAttachmentInfo: RulesData },
      QueryGetAttachmentInfoArgs
    >({
      query: GET_RULES,
      variables: {
        bookingId,
      },
    });
  }

  async getWelcomeNotes(bookingId: string) {
    const client = await this.clientPromise;
    return client.watchQuery<
      { getAttachmentInfo: WelcomeData },
      QueryGetAttachmentInfoArgs
    >({
      query: GET_WELCOME_NOTES,
      variables: {
        bookingId,
      },
    });
  }

  async getApartmentWifi(bookingId: string) {
    const client = await this.clientPromise;
    return client.watchQuery<{ NTA_getAppartmentCaract: WifiInfos }, QueryGetAttachmentInfoArgs>({

      query: GET_APPART_WIFI,
      variables: {
        bookingId,
      },
    });
  }

  async getGuestbook(bookingId: string) {
    const client = await this.clientPromise;
    return client.watchQuery<{ getGuestbook: Guestbook[] }, { bookingId: string }>({
      query: GET_GUESTBOOK,
      variables: {
        bookingId,
      },
    });
  }

  async getLatestTerms() {
    const client = await this.clientPromise;
    return client.watchQuery<{ getLatestTerms: TermsAgreement }>({
      query: GET_LATEST_TERMS,
    });
  }
  async countUnreadMessage(bookingId: string, lastMessageID: string) {
    const client = await this.clientPromise;
    return client.query<{ countUnreadMessage: Result }>({
      query: COUNT_UNREAD_MESSAGE,
      variables: {
        bookingId,
        lastMessageID
      },
    });
  }

  async getResaInfo(OiqiaBookingId: string) {
    const subscriptionPromise = this.getBookingById(OiqiaBookingId);

    const resultPromise = new Promise<BookingOiqiaQl>((resolve, reject) => {
      subscriptionPromise.then(subscription => {
        subscription.subscribe({
          next: (response) => {
            if (response?.data?.NTA_getBookingById) {
              resolve(response.data.NTA_getBookingById);
            }
          },
          error: (err) => reject(err),
        });
      }).catch(err => reject(err));
    });

    return await resultPromise;
  }
}
