import React from "react";
import { StyleSheet } from "react-native";
import i18n from "../../../i18n";
import { Text } from "../../../utils/fontsLoader";

const HintCard = () => {
  const styles = styleGen();

  return (
    <Text style={styles.text}>
      {i18n.t("pages.explore.hint")}
    </Text>
  );
};

const styleGen = () => StyleSheet.create({
  text: {
    fontSize: 17,
    textAlign: "center",
    fontFamily: "Gilroy-Bold",
    lineHeight: 40
  },
});
export default HintCard;