import React, { useEffect, useState } from "react";
import { Dimensions, SafeAreaView, StyleSheet, View } from "react-native";
import { MD3Theme, useTheme } from "react-native-paper";
import i18n from "../../../i18n";
import Mutations from "../../../api/mutations";
import { mobUser } from "../../../mobx/MobUser";
import { mobNotif } from "../../../mobx/MobNotifs";
import * as ImagePicker from "expo-image-picker";
import { GiftedChat, Bubble, Actions, ActionsProps, } from "react-native-gifted-chat";
import SendButton from "../../../components/SendButton";
import getTextFromMultilang from "../../../utils/getTextFromMultiLang";
import { observer } from "mobx-react";
import CustomHeader from "../../../navigation/CustomHeader";
import { mobAlert } from "../../../mobx/MobAlert";

const Chat = observer(() => {
  const theme = useTheme();
  const styles = styleGen(theme);
  const [messages, setMessages] = useState([]);
  const [isPortrait, setIsPortrait] = useState<boolean>(true);
  const mutations = new Mutations();

  useEffect(() => {
    mobNotif.setChatSub(mobUser.OiqiaBookingId);
    mobNotif.isInChat=true;
    checkIfPortrait();
    return () => {
      mobNotif.resetChat();
      mobNotif.isInChat=false;
    };
  }, []);

  useEffect(() => {
    if (mobNotif.chatData[0]?.conversation) {
      const newConversation = mobNotif.chatData[0].conversation.map(message => {
        const translatedText = message.textMulti ? getTextFromMultilang(mobUser.Language, message.textMulti) : message.text;
        return {
          ...message,
          text: translatedText
        };
      });
      setMessages(newConversation);
    }
  }, [mobNotif.chatData, mobUser.Language]);

  const sendMessage = async (newMessages) => {

    const bookingIdOiqia = mobUser.OiqiaBookingId;

    const { text, user, image } = newMessages[0];

    const NewMessage = {
      bookingIdOiqia,
      text,
      image,
      user
    };

    try {
      await mutations.setIncidentMsg(NewMessage);

    } catch (err) {
      console.log("ERROR setIncidentMsg", err);
      console.log("ERROR setIncidentMsg", err.networkError.result.errors);
    }
  };

  const USER = {
    "_id": "traveler",
    "name": "Locataire",
    "avatar": ""
  };

  const checkIfPortrait = () => {
    const dim = Dimensions.get("screen");
    setIsPortrait(dim.height >= dim.width);//Returns true if the screen is in portrait mode
  };

  Dimensions.addEventListener("change", checkIfPortrait);

  const renderActions = (props: Readonly<ActionsProps>) => {
    return (
      <Actions
        {...props}
        options={{
          ["Camera"]: takePicture,
          ["Gallery"]: pickImage,
          ["Cancel"]: () => null,
        }}
      //onSend={args => console.log("args", args)}
      />
    );
  };

  // Fonction pour envoyer une image
  const sendPicture = async (picture) => {
    try {
      const link = await mutations.uploadComplaintImage(
        mobUser.OiqiaBookingId,
        `data:image/jpeg;base64,${picture.base64}`,
      );

      const msg = {
        text: "",
        image: link.data.uploadComplaintImage,
        user: USER,
        conversationId: mobUser.conversationId
      };

      //await mutations.sendMessage(msg, mobUser.OiqiaBookingId);
      sendMessage([msg]);
    } catch (err) {
      alert(i18n.t("ERROR_CHAT_BOT_TITLE") + " " + i18n.t("ERROR_CHAT_BOT_DESCRIPTION"));
    }
  };

  // Fonction pour prendre une photo
  const takePicture = async () => {
    mobAlert.openCamera(sendPicture,isPortrait);
  };

  // Fonction pour choisir une image depuis la galerie
  const pickImage = async () => {
    const { granted } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (!granted) {
      alert(i18n.t("permissions.MSG_PERMISSION_LIBRARY"));
      return;
    }
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      base64: true,
      quality: 0.5,
    });
    if (!result.canceled) {
      sendPicture(result);
    }
  };

  const renderBubble = (props) => (
    <Bubble
      {...props}
      textStyle={{
        right: {
          color: theme.colors.background,
        },
      }}
      wrapperStyle={{
        left: {
          backgroundColor: theme.colors.secondary,
        },
        right: {
          backgroundColor: theme.colors.primary,
        }
      }}
    />
  );

  return (
    <SafeAreaView style={styles.Background}>
      <View style={styles.androidTopHeader}>
        <CustomHeader text={i18n.t("home.chat")} hasArrow={true} theme={theme}/>
      </View>
      <GiftedChat
        showUserAvatar={false}
        locale={mobUser.Language}
        renderSend={props => <SendButton {...props} />}
        scrollToBottom
        bottomOffset={20}
        placeholder={i18n.t("pages.chat.placeholder")}
        messages={messages}
        onSend={sendMessage}
        user={USER}
        renderActions={renderActions}
        //renderComposer={renderComposer}
        //renderInputToolbar={renderInputToolbar}
        renderBubble={renderBubble}
        messagesContainerStyle={styles.messagesContainerStyle}
      />
    </SafeAreaView>);
});

const styleGen = (theme: MD3Theme) => StyleSheet.create({
  Background: {
    flex: 1,
    //paddingBottom:Platform.OS=="android"?"12%":0,
    backgroundColor: theme.colors.background,
  },
  androidTopHeader:{
    //paddingTop:Platform.OS=="android"? 30:0,
    backgroundColor:"#E00078",
  },
  messagesContainerStyle: {
    backgroundColor: "#FFFFFFE6",
  },
});

export default Chat;