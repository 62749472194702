import React, { useEffect, useState } from "react";
import { View, Pressable, StyleSheet, Platform, Keyboard } from "react-native";
import { BookingIcon, HomeIcon, HouseIcon, KeyIcon } from "./Icons";
import { useTheme } from "react-native-paper";
import { BottomTabBarProps } from "@react-navigation/bottom-tabs";
import i18n from "../i18n";
import { Text } from "../utils/fontsLoader";
import { mobUser } from "../mobx/MobUser";
import { observer } from "mobx-react";

function getIconFromRouteName(name: string, color: string): JSX.Element {
  switch (name) {
  case "HomeStack":
    return <HomeIcon color={color} />;
  case "HousingStack":
    return <HouseIcon color={color} />;
  case "BookingStack":
    return <BookingIcon color={color} />;
  case "AccessStack":
    return <KeyIcon color={color} />;
  default:
    return <></>;
  }
}

function getTitleFromRouteName(name: string): string {
  switch (name) {
  case "HomeStack":
    return i18n.t("tabBar.home");
  case "HousingStack":
    return i18n.t("tabBar.housing");
  case "BookingStack":
    return i18n.t("tabBar.booking");
  case "AccessStack":
    return i18n.t("tabBar.access");
  default:
    return "";
  }
}

const TabBar = observer(({ state, navigation }: BottomTabBarProps) => {
  const [keyboardVisible, setKeyboardVisible] = useState(false);

  useEffect(() => {
    const showSubscription = Keyboard.addListener("keyboardDidShow", () => {
      setKeyboardVisible(true); // Le clavier est visible
    });
    const hideSubscription = Keyboard.addListener("keyboardDidHide", () => {
      setKeyboardVisible(false); // Le clavier est caché
    });

    // Nettoyage des abonnements
    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, []);

  const theme = useTheme();

  if (!keyboardVisible || Platform.OS != "android") return (
    <View style={{ ...styles.container, backgroundColor: theme.colors.secondary }}>
      {state.routes.map((route, index: number) => {

        const isFocused = state.index === index;

        function onPress() {
          const event = navigation.emit({
            type: "tabPress",
            target: route.key,
            canPreventDefault: true
          });

          if (!isFocused && !event.defaultPrevented) {
            navigation.navigate(route.name);
          }
        }

        return (
          <Pressable key={index + mobUser.Language} onPress={onPress} style={styles.itemContainer}>
            <View style={styles.item}>
              <View style={{ ...styles.icon, backgroundColor: isFocused ? theme.colors.primary : theme.colors.background }}>
                {getIconFromRouteName(route.name, isFocused ? theme.colors.background : theme.colors.primary)}
              </View>
              <Text style={{ color: theme.colors.primary }}>
                {getTitleFromRouteName(route.name)}
              </Text>
            </View>
          </Pressable>
        );
      })}
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    backgroundColor: "#182028",
  },
  icon: {
    width: 75,
    height: 75,
    borderRadius: 20
  },
  itemContainer: {
    flex: 1,
    marginVertical: 10,
    height: Platform.OS === "ios" ? 100 : 90
  },
  item: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 15,
    gap:4
  }
});

export default TabBar;