/*eslint max-len: ["error", { "code": 300 }]*/
import { gql } from "@apollo/client";

export const GET_BOOKINGS = gql`
  query getBookings {
    getBookings {
      accessCode

      id
      apartment {
        id
        name
        locks {
          smartLockId
          name
        }
      }
      price
      adults
      children
    }
  }
`;

export const GET_BOOKING_BY_ID_AND_EMAIL = gql`
  query GetBookingByIdAndEmail($bookingId: String!, $email: String!) {
    getBookingByIdAndEmail(bookingId: $bookingId, email: $email) {
      id
      adults
      children
      type
      checkIn
      checkOut
      arrival
      departure
      apartment {
        id
        name
        location {
          street
          zip
          city
          longitude
          latitude
        }
        locks {
          smartLockId
          state
          name
        }
      }
      email
      price
      guestName
      pricePaid
      accessCode
    }
  }
`;

export const GET_BOOKING_STEP = gql`
  query getBookingStatus($bookingId: String!) {
    getBookingStatus(bookingId: $bookingId) {
      bookingStep {
        status
        createdAt
      }
    }
  }
`;

export const GET_BOOKING_BY_ID = gql`
  query NTA_getBookingById($bookingId: String!) {
    NTA_getBookingById(bookingId: $bookingId) {
      smoobuBookingId
      firstName
      lastName
      arrival
      departure
      checkIn
      checkOut
      formatedAddress
      address {
        extra
        streetNumber
        street
        city
        zipCode
        country
      }
      lat
      lng
      timeZone
      bookingStep {
        status
        createdAt
      }
      responsability
      channelName
    }
  }
`;

export const GET_BOOKING_ID_BY_EXT_ID = gql`
  query getOiqiaIdByExtId($bookingId: String!) {
    getOiqiaIdByExtId(bookingId: $bookingId) {
      bookingIdOiqia
    }
  }
`;

export const GET_LAT_LNG_BY_ID = gql`
  query NTA_getBookingById($bookingId: String!) {
    NTA_getBookingById(bookingId: $bookingId) {
      lat
      lng
    }
  }
`;

export const GET_OWNER_DATA = gql`
  query getOwnerData($bookingId: String!) {
    getOwnerData(bookingId: $bookingId) {
      users {
        email
        firstName
        lastName
        phoneNumber
      }
    }
  }
`;

export const GET_CHECKINDATA = gql`
  query NTA_getCheckIn($bookingId: String!) {
    NTA_getCheckIn(bookingId: $bookingId) {
      ratingCleanliness
      email
      phone
      adults
      children
      arrival
      departure
      firstName
      lastName
      isCheckinDone
      isSatisfied
      problem {
        description
        image
      }
    }
  }
`;

export const GET_CHECKOUTDATA = gql`
  query NTA_getCheckOut($bookingId: String!, $language: String) {
    NTA_getCheckOut(bookingId: $bookingId, language: $language) {
      ratingAppart
      ratingCleanness
      ratingOiqia
      feedBack
      isCheckoutDone
      isSatisfied
      problem {
        description
        image
      }
      checkoutQuestion {
        text
        checked
      }
      createdAt
    }
  }
`;

export const GET_APPART_CARACT = gql`
  query NTA_getAppartmentCaract($bookingId: String!) {
    NTA_getAppartmentCaract(bookingId: $bookingId,) {
      equipments
      description {
        fr
        en
        es
        pt
        ru
        it
      }
      wifiName
      wifiCode
      extraInfo  {
        fr
        en
        es
        pt
        ru
        it
      }
    }
  }
`;

export const GET_APPART_EQUIPMENTS = gql`
  query NTA_getAppartmentCaract($bookingId: String!) {
    NTA_getAppartmentCaract(bookingId: $bookingId) {
      equipments
    }
  }
`;

export const GET_PARKING = gql`
  query getAttachmentInfo($bookingId: String!) {
    getAttachmentInfo(bookingId: $bookingId) {
      parking {
        title
        container
        name
        type
      }
    }
  }
`;

export const GET_APPART_WIFI = gql`
  query NTA_getAppartmentCaract($bookingId: String!) {
    NTA_getAppartmentCaract(bookingId: $bookingId) {
      wifiName
      wifiCode
    }
  }
`;

export const GET_NUKI_INFO = gql`
  query getNukiSmartLockInfo($smartLockId: Float!) {
    getNukiSmartLockInfo(smartLockId: $smartLockId) {
      state
      name
    }
  }
`;

export const GET_PREVIOUS_COMPLAINTS = gql`
  query getPreviousComplaints($bookingId: String!) {
    getPreviousComplaints(bookingId: $bookingId) {
      bookingId
      problem
    }
  }
`;

// OLD get apartment doors
export const GET_APARTMENT_DOOR_LOCKS = gql`
  query getAppartementDoors($bookingId: String!) {
    getAppartementDoors(bookingId: $bookingId) {
      _id
      description
      doorType
      deviceType
      digitCode
      manualCode
      oiqiaDoorName
      doorDeviceId
      textMultiDesc {
        fr
        en
        es
        pt
        ru
        it
      }
      textMultiName {
        fr
        en
        es
        pt
        ru
        it
      }
    }
  }
`;

// NEW get apartment doors
export const GET_APARTMENT_DOOR_LOCKS_WITH_PICTURES = gql`
  query getAppartementDoorsWithPictures($bookingId: String!) {
    getAppartementDoorsWithPictures(bookingId: $bookingId) {
      _id
      description
      doorType
      deviceType
      digitCode
      manualCode
      oiqiaDoorName
      doorDeviceId
      textMultiDesc {
        fr
        en
        es
        pt
        ru
        it
      }
      textMultiName {
        fr
        en
        es
        pt
        ru
        it
      }
      doorPictureURL
    }
  }
`;

export const GET_OIQIA_SMARTLOCKINFO = gql`
  query getOiqiaSmartLockInfo($bookingId: String!, $doorDeviceId: String!) {
    getOiqiaSmartLockInfo(bookingId: $bookingId, doorDeviceId: $doorDeviceId) {
      name
      state
      stateValue
      doorStateValue
      doorState
    }
  }
`;

export const GET_ATTACHMENT_INFO = gql`
  query getAttachmentInfo($bookingId: String!) {
    getAttachmentInfo(bookingId: $bookingId) {
      appartId
      parking {
        title
        container
        name
        type
      }
      pictures {
        title
        container
        name
        type
      }
      welcomeNotes {
        title
        container
        name
        type
      }
      manuals {
        title
        container
        name
        type
      }
      rules {
        title
        container
        name
        type
      }
    }
  }
`;

export const GET_PICTURES = gql`
  query getAttachmentInfo($bookingId: String!) {
    getAttachmentInfo(bookingId: $bookingId) {
      pictures {
        title
        container
        name
        type
      }
    }
  }
`;

export const GET_MANUALS = gql`
  query getAttachmentInfo($bookingId: String!) {
    getAttachmentInfo(bookingId: $bookingId) {
      manuals {
        title
        container
        name
        type
      }
    }
  }
`;

export const GET_RULES = gql`
  query getAttachmentInfo($bookingId: String!) {
    getAttachmentInfo(bookingId: $bookingId) {
      rules {
        title
        container
        name
        type
      }
    }
  }
`;

export const GET_WELCOME_NOTES = gql`
  query getAttachmentInfo($bookingId: String!) {
    getAttachmentInfo(bookingId: $bookingId) {
      welcomeNotes {
        title
        container
        name
        type
      }
    }
  }
`;

export const GET_GUESTBOOK = gql`
  query getGuestbook($bookingId: String!) {
    getGuestbook(bookingId: $bookingId) {
      firstName
      comment
    }
  }
`;

export const GET_LATEST_TERMS = gql`
  query GetLatestTerms {
    getLatestTerms {
      _id
      terms
      createdAt
    }
  }
`;

export const COUNT_UNREAD_MESSAGE = gql`  
query countUnreadMessage($bookingId: String!, $lastMessageID: String!) {
  countUnreadMessage(bookingId: $bookingId, lastMessageID: $lastMessageID) {
    result
  }
}
`;
