import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import { AppartDoorsWithPictures } from "../../../types/types";
import i18n from "../../../i18n";
import { MD3Theme, useTheme } from "react-native-paper";
import Divider from "../../../components/Divider";
import AppButton from "../../../components/AppButton";

import Icon from "react-native-vector-icons/AntDesign";
import Mutations from "../../../api/mutations";
import DoorCardCommonHeader from "./DoorCardCommonHeader";
import { mobUser } from "../../../mobx/MobUser";
import { Text } from "../../../utils/fontsLoader";
import { mobAlert } from "../../../mobx/MobAlert";

interface DoorCardProps {
  door: AppartDoorsWithPictures;
  idx: number;
}

export default function DoorCardNuki({ door, idx }: DoorCardProps) {
  const [actionInProgress, setActionInProgress] = useState<boolean>(false);

  const mutations = new Mutations();

  const theme = useTheme();
  const styles = styleGen(theme);

  const executeAction = (
    action: "LOCK" | "UNLOCK",
  ) => {
    mobAlert.closeAlert();
    setActionInProgress(true);
    switch (action) {
    case "LOCK":
      mobAlert.setLoading(true);
      mutations.closeDoor(mobUser.OiqiaBookingId, door.doorDeviceId).then(response => {
        mobAlert.setLoading(false);

        if (response.data?.closeDoor?.success === true) {
          mobAlert.setSuccess(
            i18n.t("generic.success"),
            i18n.t("pages.access.lockSuccess"),
          );
        }

        setActionInProgress(false);
      }).catch(() => {
        mobAlert.setLoading(false);
        setActionInProgress(false);
      });

      break;
    case "UNLOCK":
      mobAlert.setLoading(true);
      mutations.openDoor(mobUser.OiqiaBookingId, door.doorDeviceId).then(response => {
        mobAlert.setLoading(false);
        if (response.data?.openDoor?.success === true) {
          mobAlert.setSuccess(
            i18n.t("generic.success"),
            i18n.t("pages.access.unlockSuccess"),
          );
        }
        setActionInProgress(false);
      }).catch((e) => {
        console.log("exception UNLOCK", e);
        mobAlert.setLoading(false);
        setActionInProgress(false);
      });
      break;
    default:
      console.warn("unknown action found at executeAction =>", action);
      break;
    }
  };

  const askConfirmation = (action: "LOCK" | "UNLOCK"): void => {
    mobAlert.setAlert(
      i18n.t("pages.access.confirmActionTitle"),
      i18n.t(`pages.access.confirmActionText${action}`),
      i18n.t("generic.yes"),
      () => executeAction(action),
      i18n.t("generic.no"),
      mobAlert.closeAlert
    );
  };

  return (
    <View>
      <View style={styles.container}>
        <DoorCardCommonHeader idx={idx} digitCode={null} textMultiName={door.textMultiName} textMultiDesc={door.textMultiDesc} doorPictureURL={door.doorPictureURL} />
        <View style={styles.actions}>
          <Text style={styles.actionsTitle}>
            {i18n.t("pages.access.openTitle")}
          </Text>
          <Text style={styles.actionsText}>
            {i18n.t("pages.access.openText")}{" "}
            <Text style={styles.oiqia}>{door.digitCode}</Text>
          </Text>
          <Text style={styles.orText}>
            {i18n.t("generic.or")}
          </Text>
          <AppButton
            extraStyle={styles.extraStyleBtn}
            extraTextStyle={styles.extraStyleBtnText}
            disabled={actionInProgress}
            children={i18n.t("pages.access.unlock").toUpperCase()}
            onPress={() => askConfirmation("UNLOCK")}
          />
        </View>
        <Divider extraStyle={{ width: "33%" }} />
        <View style={[styles.actions, { marginBottom: 20 }]}>
          <Text style={styles.actionsTitle}>
            {i18n.t("pages.access.closeTitle")}
          </Text>
          <Text style={styles.actionsText}>
            {i18n.t("pages.access.closeText")}{" "}
            <Icon style={styles.oiqia} name="caretleft" />
            {i18n.t("pages.access.closeText2")}
          </Text>
          <Text style={styles.orText}>
            {i18n.t("generic.or")}
          </Text>
          <AppButton
            disabled={actionInProgress}
            extraStyle={styles.extraStyleBtn}
            extraTextStyle={styles.extraStyleBtnText}
            children={i18n.t("pages.access.lock").toUpperCase()}
            onPress={() => askConfirmation("LOCK")}
          />
        </View>
      </View>
    </View>
  );
}

const styleGen = (theme: MD3Theme) => StyleSheet.create({
  container: {
    backgroundColor: theme.colors.secondary,
    padding: 20,
    paddingBottom: 0,
    borderRadius: 20,
    alignItems: "center",
    gap: 20,
  },
  title: {
    fontSize: 25,
    textAlign: "center",
    fontFamily: "Gilroy-Bold",
  },
  actions: {
    width: "80%",
    marginTop: 0
  },
  actionsTitle: {
    fontSize: 20,
    textAlign: "center",
    fontFamily: "Gilroy-Light",
    marginBottom: 20,
  },
  actionsText: {
    fontSize: 20,
    textAlign: "center",
    fontFamily: "Gilroy-Light",
  },
  orText: {
    fontSize: 20,
    textAlign: "center",
    fontFamily: "Gilroy-Light",
    marginTop: 10,
    textTransform: "lowercase"
  },
  extraStyleBtn: {
    marginTop: 10,
    paddingVertical: 7,
  },
  extraStyleBtnText: { fontFamily: "Gilroy-Medium" },
  oiqia: {
    fontSize: 20,
    textAlign: "center",
    fontFamily: "Gilroy-Bold",
    color: theme.colors.primary,
  },
  laoding: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});
