import React, { useEffect, useState } from "react";
import { Dimensions, ScrollView, StyleSheet, View } from "react-native";
import {
  AssistanceIcon, BedIcon, BookIcon,
  BookingIcon, CarIcon, CheckInIcon,
  CheckOutIcon, HouseIcon, InvoiceIcon,
  KeyIcon, LanguageIcon, LocationIcon,
  PowerIcon, WelcomeIcon, WifiIcon
} from "../../components/Icons";
import i18n from "../../i18n";
import HomePageButton from "../../components/HomePageButton";
import { mobUser } from "../../mobx/MobUser";
import { observer } from "mobx-react";
import { useNavigation } from "@react-navigation/native";
import * as Notifications from "expo-notifications";
//import * as TaskManager from 'expo-task-manager';
import { ApolloQueryResult } from "@apollo/client";
import Queries from "../../api/queries";
import { openMapByLatLng } from "../../utils/itinerary";
import { mobAlert } from "../../mobx/MobAlert";

export type IconType = {
  icon: JSX.Element,
  title: string,
  page?: string,
  onPress?: () => void
};

/* const BACKGROUND_NOTIFICATION_TASK = 'BACKGROUND-NOTIFICATION-TASK';

TaskManager.defineTask(
  BACKGROUND_NOTIFICATION_TASK,
  ({ data, error, executionInfo }) => {
    if (error) {
      console.log('error occurred');
    }
    if (data) {
      console.log('data-----', data);
    }
  })

Notifications.registerTaskAsync(BACKGROUND_NOTIFICATION_TASK); */

const Home = observer(() => {
  const styles = styleGen();
  const navigation = useNavigation();
  const [latLng, setLatLng] = useState<{ lat: string, lng: string }>();
  const queries = new Queries();

  const setLogoutModal = () => {
    mobAlert.setAlert(
      i18n.t("pages.logout.modalTitle"),
      null,
      i18n.t("pages.logout.disconnect"),
      ()=>{
        mobUser.LogOut();
        mobAlert.closeAlert();
      },
      i18n.t("generic.cancel"),
      mobAlert.closeAlert
    );
  };

  const InvoiceAction = async () => {
    const resa = await queries.getResaInfo(mobUser.OiqiaBookingId);

    if (resa.channelName === "Airbnb") {
      mobAlert.setAlert(i18n.t("pages.invoice.unavaliable"), i18n.t("pages.invoice.contactOTA"));
    } else {
      navigation.navigate("Invoice");
    }
  };

  useEffect(() => {
    let latLngSubscription;

    async function subscribeToLatLng() {
      try {
        const latLngObservable = await queries.getLatLngById(mobUser.OiqiaBookingId);
        latLngSubscription = latLngObservable.subscribe({
          next(response: ApolloQueryResult<{ NTA_getBookingById: { lat: number, lng: number } }>) {
            setLatLng({
              lat: response?.data?.NTA_getBookingById?.lat?.toString(),
              lng: response?.data?.NTA_getBookingById?.lng?.toString()
            });
          },
          error(err) {
            console.error("Error fetching latLng data:", err);
          }
        });
      } catch (error) {
        console.error("Error setting up subscription:", error);
      }
    }
    const subscription = Notifications.addNotificationResponseReceivedListener(async response => {
      const { screen } = response.notification.request.content.data;
      if (screen) {
        navigation.navigate(screen);
      }
    });

    subscribeToLatLng();
    return () => {
      latLngSubscription?.unsubscribe();
      subscription.remove();
    };
  }, []);

  const iconSize = Dimensions.get("screen").height * 0.087;

  const ICONS_LIST: IconType[][] = [
    [
      { icon: <HouseIcon height={iconSize} width={iconSize} />, title: i18n.t("home.housing"), page: "Housing" },
      { icon: <LocationIcon height={iconSize} width={iconSize} />, title: i18n.t("home.itinerary"), onPress: () => openMapByLatLng(latLng.lat, latLng.lng) },
      { icon: <KeyIcon height={iconSize} width={iconSize} />, title: i18n.t("home.access"), page: "Access" }
    ],
    [
      { icon: <BookingIcon height={iconSize} width={iconSize} />, title: i18n.t("home.booking"), page: "BookingInfos" },
      { icon: <CheckInIcon height={iconSize} width={iconSize} />, title: i18n.t("home.checkIn"), page: "CheckIn" },
      { icon: <CheckOutIcon height={iconSize} width={iconSize} />, title: i18n.t("home.checkOut"), page: "CheckOut" }
    ],
    [
      { icon: <BedIcon height={iconSize} width={iconSize} />, title: i18n.t("home.equipments"), page: "Equipments" },
      { icon: <WifiIcon height={iconSize} width={iconSize} />, title: i18n.t("home.wifi"), page: "Wifi" },
      { icon: <CarIcon height={iconSize} width={iconSize} />, title: i18n.t("home.carPark"), page: "Parking" }
    ],
    [
      { icon: <InvoiceIcon height={iconSize} width={iconSize} />, title: i18n.t("home.invoice"), onPress: InvoiceAction },//page: "Invoice" },
      { icon: <WelcomeIcon height={iconSize} width={iconSize} />, title: i18n.t("home.welcomeNotes"), page: "Welcome" },
      { icon: <BookIcon height={iconSize} width={iconSize} />, title: i18n.t("home.guestbook"), page: "Guestbook" }
    ],
    [
      { icon: <LanguageIcon height={iconSize} width={iconSize} />, title: i18n.t("home.language"), page: "Language" },
      { icon: <AssistanceIcon height={iconSize} width={iconSize} />, title: i18n.t("home.assistance"), page: "Assistance" },
      { icon: <PowerIcon height={iconSize} width={iconSize} />, title: i18n.t("home.disconnect"), onPress: setLogoutModal }
    ]
  ];

  return (
    <ScrollView key={mobUser.Language} contentContainerStyle={styles.container}>
      {ICONS_LIST.map((icons: IconType[], idx: number) => {
        return icons.map((component: IconType, jdx: number) => (
          <View key={idx + "/" + jdx} style={styles.rowFlex}>
            <HomePageButton component={component} />
          </View>
        ));
      }
      )}
    </ScrollView>
  );
});

export default Home;

const styleGen = () => StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    alignContent: "space-around",
    justifyContent: "center",
    flexWrap: "wrap"
  },
  rowFlex: {
    width: "30%",
    alignItems: "center"
  },
});
