import React, { useEffect, useRef } from "react";
import { StyleSheet, Animated, Easing } from "react-native";

export default function Skeleton() {
  const fadeAnim = useRef(new Animated.Value(0)).current;
  const breathIn = Easing.out(Easing.sin);
  const breathOut = Easing.in(Easing.sin);

  useEffect(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(fadeAnim, {
          toValue: 1,
          duration: 5000,
          easing: breathIn,
          useNativeDriver: true
        }),
        Animated.timing(fadeAnim, {
          toValue: 2,
          duration: 3000,
          easing: breathOut,
          useNativeDriver: true
        }),
        Animated.timing(fadeAnim, {
          toValue: 1,
          duration: 5000,
          useNativeDriver: true,
        }),
      ])
    ).start();
  }, [fadeAnim]);

  return (
    <Animated.View style={{
      ...styles.skeleton,
      opacity: fadeAnim
    }}>

    </Animated.View>
  );
}

const styles = StyleSheet.create({
  skeleton: {
    width: "100%",
    height: "100%",
    borderRadius: 15,
    backgroundColor: "#cccccc"
  }
});
