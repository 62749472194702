import React from "react";
import { Dimensions, StyleSheet, ScrollView } from "react-native";
import LoginForm from "./LoginForm";
import { ColoredOiqiaIcon } from "../../components/Icons";
import { MD3Theme, useTheme } from "react-native-paper";

export default function Login() {
  const theme = useTheme();
  const styles = styleGen(theme);

  return (
    <ScrollView
      contentContainerStyle={styles.container}
      keyboardDismissMode="interactive"
    >
      <ColoredOiqiaIcon width={Dimensions.get("screen").height / 6} height={Dimensions.get("screen").height / 6} />
      <LoginForm />
    </ScrollView>
  );
}

const styleGen = (theme: MD3Theme) => StyleSheet.create({
  container: {
    minHeight: Dimensions.get("screen").height,
    alignItems: "center",
    backgroundColor: theme.colors.background,
    paddingVertical: "20%",
    gap: 35
  },
});
