import { format } from "date-fns";
import { fr, enGB, es, it, pt, ru } from "date-fns/locale";
import { mobUser } from "../mobx/MobUser";

const mapFNSLocales= {
  "fr":fr,
  "en":enGB,
  "es":es,
  "it":it,
  "pt":pt,
  "ru":ru
};

export const formatDateHour = (dateText) => {
  return format(new Date(dateText), "PPPp", { locale: mapFNSLocales[mobUser.Language] });
};

export const formatDate = (dateText) => {
  return format(new Date(dateText), "PPP", { locale: mapFNSLocales[mobUser.Language] });
};