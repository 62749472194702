import { action, makeObservable, observable } from "mobx";
//import { GRAPHQL_URL } from "@env";
//import { ApolloClient, InMemoryCache, NormalizedCacheObject, gql } from "@apollo/client";

class Store {
  text = "";

  updateText = (text) => {
    this.text = text;
  };

  constructor() {
    makeObservable(this, {
      text: observable,
      updateText: action
    });
  }
}

export default new Store();
