import React, { Dispatch, SetStateAction } from "react";
import { StarIcon } from "./Icons";
import { TouchableHighlight } from "react-native";
import { useTheme } from "react-native-paper";

interface StarButtonProps {
  filled?: boolean
  nb: number
  value: number
  setValue: Dispatch<SetStateAction<number>>
  setStarsArray: Dispatch<SetStateAction<boolean[]>>
  editable?: boolean;
}

export default function StarButton({filled, nb, value, setValue, setStarsArray, editable = true }: StarButtonProps) {
  const theme = useTheme();
  const [ isPress, setIsPress ] = React.useState(false);
  const touchProps = {
    underlayColor: theme.colors.secondary,
    onHideUnderlay: () => setIsPress(false),
    onShowUnderlay: () => setIsPress(true)
  };

  function onPress() {
    setValue(nb);
    setStarsArray(Array(nb).fill(true).concat(Array(5 - nb).fill(false)));
  }

  function onPressIn() {
    setStarsArray(Array(nb).fill(true).concat(Array(5 - nb).fill(false)));
  }

  function onPressOut() {
    setStarsArray(Array(value).fill(true).concat(Array(5 - value).fill(false)));
  }

  return (
    <TouchableHighlight
      onPress={onPress}
      onPressIn={onPressIn}
      onPressOut={onPressOut}
      disabled={!editable}
      {...touchProps}
    >
      <StarIcon color={(filled || isPress) ? theme.colors.primary : theme.colors.background} />
    </TouchableHighlight>

  );
}
