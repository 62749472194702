import React, { useEffect } from "react";
import { StyleSheet, View, } from "react-native";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { MD3Theme, useTheme } from "react-native-paper";
import FieldErrorText from "../../../components/FieldErrortext";
import Rating from "../../../components/Rating";
import AppButton from "../../../components/AppButton";
import i18n from "../../../i18n";
import FormCheckBox from "../../../components/FormCheckBox";
import ProblemSection from "../../../components/ProblemSection";
import AppInput from "../../../components/AppInput";
import { mobUser } from "../../../mobx/MobUser";
import Mutations from "../../../api/mutations";
import { mobAlert } from "../../../mobx/MobAlert";
import { Text } from "../../../utils/fontsLoader";
import DoneCard from "../../../components/DoneCard";
import { findDifferences } from "../../../utils/formUtils";
import { useNavigation } from "@react-navigation/native";

export default function CheckOutForm({ checkOutInfos }) {
  const navigation = useNavigation();
  const theme = useTheme();
  const styles = stylesGen(theme);
  const mutations = new Mutations();

  const defaultValues = {
    globalRating: 0,
    cleanlinessRating: 0,
    arrivalRating: 0,
    feedBack: "",
    isCheckoutDone: false,
    isSatisfied: true,
    problem: {
      description: "",
      images: []
    },
    checkoutQuestions: [],
  };

  const formMethods = useForm({
    defaultValues
  });

  const {
    control,
    reset,
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const isCheckoutDone = watch("isCheckoutDone");

  const questions = watch("checkoutQuestions");

  useEffect(() => {
    // Mise à jour des valeurs par défaut useStatedu formulaire avec les nouvelles données de checkInInfos
    const currentValues = getValues();
    reset({
      ...currentValues,
      globalRating: checkOutInfos?.ratingAppart || 0,
      cleanlinessRating: checkOutInfos?.ratingCleanness || 0,
      arrivalRating: checkOutInfos?.ratingOiqia || 0,
      feedBack: checkOutInfos?.feedBack || "",
      isCheckoutDone: checkOutInfos?.isCheckoutDone || false,
      isSatisfied: checkOutInfos?.isSatisfied === undefined ? true : checkOutInfos?.isSatisfied,
      problem: {
        description: checkOutInfos?.problem?.description || "",
        images: checkOutInfos?.problem?.image || []
      },
      checkoutQuestions: checkOutInfos?.checkoutQuestion?.map(question => ({
        title: question.text,
        checked: question.checked,
      })) ?? [],
      ...findDifferences(currentValues, defaultValues)
    });
  }, [checkOutInfos, reset, getValues]);

  const updateCheckOut = async (data) => {
    const transformedData = {
      bookingId: mobUser.OiqiaBookingId,
      ratingAppart: data.globalRating,
      ratingCleanness: data.cleanlinessRating,
      ratingOiqia: data.arrivalRating,
      feedBack: data.feedBack,
      isSatisfied: data.isSatisfied,
      problem: {
        description: data.problem.description,
        image: data.problem.images,
      },
      checkoutQuestion: data.checkoutQuestions.map(q => ({
        text: q.title,
        checked: q.checked
      })),
      isCheckoutDone: data.isCheckoutDone
    };

    try {
      if (data.isCheckoutDone) mobAlert.setLoading(true);
      await mutations.addCheckOut(transformedData).then(() => {
        if (data.isCheckoutDone) {
          mobAlert.setLoading(false);
          mobAlert.setSuccess(
            i18n.t("pages.checkOut.successTitle"),
            i18n.t("pages.checkOut.successMessage"),
            () => {
              mobAlert.closeSuccess();
              //scrollRef.current.scrollTo({ x: 0, y: 0, animated: true });
              navigation.goBack();
            }
          );
        }
      });
      //console.log(transformedData);
      //const response = await mutations.addCheckOut(transformedData);
      //console.log('Check-out successful:', response);
    } catch (error) {
      mobAlert.setLoading(false);
      //console.error('Check-out failed:', error);
    }
  };

  useEffect(() => {
    return () => {
      const currentFormData = getValues();
      if (!currentFormData.isCheckoutDone) {
        updateCheckOut(currentFormData);
      }
    };
  }, []);

  const onError = () => mobAlert.setError(i18n.t("errors.missingField"), i18n.t("errors.missingFieldText"));

  async function onSubmit(data) {
    setValue("isCheckoutDone", true);
    updateCheckOut({ ...data, isCheckoutDone: true });
  }
  const CHECK_OUT_FORM_RATING_FIELDS: {
    title: string,
    name: "globalRating" | "cleanlinessRating" | "arrivalRating"
  }[] = [
    { title: i18n.t("pages.checkOut.rating1"), name: "globalRating" },
    { title: i18n.t("pages.checkOut.rating2"), name: "cleanlinessRating" },
    { title: i18n.t("pages.checkOut.rating3"), name: "arrivalRating" },
  ];

  return (
    <View style={styles.container}>
      {isCheckoutDone && <DoneCard field={{ title: i18n.t("pages.checkOut.doneMessage") }} />}
      <FormProvider {...formMethods}>
        {CHECK_OUT_FORM_RATING_FIELDS.map((field, idx) => (
          <Controller
            key={idx}
            control={control}
            rules={{
              required: i18n.t("errors.missingField"),
              validate: value => value > 0 || i18n.t("errors.ratingGreaterThanZeroError")
            }}
            render={({ field: { onChange, value } }) => (
              <View style={styles.field}>
                <Text style={styles.label}>
                  {field.title}
                </Text>
                <Rating value={value} setValue={onChange} editable={!isCheckoutDone} />
                {errors[field.name] && <FieldErrorText errorMessage={errors[field.name].message} />}
              </View>
            )}
            name={field.name}
          />
        ))}
        <Controller
          control={control}
          rules={{
            required: i18n.t("errors.missingField"),
          }}
          render={({ field: { onChange, value } }) => (
            <View style={styles.field}>
              <Text style={styles.label}>
                {i18n.t("pages.checkOut.feedBack")}
              </Text>
              <AppInput
                value={value}
                onChangeText={onChange}
                additionalStyle={styles.inputText}
                multiline
                editable={!isCheckoutDone}
              />
              {errors.feedBack && <FieldErrorText errorMessage={errors.feedBack.message} />}
            </View>
          )}
          name="feedBack"
        />
        <ProblemSection />
        {!questions.length ||
          <Text style={styles.label}>
            {i18n.t("pages.checkOut.doTasks")}
          </Text>
        }
        {questions.map((field, idx) => (
          <Controller
            key={idx}
            control={control}
            name={`checkoutQuestions.${idx}.checked`}
            rules={{
              required: i18n.t("pages.checkOut.missingTask"),
            }}
            render={({ field: { value } }) => (
              <View style={[styles.field, styles.checkBox]}>
                <FormCheckBox buttonStatus={value} setButtonStatus={() => setValue(`checkoutQuestions.${idx}.checked`, !value)} editable={!isCheckoutDone}>
                  {field.title}
                </FormCheckBox>
                {errors.checkoutQuestions?.[idx]?.checked && <FieldErrorText errorMessage={errors.checkoutQuestions[idx].checked.message} />}
              </View>
            )}
          />
        ))}
        {!isCheckoutDone &&
          <AppButton
            extraStyle={styles.submitButton}
            onPress={handleSubmit(onSubmit, onError)}>
            {i18n.t("pages.checkOut.submit")}
          </AppButton>
        }
      </FormProvider>
    </View >
  );
}

const stylesGen = (theme: MD3Theme) => StyleSheet.create({
  container: {
    alignItems: "center",
    gap: 25
  },
  field: {
    alignItems: "center",
    width: "100%",
    gap: 10
  },
  checkBox: {
    alignItems: "flex-start",
    marginLeft: "20%",
  },
  label: {
    fontSize: 20,
    textAlign: "center"
  },
  inputText: {
    fontSize: 20,
    fontFamily: "Gilroy-Light",
  },
  submitButton: {
    marginTop: 20
  },
  row: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    flexWrap: "wrap",
  },
  button: {
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
    backgroundColor: "#007bff",
    borderRadius: 22,
  },
  icon: {
    marginRight: 8,
  },
  buttonText: {
    fontFamily: "Gilroy-SemiBold",
    color: theme.colors.background,
  },
});
