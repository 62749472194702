import React from "react";
import RadioButton from "./RadioButton";

interface FormCheckBoxProps {
  buttonStatus: boolean,
  setButtonStatus: any,
  children: string,
  editable?: boolean
}

export default function FormCheckBox({ children, buttonStatus, setButtonStatus, editable = true }: FormCheckBoxProps) {

  const radioOptions = [
    { label: children, value: true },
  ];
  return (
    <RadioButton
      options={radioOptions}
      value={buttonStatus}
      onChange={setButtonStatus}
      editable={editable}
      sizeFactor={1.1}
    />
  );
}
