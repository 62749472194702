import React from "react";
import { Platform, StatusBar, View } from "react-native";
import { useTheme } from "react-native-paper";

export default function CustomStatusBar() {
  const HEADER_HEIGHT = Platform.OS === "ios" ? 44 : 0;
  const theme = useTheme();

  if (Platform.OS === "android")
    return <></>;

  return (
    <View
      style={{
        backgroundColor: theme.colors.primary,
        height: Platform.OS === "ios" ? HEADER_HEIGHT : StatusBar.currentHeight,
      }}>
      <StatusBar
        translucent
        backgroundColor={theme.colors.primary}
        barStyle="light-content"
      />
    </View>
  );
}
