import React from "react";
import { Dimensions, StyleSheet } from "react-native";
import { PictureData } from "../types/types";
import ParallaxCarousel from "./ParallaxCarousel";
import { Card } from "react-native-paper";

interface ImageGalleryProps {
  pictureData: PictureData
}

const picSize = Dimensions.get("screen").width*0.73;

export default function ImageGallery({ pictureData }: ImageGalleryProps) {
  //<Image width={300} height={300} source={{uri:pictureData?.pictures[0].container+"/"+pictureData?.pictures[0].name}} />

  return (
    <Card>
      <ParallaxCarousel
        data={pictureData?.pictures.map((img, _idx) => {
          img["uri"] = `${img.container}/${img.name}`;
          return img;
        })}
        itemWidth={styles.image.width}
        itemHeight={styles.image.height}
      />
    </Card>
  );
}

const styles = StyleSheet.create({
  image: {
    width: picSize,
    height: picSize,
    borderRadius: 20
  },
});
