import React, { useEffect, useState } from "react";
import { Dimensions, Platform, View, TouchableOpacity } from "react-native";
import Animated, {
  Extrapolate,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
} from "react-native-reanimated";
import Carousel from "react-native-reanimated-carousel";
import { withAnchorPoint } from "../utils/withAnchorPoint";
import { useTheme } from "react-native-paper";
import { mobAlert } from "../mobx/MobAlert";

const ParallaxCarousel = ({
  data, // Array of items (e.g., colors, images)
  itemWidth, // Width of each item
  itemHeight, // Height of each item
  autoPlay = false, // Autoplay feature
  autoPlayInterval = 1500, // Interval for autoplay
  loop = true, // Loop the carousel or not
  pagingEnabled = true
}) => {

  const [updatedData, updateData] = useState(data);
  const progressValue = useSharedValue<number>(0);

  useEffect(() => {
    updateData(data);
  }, [data]);
  const baseOptions = {
    vertical: false,
    width: itemWidth || Dimensions.get("window").width,
    height: itemHeight || Dimensions.get("window").height,
    loop,
    autoPlay,
    autoPlayInterval,
    pagingEnabled,
    withAnimation: {
      type: "spring",
      config: { damping: 13 },
    },
  };

  return (
    <View style={{ flex: 1, alignItems: "center" }}>
      <Carousel
        {...baseOptions}
        withAnimation={{
          type: "spring",
          config: {
            damping: 13,
          },
        }}
        data={updatedData}
        scrollAnimationDuration={10000}
        onProgressChange={(_, absoluteProgress) =>
          (progressValue.value = absoluteProgress)
        }
        renderItem={({ index, animationValue }) => (
          <Card
            data={updatedData}
            index={index}
            animationValue={animationValue}
            itemWidth={baseOptions.width}
            itemHeight={baseOptions.height}
          />
        )}
      />
      <View style={{ top: itemHeight, marginTop: 10, flexDirection: "row", gap: 10 }}>
        {data && data.map((element, index) => (
          <PaginationItem
            animValue={progressValue}
            index={index}
            key={index}
            length={data.length}
          />
        ))}
      </View>
    </View>
  );
};

const PaginationItem = React.memo(({ animValue, index, length }) => {
  const theme = useTheme();
  const width = 10;

  const animStyle = useAnimatedStyle(() => {
    let inputRange = [index - 1, index, index + 1];
    let outputRange = [-width, 0, width];

    if (index === 0 && animValue?.value > length - 1) {
      inputRange = [length - 1, length, length + 1];
      outputRange = [-width, 0, width];
    }

    return {
      transform: [
        {
          translateX: interpolate(
            animValue?.value,
            inputRange,
            outputRange,
            Extrapolate.CLAMP,
          ),
        },
      ],
    };
  }, [animValue, index, length]);
  return (
    <View
      style={{
        backgroundColor: theme.colors.background,
        width,
        height: width,
        borderRadius: 50,
        shadowColor: theme.colors.onBackground,
        shadowOffset: {
          width: 0,
          height: 8,
        },
        borderColor: "#DCDCDC",
        borderWidth: Platform.OS == "android" ? 1 : 0,
        shadowOpacity: 0.3,
        shadowRadius: 10.32,
        overflow: "hidden",
        transform: [
          {
            rotateZ: "0deg"
          },
        ],
      }}
    >
      <Animated.View
        style={[
          {
            borderRadius: 50,
            backgroundColor: theme.colors.onBackground,
            flex: 1,
          },
          animStyle,
        ]}
      />
    </View>
  );
});

const AnimatedTouchable = Animated.createAnimatedComponent(TouchableOpacity);

const Card = React.memo(({ index, animationValue, data, itemWidth, itemHeight }) => {
  const theme = useTheme();
  const colors = [theme.colors.background];

  const cardStyle = useAnimatedStyle(() => {
    const scale = interpolate(
      animationValue.value,
      [-0.1, 0, 1],
      [0.95, 1, 1],
      Extrapolate.CLAMP,
    );

    const translateX = interpolate(
      animationValue.value,
      [-1, -0.2, 0, 1],
      [0, itemWidth * 0.3, 0, 0],
    );

    const rotateY = interpolate(
      animationValue.value,
      [-1, 0, 0.4, 1],
      [30, 0, -25, -25],
      Extrapolate.CLAMP,
    ) + "deg";

    let transform = [
      { scale },
      { translateX },
      { perspective: 200 },
      { rotateY },
    ];

    transform = withAnchorPoint(
      { transform },
      0.5,
      0.5,
      itemWidth,
      itemHeight,
    ).transform;

    return {
      transform,
    };
  }, [animationValue, itemWidth, itemHeight]);

  const imageUrls = data.map((img, _idx) => {
    return ({
      url: `${img.container}/${img.name}`,
    });
  });

  return (
    <>
      <Animated.View
        style={[
          {
            flex: 1,
            backgroundColor: colors[index % colors.length],
            //justifyContent: "center",
            alignItems: "center",
            borderRadius: 20,
            shadowColor: theme.colors.onBackground,
            shadowOffset: {
              width: 0,
              height: 8,
            },
            borderColor: "#DCDCDC",
            borderWidth: Platform.OS == "android" ? 1 : 0,
            shadowOpacity: 0.3,
            shadowRadius: 10.32,
            overflow: "hidden"
            //elevation: 16,
          },
          cardStyle,
        ]}
      //resizeMode={"cover"}
      >
        <AnimatedTouchable activeOpacity={1} onPress={() => mobAlert.setImageModal(index,imageUrls)}>
          <>
            <Animated.Image
              source={{ uri: data[index].uri }}
              style={[
                {
                  height: itemHeight * 0.7,
                  width: itemWidth,
                  //elevation: 16,
                },
              ]}
              resizeMode={"cover"}
            />
            <Animated.Text
              style={{
                color: theme.colors.onBackground,
                marginTop: itemHeight * 0.1,
                fontFamily: "Gilroy-Bold",
                fontSize: 18,
                textAlign: "center",
                textTransform: "uppercase"
              }}
            >
              {data[index].title}
            </Animated.Text>
          </>
        </AnimatedTouchable>
      </Animated.View>
    </>
  );
});

export default ParallaxCarousel;