import React from "react";
import { StyleSheet } from "react-native";
import { useTheme } from "react-native-paper";
import { Text } from "../utils/fontsLoader";

export default function FieldErrorText({ errorMessage }) {
  const theme = useTheme();
  const styles = StyleSheet.create({
    textError: {
      color: theme.colors.error
    }
  });

  return <Text style={styles.textError}>{errorMessage}</Text>;
}