import React from "react";
import { View, StyleSheet } from "react-native";
import { MaterialIcons } from "@expo/vector-icons";
import { Button, MD3Theme, useTheme } from "react-native-paper";

import i18n from "../../i18n";
import { modalStylesGen } from "./OiqiaLoadingModal";
import { Text } from "../../utils/fontsLoader";

export interface OiqiaSuccessModalProps {
  message?: string;
  title: string;
  onClose: () => void;
}

export const OiqiaSuccessModal = (props: OiqiaSuccessModalProps) => {
  const { message, title, onClose } = props;
  const theme = useTheme();
  const styles = styleGen(theme);

  return (
    <View style={{ ...modalStylesGen(theme).modalBackground, backgroundColor: "rgba(0,0,0,0.4)" }}>
      <View style={styles.errorWrapper}>
        <MaterialIcons name="check-circle-outline" size={40} color={theme.colors.success} />
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.message}>{message}</Text>
        <Button
          mode="contained"
          dark={true}
          buttonColor={theme.colors.primary}
          textColor={theme.colors.background}
          onPress={onClose}
          style={styles.button}
        >
          {i18n.t("generic.close")}
        </Button>
      </View>
    </View>
  );
};

const styleGen = (theme: MD3Theme) => StyleSheet.create({
  errorWrapper: {
    backgroundColor: theme.colors.background,
    padding: 20,
    width: 300,
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
    gap: 10
  },
  title: {
    textAlign: "center",
    fontFamily: "Gilroy-Bold",
    fontSize: 16,
    width: "80%"
  },
  message: {
    textAlign: "center"
  },
  button: {

  }
});

export default OiqiaSuccessModal;