import React from "react";
import { StyleSheet, View } from "react-native";
import { MD3Theme, useTheme } from "react-native-paper";
import { InfoCardTextProps } from "../../../types/types";
import BookingIDText from "./BookingIDText";

interface InfoCardProps {
  field: InfoCardTextProps
}

export default function BookingIDCard({ field }: InfoCardProps) {
  const theme = useTheme();
  const styles = genStyles(theme);

  return (
    <View style={styles.container}>
      <BookingIDText title={field?.title} text={field?.text} />
    </View>
  );
}

const genStyles = (theme: MD3Theme) => StyleSheet.create({
  container: {
    backgroundColor: theme.colors.secondary,
    alignItems:"center",
    padding: 10,
    borderRadius: 20,
    gap: 3,
  }
});
