import React from "react";
import { StyleSheet, View } from "react-native";
import { WifiInfos } from "../../../types/types";
import AppButton from "../../../components/AppButton";
import * as Clipboard from "expo-clipboard";
import i18n from "../../../i18n";
import { Text } from "../../../utils/fontsLoader";

interface WifiCardInfosProps {
  wifiInfos: WifiInfos
}
/*
        <View style={styles.flexRow}>
          <View style={{ flex: 1 }}>
            <Text style={styles.title}>
              {i18n.t("pages.housing.address")}
            </Text>
            <Text style={styles.dataText}>
              {data.address.streetNumber}{" "}
              {data.address.street},{" "}
              {data.address.zipCode}{" "},
              {data.address.city},{" "}
              {data.address.country}
            </Text>
          </View>
          <View>
            <HomePageButton
              component={component}
              extraStyle={{
                iconNormal: {
                  color: theme.colors.primary
                },
                iconPress: {}
              }}
            />
          </View>
        </View>
      </View>
*/
export default function WifiCardInfos({ wifiInfos }: WifiCardInfosProps) {

  async function copyWifiCodeToClipboard() {
    await Clipboard.setStringAsync(wifiInfos.wifiCode);
  }

  return (
    <>
      <View>
        <Text style={styles.title}>
          {i18n.t("pages.wifi.wifiName")}
        </Text>
        <Text style={styles.text}>
          {wifiInfos?.wifiName}
        </Text>
      </View>
      <View style={styles.row}>
        <View style={{ flex: 1}}>
          <Text style={styles.title}>
            {i18n.t("pages.wifi.wifiPassword")}
          </Text>
          <Text style={styles.text}>
            {wifiInfos?.wifiCode}
          </Text>
        </View>
        <View style={{}}>
          <AppButton
            onPress={copyWifiCodeToClipboard}
          >
              Copier
          </AppButton>
        </View>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    width: "100%",
    gap: 5,
    alignItems: "center"
  },
  title: {
    fontSize: 20,
    padding: 3,
    fontFamily: "Gilroy-Bold"
  },
  text: {
    fontSize: 17,
    padding: 3,
  },
  button: {
    margin: 5
  },
  buttonText: {
  }
});
