import React, { useEffect, useState } from "react";
import { Platform, StyleSheet, View } from "react-native";
import StarButton from "./StarButton";
import { useTheme } from "react-native-paper";
import AppInput from "./AppInput";

interface RatingProps {
  value: number,
  setValue: (...event: any[]) => void;
  editable?: boolean;
}

export default function Rating({ value, setValue, editable = true }: RatingProps) {
  const theme = useTheme();

  const [starsArray, setStarsArray] = useState<boolean[]>(Array(5).fill(false));

  const styles = StyleSheet.create({
    ..._styles,
    row: {
      ..._styles.row,
      backgroundColor: theme.colors.secondary
    }
  });

  // WEB-APP HANDLER
  const getValueForWeb = (): number => {

    let value = 0;

    for (const stars of starsArray) {
      if (stars === true) {
        value++;
      }
    }

    return value;
  };
  // END WEB-APP HANDLER

  useEffect(() => {
    const updatedStarsArray = starsArray.map((_, idx) => idx < value);
    setStarsArray(updatedStarsArray);
  }, [value]);

  return (
    <View style={styles.row}>
      {Platform.OS !== "web" && starsArray.map((boolValue, idx) => (
        <StarButton
          key={idx} nb={idx + 1}
          value={value}
          setValue={setValue}
          setStarsArray={setStarsArray}
          filled={boolValue}
          editable={editable}
        />
      ))}
      {Platform.OS === "web" &&
        <AppInput
          value={getValueForWeb()}
          onChangeText={(v) => setValue(Number(v))}
          keyboardType='number-pad'
          editable={editable}
        />
      }
    </View>
  );
}

const _styles = StyleSheet.create({
  row: {
    width: "100%",
    justifyContent: "center",
    flexDirection: "row",
    borderWidth: 1,
    borderColor: "lightgray",
    borderRadius: 20,
    padding: 10,
  }
});
