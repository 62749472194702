import React, { useEffect, useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import Queries from "../../../api/queries";
import { AttachmentInfo, WelcomeData } from "../../../types/types";
import { ApolloQueryResult } from "@apollo/client";
import { mobUser } from "../../../mobx/MobUser";
import PDFGridLayout from "../../../components/PDFGridLayout";
import { WelcomeIcon } from "../../../components/Icons";
import { MD3Theme, useTheme } from "react-native-paper";

export default function Welcome() {
  const theme = useTheme();
  const styles = styleGen(theme);
  const [welcomes, setWelcomes] = useState<AttachmentInfo[]>();
  const queries = new Queries();

  useEffect(() => {
    let welcomeSubscription;

    async function subscribeToData() {
      try {
        const attachmentObservable = await queries.getWelcomeNotes(mobUser.OiqiaBookingId);
        welcomeSubscription = attachmentObservable.subscribe({
          next(response: ApolloQueryResult<{ getAttachmentInfo: WelcomeData }>) {
            setWelcomes(response.data.getAttachmentInfo?.welcomeNotes);
          },
          error(err) {
            console.error("Error fetching attachment data:", err);
          }
        });
      } catch (error) {
        console.error("Error setting up subscriptions:", error);
      }
    }

    subscribeToData();

    return () => {
      welcomeSubscription?.unsubscribe();
    };
  }, []);

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <View style={styles.subContainer}>
        <PDFGridLayout
          data={welcomes}
          IconComponent={WelcomeIcon}
        />
      </View>
    </ScrollView>
  );
}

const styleGen = (theme: MD3Theme) => StyleSheet.create({
  container: {
    backgroundColor: theme.colors.background,
    alignItems: "center",
  },
  subContainer: {
    margin: 20,
    backgroundColor: theme.colors.background,
    width: "80%"
  }
});