import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { AppartmentCaractInfo, BookingOiqiaQl } from "../../../types/types";
import i18n from "../../../i18n";
import { IconType } from "..";
import { LocationIcon } from "../../../components/Icons";
import HomePageButton from "../../../components/HomePageButton";
import { useTheme } from "react-native-paper";
import Queries from "../../../api/queries";
import { ApolloQueryResult } from "@apollo/client";
import { mobUser } from "../../../mobx/MobUser";
import { openMapByLatLng } from "../../../utils/itinerary";
import { Text } from "../../../utils/fontsLoader";
import getTextFromMultilang from "../../../utils/getTextFromMultiLang";

interface HousingInfosProps {
  data: BookingOiqiaQl;
}

export default function HousingInfos({ data }: HousingInfosProps) {
  const component: IconType = { icon: <LocationIcon />, title: "", onPress: () => openMapByLatLng(latLng?.lat, latLng?.lng) };
  const theme = useTheme();
  const [latLng, setLatLng] = useState<{ lat: string, lng: string }>();
  const [caract, setCaract] = useState<AppartmentCaractInfo>({});
  const queries = new Queries();

  useEffect(() => {
    let latLngSubscription;
    let descriptionSubscription;

    async function subscribeToLatLng() {
      try {
        const latLngObservable = await queries.getLatLngById(mobUser.OiqiaBookingId);
        latLngSubscription = latLngObservable.subscribe({
          next(response: ApolloQueryResult<{ NTA_getBookingById: { lat: number, lng: number } }>) {
            setLatLng({
              lat: response?.data?.NTA_getBookingById?.lat?.toString(),
              lng: response?.data?.NTA_getBookingById?.lng?.toString()
            });
          },
          error(err) {
            console.error("Error fetching latLng data:", err);
          }
        });
      } catch (error) {
        console.error("Error setting up subscription:", error);
      }
    }

    async function subscribeDescriptionData() {
      try {
        const descriptionObservable = await queries.getAppartmentCaract(mobUser.OiqiaBookingId);
        descriptionSubscription = descriptionObservable.subscribe({
          next(response: ApolloQueryResult<{ NTA_getAppartmentCaract: AppartmentCaractInfo }>) {
            setCaract(response.data.NTA_getAppartmentCaract);
          },
          error(err) {
            console.error("Error fetching wifi data:", err);
          }
        });
      } catch (error) {
        console.error("Error setting up subscription:", error);
      }
    }

    subscribeDescriptionData();
    subscribeToLatLng();

    return () => {
      latLngSubscription?.unsubscribe();
      descriptionSubscription?.unsubscribe();
    };
  }, []);

  return (
    <View style={styles.container}>
      <View style={styles.flexRow}>
        <View style={{ flex: 1 }}>
          <Text style={styles.title}>
            {i18n.t("pages.housing.address")}
          </Text>
          <Text selectable style={styles.dataText}>
            {data?.address?.extra}{"\n"}
            {data?.address?.streetNumber}{" "}
            {data?.address?.street},{" "}
            {data?.address?.zipCode}{" "},
            {data?.address?.city},{" "}
            {data?.address?.country}
          </Text>
        </View>
        <View>
          <HomePageButton
            component={component}
            extraStyle={{
              iconNormal: {
                color: theme.colors.primary
              },
              iconPress: {}
            }}
          />
        </View>
      </View>
      <View>
        {caract?.description &&
          <>
            <Text style={styles.title}>
              {i18n.t("pages.housing.description")}
            </Text>
            <Text style={styles.dataText}>
              {getTextFromMultilang(mobUser.Language,caract.description)}
            </Text>
          </>}
      </View>
      <View>
        {caract?.extraInfo &&
          <>
            <Text style={styles.title}>
              {i18n.t("pages.housing.extraInfos")}
            </Text>
            <Text style={styles.dataText}>
              {getTextFromMultilang(mobUser.Language,caract.extraInfo)}
            </Text>
          </>}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    gap: 15,
    width: "100%"
  },
  flexRow: {
    gap: 5,
    flexDirection: "row",
    width: "100%",
  },
  title: {
    fontFamily: "Gilroy-Bold",
    fontSize: 17,
    marginVertical: 3,
  },
  dataText: {
    fontSize: 17,
    marginVertical: 3,
  }
});
