import React from "react";
import { StyleSheet } from "react-native";
import { FAB, MD3Theme, useTheme } from "react-native-paper";

const ScrollFAB = ({ ScrollViewRef }) => {
  const theme = useTheme();
  const styles = styleGen(theme);

  return <FAB
    icon="chevron-down"
    style={styles.fab}
    color={theme.colors.primary}
    customSize={60}
    mode='elevated'
    onPress={() => ScrollViewRef.current.scrollToEnd({ animated: true })}
  />;
};

const styleGen = (theme: MD3Theme) => StyleSheet.create({
  fab: {
    position: "absolute",
    borderRadius: 50,
    bottom: "5%",
    right:"5%",
    backgroundColor: theme.colors.background,
  },
});

export default ScrollFAB;