import React from "react";
import { StyleSheet, View } from "react-native";
import { useTheme } from "react-native-paper";
import { BookingOiqiaQl } from "../../../types/types";
import Skeleton from "../../../components/Skeleton";
import HousingInfos from "./HousingInfos";

interface GridLayoutProps {
  isDataLoading: boolean,
  data: BookingOiqiaQl
}

export default function HousingDisplayData({ isDataLoading, data }: GridLayoutProps) {
  const theme = useTheme();

  return(
    <View style={{ ...styles.container, backgroundColor: theme.colors.secondary }}>
      {isDataLoading ?
        <View style={styles.skeletonContainer}>
          {[0, 1].map((_, index: number) => (
            <View key={index} style={styles.skeleton}>
              <Skeleton />
            </View>
          ))}
        </View>
        :
        <HousingInfos data={data} />
      }
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 20,
    padding: 20,
  },
  skeletonContainer: {
    gap: 10
  },
  skeleton: {
    height: 75,
  }
});
