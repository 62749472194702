import React, { useEffect, useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import Queries from "../../../api/queries";
import { mobUser } from "../../../mobx/MobUser";
import { RulesData, AttachmentInfo } from "../../../types/types";
import { ApolloQueryResult } from "@apollo/client";
/*import IntroPage from "./IntroPage";
import Summary from "./Summary";
import RuleCard from "./RuleCard";*/
import PDFGridLayout from "../../../components/PDFGridLayout";
import { HouseRulesIcon } from "../../../components/Icons";
import { MD3Theme, useTheme } from "react-native-paper";

export default function Rules() {
  const theme = useTheme();
  const styles = styleGen(theme);
  const [rulesData, setRulesData] = useState<AttachmentInfo[]>();
  const queries = new Queries();

  useEffect(() => {
    let rulesSubscription;

    async function subscribeToRulesData() {
      try {
        const rulesObservable = await queries.getRules(mobUser.OiqiaBookingId);
        rulesSubscription = rulesObservable.subscribe({
          next(response: ApolloQueryResult<{ getAttachmentInfo: RulesData }>) {
            setRulesData(response.data.getAttachmentInfo?.rules);
          },
          error(err) {
            console.error("Error fetching rules data:", err);
          }
        });
      } catch (error) {
        console.error("Error setting up subscription:", error);
      }
    }

    subscribeToRulesData();

    return () => {
      rulesSubscription?.unsubscribe();
    };
  }, []);

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <View style={styles.subContainer}>
        <PDFGridLayout
          data={rulesData}
          IconComponent={HouseRulesIcon}
        />
        {/*<IntroPage />
        <Summary rules={rulesData} />
        {(isLoading || !rulesData) ?
          <></>
          :
          <View style={styles.cardsList}>
            {rulesData.map((rule, idx) => (
              <RuleCard key={idx} idx={idx} rule={rule} />
            ))}
          </View>}*/}
      </View>
    </ScrollView>
  );
}

const styleGen = (theme: MD3Theme) => StyleSheet.create({
  container: {
    width: "100%",
    alignItems: "center",
  },
  subContainer: {
    margin: 20,
    backgroundColor: theme.colors.background,
    width: "80%"
  },
  /*subContainer: {
    width: "85%",
    alignItems: "center",
    paddingBottom: 50
  },*/
  cardsList: {
    marginTop: 75,
    width: "100%",
    gap: 30
  }
});
