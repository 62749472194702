import React, { useEffect, useState } from "react";
import { StyleSheet } from "react-native";
import { GestureHandlerRootView, ScrollView } from "react-native-gesture-handler";
import { ApolloQueryResult } from "@apollo/client";
import Queries from "../../../api/queries";
import { mobUser } from "../../../mobx/MobUser";
import { PictureData, BookingOiqiaQl } from "../../../types/types";
import GridLayout from "./GridLayout";
import { observer } from "mobx-react";
import { MD3Theme, useTheme } from "react-native-paper";

const Housing = observer(() => {
  const theme = useTheme();
  const styles = styleGen(theme);
  const [bookingData, setBookingData] = useState<BookingOiqiaQl>();
  const [pictureData, setPictureData] = useState<PictureData>();
  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
  const [isAttachmentLoading, setIsAttachmentLoading] = useState<boolean>(true);
  const queries = new Queries();

  useEffect(() => {
    let bookingSubscription;
    let attachmentSubscription;

    async function subscribeToData() {
      try {
        const BookingInfo = await queries.getBookingById(mobUser.OiqiaBookingId);
        bookingSubscription = BookingInfo.subscribe({
          next(response: ApolloQueryResult<{ NTA_getBookingById: BookingOiqiaQl }>) {
            setBookingData(response.data.NTA_getBookingById);
            setIsDataLoading(false);
          },
          error(err) {
            console.error("Error fetching booking data:", err);
          }
        });

        const attachmentObservable = await queries.getPictures(mobUser.OiqiaBookingId);
        attachmentSubscription = attachmentObservable.subscribe({
          next(response: ApolloQueryResult<{ getAttachmentInfo: PictureData }>) {
            setPictureData(response.data.getAttachmentInfo);
            setIsAttachmentLoading(false);
          },
          error(err) {
            console.error("Error fetching attachment data:", err);
          }
        });

      } catch (error) {
        console.error("Error setting up subscriptions:", error);
      }
    }

    subscribeToData();

    return () => {
      bookingSubscription?.unsubscribe();
      attachmentSubscription?.unsubscribe();
    };
  }, []);

  return (
    <GestureHandlerRootView>
      <ScrollView contentContainerStyle={styles.container}>
        <GridLayout
          isDataLoading={isDataLoading}
          isAttachmentLoading={isAttachmentLoading}
          data={bookingData}
          pictureData={pictureData}
        />
      </ScrollView>
    </GestureHandlerRootView>
  );

});

const styleGen = (theme: MD3Theme) => StyleSheet.create({
  container: {
    width:"80%",
    backgroundColor: theme.colors.background,
    alignItems: "center",
    alignSelf: "center",
    paddingTop: 20,
    paddingBottom: 80
  }
});

export default Housing;
