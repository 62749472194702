import React from "react";
import HomeSvg from "../../assets/icons/home_icon.svg";
import ArrowSvg from "../../assets/icons/arrow_icon.svg";
import HouseSvg from "../../assets/icons/house_icon.svg";
import LocationSvg from "../../assets/icons/location_icon.svg";
import KeySvg from "../../assets/icons/key_icon.svg";
import BuildingSvg from "../../assets/icons/building_icon.svg";
import WifiSvg from "../../assets/icons/wifi_icon.svg";
import HouseRulesSvg from "../../assets/icons/house_rules_icon.svg";
import BookingSvg from "../../assets/icons/booking_icon.svg";
import AssistanceSvg from "../../assets/icons/assistance_icon.svg";
import LanguageSvg from "../../assets/icons/language_icon.svg";
import CheckInSvg from "../../assets/icons/check_in_icon.svg";
import CheckOutSvg from "../../assets/icons/check_out_icon.svg";
import StarSvg from "../../assets/icons/star_icon.svg";
import BookSvg from "../../assets/icons/book_icon.svg";
import PowerSvg from "../../assets/icons/power_icon.svg";
import BedSvg from "../../assets/icons/bed_icon.svg";
import WelcomeSvg from "../../assets/icons/welcome_icon.svg";
import InvoiceSvg from "../../assets/icons/invoice_icon.svg";
import OutsideSvg from "../../assets/icons/outside_icon.svg";
import PersonsSvg from "../../assets/icons/persons_icon.svg";
import GoingOutsideSvg from "../../assets/icons/going_outside_icon.svg";
import GoingInsideSvg from "../../assets/icons/going_inside_icon.svg";
import InfosSvg from "../../assets/icons/infos_icon.svg";
import CarSvg from "../../assets/icons/car_icon.svg";
import CheckMarkSvg from "../../assets/icons/check_mark.svg";
import ColoredOiqiaSvg from "../../assets/icons/colored_oiqia_icon.svg";
import CrossSvg from "../../assets/icons/cross_icon.svg";
import DeadFaceSVG from "../../assets/icons/dead_face.svg";
import { useTheme } from "react-native-paper";
import { Platform, View } from "react-native";

interface IconProps {
  color?: string,
  rotation?: number,
  style?: any,
  width?: number | string
  height?: number | string,
}

function IconWebAdapter(Component, props) {
  return Platform.OS === "web" ? (
    <img alt="logo" width="100" height="50" src={Component} />
  ) : (
    <Component {...props} fill={!props.color ? useTheme().colors.onBackground : props.color} />
  );
}

export function DeadFaceIcon(props: IconProps) {
  return IconWebAdapter(DeadFaceSVG, props);
}

export function HomeIcon(props: IconProps) {
  return IconWebAdapter(HomeSvg, props);
}

export function ArrowIcon(props: IconProps) {
  return Platform.OS === "web" ? (
    <View style={props.style}>
      <img alt="logo" width="100" height="50" src={ArrowSvg.toString()} />
    </View>
  ) : (
    <ArrowSvg {...props} stroke={!props.color ? useTheme().colors.onBackground : props.color} />
  );
}

export function HouseIcon(props: IconProps) {
  return IconWebAdapter(HouseSvg, props);
}

export function LocationIcon(props: IconProps) {
  return IconWebAdapter(LocationSvg, props);
}

export function KeyIcon(props: IconProps) {
  return IconWebAdapter(KeySvg, props);
}

export function BuildingIcon(props: IconProps) {
  return IconWebAdapter(BuildingSvg, props);
}

export function WifiIcon(props: IconProps) {
  return IconWebAdapter(WifiSvg, props);
}

export function HouseRulesIcon(props: IconProps) {
  return IconWebAdapter(HouseRulesSvg, props);
}

export function BookingIcon(props: IconProps) {
  return IconWebAdapter(BookingSvg, props);
}

export function AssistanceIcon(props: IconProps) {
  return IconWebAdapter(AssistanceSvg, props);
}

export function LanguageIcon(props: IconProps) {
  return IconWebAdapter(LanguageSvg, props);
}

export function CheckInIcon(props: IconProps) {
  return IconWebAdapter(CheckInSvg, props);
}

export function CheckOutIcon(props: IconProps) {
  return IconWebAdapter(CheckOutSvg, props);
}

export function StarIcon(props: IconProps) {
  return IconWebAdapter(StarSvg, props);
}

export function BookIcon(props: IconProps) {
  return IconWebAdapter(BookSvg, props);
}

export function PowerIcon(props: IconProps) {
  return IconWebAdapter(PowerSvg, props);
}

export function BedIcon(props: IconProps) {
  return IconWebAdapter(BedSvg, props);
}

export function WelcomeIcon(props: IconProps) {
  return IconWebAdapter(WelcomeSvg, props);
}

export function InvoiceIcon(props: IconProps) {
  return IconWebAdapter(InvoiceSvg, props);
}

export function OutsideIcon(props: IconProps) {
  return IconWebAdapter(OutsideSvg, props);
}

export function PersonsIcon(props: IconProps) {
  return IconWebAdapter(PersonsSvg, props);
}

export function GoingOutsideIcon(props: IconProps) {
  return IconWebAdapter(GoingOutsideSvg, props);
}

export function GoingInsideIcon(props: IconProps) {
  return IconWebAdapter(GoingInsideSvg, props);
}

export function InfosIcon(props: IconProps) {
  return IconWebAdapter(InfosSvg, props);
}

export function CarIcon(props: IconProps) {
  return IconWebAdapter(CarSvg, props);
}

export function CheckMarkIcon(props: IconProps) {

  return IconWebAdapter(CheckMarkSvg, props);
}

export function ColoredOiqiaIcon(props: IconProps) {
  return IconWebAdapter(ColoredOiqiaSvg, props);
}

export function CrossIcon(props: IconProps) {
  return IconWebAdapter(CrossSvg, props);
}
