import React from "react";
import { StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Text } from "../utils/fontsLoader";

const LinkText = ({ children, style, ...props }) => {
  const navigation = useNavigation();

  // Fonction pour détecter et gérer les liens
  const renderText = (inputText) => {
    const parts = inputText.split(/\[([^\]]+)\]\(([^)]+)\)/);
    return parts.map((part, index) => {
      if (index % 3 === 1) {
        return (
          <Text key={index} onPress={() => navigation.navigate(parts[index + 1])} style={[styles.link, style]}>{part}</Text>
        );
      } else if (index % 3 === 0) {
        return <Text key={index} style={style}>{part}</Text>;
      }
      return null;
    });
  };

  // Assurez-vous que les enfants sont de type string
  if (typeof children === "string") {
    return (
      <Text {...props}>
        {renderText(children)}
      </Text>
    );
  }

  // Si les enfants ne sont pas de type string, retournez-les directement
  return children;
};

const styles = StyleSheet.create({
  link: {
    color: "blue", // Définissez ici le style de votre lien
  },
});

export default LinkText;
