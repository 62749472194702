import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useTheme } from "react-native-paper";
import CustomHeader from "./CustomHeader";
import Access from "../pages/Home/Access";
import i18n from "../i18n";

const AccessTabScreen = createNativeStackNavigator();

interface Route {
  name: string,
  component: () => JSX.Element,
  headerTitle: string,
  headerHasArrow: boolean
}

export default function AccessTabScreenScreen() {
  const theme = useTheme();

  const ROUTE: Route = { name: "Access", component: Access, headerTitle: i18n.t("home.access"), headerHasArrow: false };

  return (
    <AccessTabScreen.Navigator screenOptions={{
      statusBarColor: theme.colors.primary,
      navigationBarHidden: true,
      contentStyle: {
        backgroundColor: theme.colors.background
      }
    }}
    >
      <AccessTabScreen.Screen
        name={ROUTE.name}
        component={ROUTE.component}
        options={{
          header: () => CustomHeader({ text: ROUTE.headerTitle, hasArrow: ROUTE.headerHasArrow, backPath: "Main", theme })
        }}
      />
    </AccessTabScreen.Navigator>
  );
}
