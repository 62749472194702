import React from "react";
import { TextInput, KeyboardTypeOptions, StyleSheet, View, Dimensions } from "react-native";
import { useTheme } from "react-native-paper";
import { Text, adjustText } from "../utils/fontsLoader";

interface AppInputProps {
  value: string,
  onChangeText: (text: string) => void,
  placeholder?: string
  additionalStyle?: object
  keyboardType?: KeyboardTypeOptions
  multiline?: boolean
  autoCapitalize?: "none" | "sentences" | "words" | "characters"
  editable?: boolean
  label?: string
  onBlur?: any
}

export default function AppInput({
  value,
  onChangeText,
  placeholder,
  additionalStyle,
  keyboardType,
  multiline,
  autoCapitalize,
  editable,
  label,
  onBlur
}: AppInputProps) {
  const theme = useTheme();

  const styles = StyleSheet.create({
    input: {
      borderWidth: 1,
      width: "100%",
      borderColor: "silver",
      paddingHorizontal: 10,
      paddingTop: multiline ? 9 : 0,
      paddingBottom: multiline ? 10 : 0,
      textAlign: "center",
      fontSize: Dimensions.get("screen").height / 45,
      backgroundColor: theme.colors.secondary,
      color:theme.colors.onBackground,
      fontFamily: "Gilroy-Regular",
      borderRadius: 15,
      minHeight: 35,
      lineHeight: multiline ? 25 : 20,
    },
    container: {
      width: "100%",
      alignItems: "center",
      gap: 20,
    },
    label: {
      textAlign:"center",
      fontSize: 25,
      fontFamily: "Gilroy-Light",
    }
  });

  return (
    <View style={styles.container}>
      {label &&
        <Text style={styles.label}>
          {adjustText(label,Dimensions.get("screen").width*0.8,25)}
        </Text>
      }
      <TextInput
        style={{ ...styles.input, ...additionalStyle }}
        placeholder={placeholder}
        value={value}
        onChangeText={onChangeText}
        keyboardType={keyboardType}
        autoCapitalize={autoCapitalize}
        multiline={multiline}
        onBlur={onBlur}
        editable={editable}
      />
    </View>
  );
}

