import React, { useEffect, useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import Queries from "../../../api/queries";
import { AttachmentInfo, ManualsData } from "../../../types/types";
import { ApolloQueryResult } from "@apollo/client";
import { mobUser } from "../../../mobx/MobUser";
import PDFGridLayout from "../../../components/PDFGridLayout";
import { InfosIcon } from "../../../components/Icons";
import { MD3Theme, useTheme } from "react-native-paper";

export default function Manuals() {
  const theme = useTheme();
  const styles = styleGen(theme);
  const [manuals, setManuals] = useState<AttachmentInfo[]>();
  const queries = new Queries();

  useEffect(() => {
    let manaulsSubscription;

    async function subscribeToData() {
      try {
        const attachmentObservable = await queries.getManuals(mobUser.OiqiaBookingId);
        manaulsSubscription = attachmentObservable.subscribe({
          next(response: ApolloQueryResult<{ getAttachmentInfo: ManualsData }>) {
            setManuals(response.data.getAttachmentInfo?.manuals);
          },
          error(err) {
            console.error("Error fetching attachment data:", err);
          }
        });
      } catch (error) {
        console.error("Error setting up subscriptions:", error);
      }
    }

    subscribeToData();

    return () => {
      manaulsSubscription?.unsubscribe();

    };
  }, []);

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <View style={styles.subContainer}>
        <PDFGridLayout
          data={manuals}
          IconComponent={InfosIcon}
        />
      </View>
    </ScrollView>
  );
}

const styleGen = (theme: MD3Theme) => StyleSheet.create({
  container: {
    backgroundColor: theme.colors.background,
    alignItems: "center",
  },
  subContainer: {
    margin: 20,
    backgroundColor: theme.colors.background,
    width: "80%"
  }
});