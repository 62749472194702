import React, { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import { Subscription } from "zen-observable-ts";
import { AttachmentInfo, ParkingData } from "../../../types/types";
import Queries from "../../../api/queries";
import { mobUser } from "../../../mobx/MobUser";
import { ApolloQueryResult } from "@apollo/client";
import InfoCard from "../../../components/InfosCard";
import Placeholder from "../../../components/Placeholder";

const Parking = () => {
  const [ParkingInfos, setParkingInfos] = useState<AttachmentInfo[]>([]);
  const queries = new Queries();

  useEffect(() => {
    let parkingDataSubscription: Subscription;

    async function subscribeToData() {
      try {
        const parkingObservable = await queries.getParking(mobUser.OiqiaBookingId);
        parkingDataSubscription = parkingObservable.subscribe({
          next(response: ApolloQueryResult<{ getAttachmentInfo: ParkingData }>) {
            setParkingInfos(response.data.getAttachmentInfo?.parking);
          },
          error(err) {
            console.error("Error fetching check-in data:", err);
          }
        });
      } catch (error) {
        console.error("Error setting up subscription:", error);
      }
    }

    subscribeToData();

    return () => {
      parkingDataSubscription?.unsubscribe();
    };
  }, []);
  return (
    <View style={styles.container}>
      <View style={styles.subContainer}>
        {ParkingInfos?.length ? ParkingInfos.map((e, idx) => (
          <InfoCard key={idx} fieldMap={[e]} />
        )): <Placeholder/>}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    paddingVertical: 20,
  },
  subContainer: {
    width: "80%",
    gap: 25
  },
});

export default Parking;