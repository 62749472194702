import React, { useEffect } from "react";
import { View, StyleSheet } from "react-native";
import { Controller, FormProvider, useForm } from "react-hook-form";
import Mutations from "../../../api/mutations";
import i18n from "../../../i18n";
import FieldErrorText from "../../../components/FieldErrortext";
import AppInput from "../../../components/AppInput";
import AppButton from "../../../components/AppButton";
import { MD3Theme, useTheme } from "react-native-paper";
import { mobUser } from "../../../mobx/MobUser";
import { useNavigation } from "@react-navigation/native";
import { mobAlert } from "../../../mobx/MobAlert";
import { Text } from "../../../utils/fontsLoader";

const ExploreForm = ({ checkInInfos }) => {
  const theme = useTheme();
  const styles = stylesGen(theme);
  const navigation = useNavigation();
  const mutations = new Mutations();

  const formMethods = useForm({
    defaultValues: {
      firstName: checkInInfos?.firstName || "",
      comment: "",
    },
  });

  const {
    control,
    reset,
    getValues,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  useEffect(() => {
    reset({
      ...getValues(),
      firstName: checkInInfos?.firstName || "",
    });
  }, [checkInInfos, reset, getValues]);

  const onSubmit = (data) => {
    mutations.addGuestbookEntry({ ...data, bookingId: mobUser.OiqiaBookingId })
      .then(() => {
        mobAlert.setSuccess(
          i18n.t("pages.explore.successTitle"),
          i18n.t("pages.explore.successMessage"),
          () => {
            mobAlert.closeSuccess();
            navigation.goBack();
          }
        );
        mobUser.setGestbookDone(true);
      }).catch(error => {
        console.error("Error adding guestbook entry", error);
        mobAlert.setError(i18n.t("pages.explore.errorTitle"), i18n.t("pages.explore.errorMessage"));
      });
    // Faites ce que vous voulez avec les données du commentaire ici, par exemple, appelez mutations.askComment(CommentData);
  };

  return (
    <View style={styles.container}>
      <FormProvider {...formMethods}>
        <Controller
          control={control}
          rules={{
            required: i18n.t("errors.missingField"),
          }}
          render={({ field: { onChange, value } }) => (
            <View style={styles.field}>
              <Text style={styles.label}>
                {i18n.t("pages.explore.firstName")}
              </Text>
              <AppInput
                value={value}
                onChangeText={onChange}
                additionalStyle={styles.inputText}
                autoCapitalize="words"
              />
              {errors.firstName && (
                <FieldErrorText errorMessage={errors.firstName.message} />
              )}
            </View>
          )}
          name="firstName"
        />
        <Controller
          control={control}
          rules={{
            required: false,
          }}
          render={({ field: { onChange, value } }) => (
            <View style={styles.field}>
              <Text style={styles.label}>
                {i18n.t("pages.explore.comment")}
              </Text>
              <AppInput
                value={value}
                onChangeText={onChange}
                additionalStyle={styles.inputText}
                multiline
              />
            </View>
          )}
          name="comment"
        />
        <AppButton
          extraStyle={styles.submitButton}
          extraTextStyle={styles.submitButtonText}
          color={theme.colors.primary}
          onPress={handleSubmit(onSubmit)}
        >
          {i18n.t("pages.explore.submit")}
        </AppButton>
      </FormProvider>
    </View>
  );
};

const stylesGen = (theme: MD3Theme) => StyleSheet.create({
  container:{
    borderWidth:1,
    borderRadius:30,
    paddingVertical:50,
    paddingHorizontal:15,
    borderColor:"rgba(0,0,0,0.2)",
    gap: 25
  },
  field: {
    gap: 10,
  },
  label: {
    fontSize: 20,
    textAlign: "center",
  },
  inputText: {
    fontSize: 20,
    fontFamily: "Gilroy-Light",
  },
  submitButton: {
    marginTop: 20,
  },
  submitButtonText: {
    color: theme.colors.background,
    fontFamily: "Gilroy-Medium"
  },
});

export default ExploreForm;
