import i18n from "../../../i18n";
import { BookingOiqiaQl, InfoCardTextProps } from "../../../types/types";
import { formatDateHour } from "../../../utils/dateFormater";

export default function getInfosFromArray(data: BookingOiqiaQl): InfoCardTextProps[] {
  const tmp: InfoCardTextProps[] = [];

  tmp.push({ title: i18n.t("pages.bookingInfos.bookingId"), text: data?.smoobuBookingId });
  tmp.push({ title: i18n.t("pages.bookingInfos.arrival"), text: formatDateHour(data?.arrival + "T" + data?.checkIn) });
  tmp.push({ title: i18n.t("pages.bookingInfos.departure"), text: formatDateHour(data?.departure + "T" + data?.checkOut) });
  tmp.push({ title: i18n.t("pages.bookingInfos.address"), text: data?.formatedAddress });
  tmp.push({ title: i18n.t("pages.bookingInfos.lastName"), text: data?.lastName });
  tmp.push({ title: i18n.t("pages.bookingInfos.firstName"), text: data?.firstName });
  return tmp;
}
