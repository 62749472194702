import React, { useEffect, useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import CheckOutForm from "./CheckOutForm";
import Queries from "../../../api/queries";
import { Subscription } from "zen-observable-ts";
import { CheckOutData } from "../../../types/types";
import { ApolloQueryResult } from "@apollo/client";
import { mobUser } from "../../../mobx/MobUser";

export default function CheckOut() {
  const [checkOutInfos, setcheckOutInfos] = useState<CheckOutData>();
  const queries = new Queries();

  useEffect(() => {
    let checkOutDataSubscription: Subscription;

    async function subscribeToData() {
      try {
        const checkOutObservable = await queries.getCheckOut(mobUser.OiqiaBookingId, mobUser.Language);
        checkOutDataSubscription = checkOutObservable.subscribe({
          next(response: ApolloQueryResult<{ NTA_getCheckOut: CheckOutData }>) {
            setcheckOutInfos(response.data.NTA_getCheckOut);
          },
          error(err) {
            console.error("Error fetching check-out data:", err);
          }
        });
      } catch (error) {
        console.error("Error setting up subscription:", error);
      }
    }

    subscribeToData();

    return () => {
      checkOutDataSubscription?.unsubscribe();
    };
  }, []);

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <View style={styles.subContainer}>
        <CheckOutForm checkOutInfos={checkOutInfos} />
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center"
  },
  subContainer: {
    marginTop: 20,
    marginBottom: 20,
    gap: 10,
    width: "80%"
  },
});
