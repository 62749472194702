import React from "react";
import { ActivityIndicator, Platform, View, StyleSheet } from "react-native";
import { MD3Theme, useTheme } from "react-native-paper";

export interface OiqiaLoadingModalProps {
  opacity?: number;
}

export const OiqiaLoadingModal = (props: OiqiaLoadingModalProps) => {
  const { opacity = 0.4 } = props;
  const theme = useTheme();
  const modalStyles = modalStylesGen(theme);

  return (
    <View
      style={[
        modalStyles.modalBackground,
        { backgroundColor: `rgba(0,0,0,${opacity})` },
      ]}
    >
      <View style={modalStyles.activityIndicatorWrapper}>
        {Platform.OS != "web" &&
          <ActivityIndicator
            animating={true}
            color={theme.colors.primary}
            size="small"
          />
        }
      </View>
    </View>
  );
};

export const modalStylesGen = (theme: MD3Theme) => StyleSheet.create({
  modalBackground: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  activityIndicatorWrapper: {
    backgroundColor: theme.colors.background,
    height: 100,
    width: 100,
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    position: "absolute",
    paddingTop: 50,
  },
});

export default OiqiaLoadingModal;