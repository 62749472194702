import React from "react";
import { View, Pressable, StyleSheet } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { MD3Theme, useTheme } from "react-native-paper";
import { Text } from "../../utils/fontsLoader";

interface ButtonData {
  text: any;
  callback: () => any;
  fontColor?: string;
  backgroundColor?: string;
}

interface Props {
  titleText: any;
  bodyText?: any;
  onClose: () => void;
  firstButtonData: ButtonData;
  secondButtonData?: ButtonData;
}

const OiqiaModal = ({ titleText, bodyText = "", firstButtonData, secondButtonData, onClose }: Props) => {
  const theme = useTheme();
  const styles = styleGen(theme);

  const getButtonStyle = (backgroundColor?: string) => [
    styles.button,
    backgroundColor && { backgroundColor }
  ];

  const getTextStyle = (fontColor?: string) => [
    styles.textStyle,
    fontColor && { color: fontColor }
  ];

  return (
    <View style={styles.centeredView}>
      <View style={styles.modalView}>
        <View style={styles.buttonCloseView}>
          <Pressable onPress={onClose} style={styles.buttonCloseModal}>
            <Ionicons name="close-circle" size={30} color={theme.colors.primary} />
          </Pressable>
        </View>
        <Text style={styles.modalTitle}>{titleText}</Text>
        <Text style={styles.modalText}>{bodyText}</Text>
        <View style={styles.buttonView}>
          <Pressable style={getButtonStyle(firstButtonData.backgroundColor)} onPress={firstButtonData.callback}>
            <Text style={getTextStyle(firstButtonData.fontColor)}>{firstButtonData.text}</Text>
          </Pressable>
          {secondButtonData && (
            <Pressable style={getButtonStyle(secondButtonData.backgroundColor)} onPress={secondButtonData.callback}>
              <Text style={getTextStyle(secondButtonData.fontColor)}>{secondButtonData.text}</Text>
            </Pressable>
          )}
        </View>
      </View>
    </View>
  );
};

const styleGen = (theme: MD3Theme) => StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    position: "relative",
    margin: 20,
    backgroundColor: theme.colors.background,
    borderRadius: 20,
    paddingHorizontal: 35,
    paddingBottom: 20,
    alignItems: "center",
    shadowColor: theme.colors.onBackground,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
    width: "40%",
    backgroundColor: theme.colors.primary,
  },
  textStyle: {
    fontFamily: "Gilroy-Bold",
    textAlign: "center",
  },
  modalText: {
    fontFamily: "Gilroy-Medium",
    fontSize: 18,
    marginBottom: 20,
    textAlign: "center",
  },
  modalTitle: {
    fontFamily: "Gilroy-SemiBold",
    fontSize: 24,
    marginBottom: 15,
    textAlign: "center",
  },
  buttonView: {
    width:"100%",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  buttonCloseView: {
    alignSelf: "flex-end",
    marginVertical: 5,
  },
  buttonCloseModal: {
    borderRadius: 50,
    marginTop: 10,
    elevation: 2,
  },
});

export default OiqiaModal;